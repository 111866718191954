import React, { useState } from "react";
import { Box, Button, Grid, Typography, Skeleton } from "@mui/material";

export default function Banner({ bannerData }) {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box
          position="relative"
          width="100%"
          sx={{
            height: { xs: "80vh", sm: "100vh", md: "150vh" },
            overflow: "hidden",
          }}
        >
          {/* Skeleton Loader for LCP optimization */}
          {!isLoaded && (
            <Skeleton
            animation='wave'
              variant="rectangular"
              width="100%"
              height="100%"
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 2,
              }}
            />
          )}

          {/* Video Background */}
          {bannerData.videoUrl ? (
            <Box
              component="video"
              playsInline
              autoPlay
              loop
              muted
              preload="auto"
              onCanPlayThrough={() => setIsLoaded(true)}
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
                opacity: isLoaded ? 1 : 0,
                transition: "opacity 1.5s ease-in-out",
              }}
            >
              <source src={bannerData.videoUrl} type="video/mp4" />
            </Box>
          ) : (
            <Box
              component="img"
              loading="lazy"
              decoding="async"
              onLoad={() => setIsLoaded(true)}
              src={bannerData.imageUrl}
              alt="Banner"
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
                opacity: isLoaded ? 1 : 0,
                transition: "opacity 1.5s ease-in-out",
              }}
            />
          )}

          {/* Overlay Content */}
          <Grid
            container
            position="absolute"
            bottom="5%"
            sx={{
              color: "white",
              px: { xs: 2, sm: 4, md: 8 },
              textAlign: { xs: "center", md: "left" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={bannerData.features.length > 0 ? 6 : 12}
              display="flex"
              alignItems="center"
              justifyContent={bannerData.features.length > 0 ? "flex-start" : "center"}
              gap={3}
              sx={{
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              {/* Title and Subtitle */}
              <Box
                sx={{
                  borderRight: { sm: bannerData.features.length > 0 && "1px solid white" },
                  pr: { sm: 3 },
                  pb: { xs: 2, sm: 0 },
                }}
              >
                <Typography
                  variant={"h4"}
                  sx={{
                    fontFamily: "KIAbodyLatinbasic_Bold",
                    lineHeight: 1.1,
                  }}
                >
                  {bannerData.title}
                </Typography>
                <Typography variant="subtitle1">{bannerData.subtitle}</Typography>
              </Box>

              {/* Price Section */}
              {bannerData.price && (
                <Box>
                  <Typography variant="h6">Price starts at</Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      fontFamily: "KIAbodyLatinbasic_Bold",
                      display: "block",
                    }}
                  >
                    {bannerData.price}
                  </Typography>
                </Box>
              )}
            </Grid>

            {/* Features Grid */}
            {bannerData.features.length > 0 && (
              <Grid item xs={12} sm={12} md={6} textAlign="center">
                <Grid
                  container
                  spacing={3}
                  sx={{
                    display: { xs: "none", sm: "none", md: "flex" },
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {bannerData.features.map((item, index) => (
                    <Grid item xs={4} key={index}>
                      <Box
                        component="img"
                        loading="lazy"
                        decoding="async"
                        src={item.imgSrc}
                        alt="Feature Icon"
                        sx={{
                          width: { xs: "30px", sm: "40px", md: "50px" },
                          mb: 1,
                        }}
                      />
                      <Typography
                        sx={{
                          fontFamily: "KIAbodyLatinbasic_Regular",
                          fontSize: "12px",
                        }}
                      >
                        {item.text}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>

                {/* Mobile "Book Now" Button */}
                <Grid
                  container
                  sx={{
                    display: { xs: "block", sm: "block", md: "none" },
                    mt: 2,
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      color: "#000",
                      backgroundColor: "#FFF",
                      px: 6,
                      py: 1,
                      textTransform: "capitalize",
                      fontSize: "16px",
                      borderRadius: 0,
                      fontWeight: 500,
                    }}
                  >
                    Book Now
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
