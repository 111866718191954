import { Box, Grid, Skeleton, Typography } from "@mui/material";
import React, { useState } from "react";

export default function Intro({ data }) {
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <>
      <Grid container py={"2%"} px={"7%"}>
        <Grid item xs={12}>
          <Typography
            variant="inherit"
            color="gray"
            letterSpacing={2}
            fontFamily="KIAbodyLatinbasic_Regular"
            sx={{
              fontSize: "clamp(14px, 2vw, 16px)",
            }}
          >
            {data.subheading}
          </Typography>
          <Typography
            variant="h4"
            fontFamily="KIAbodyLatinbasic_Bold"
            lineHeight={2.5}
            sx={{
              fontSize: "clamp(24px, 4vw, 27px)",
            }}
          >
            {data.heading}
          </Typography>
          <Typography
            fontFamily="KIAbodyLatinbasic_Regular"
            textAlign="justify"
            sx={{
              fontSize: "clamp(12px, 1.8vw, 16px)",
            }}
          >
            {data.description}
          </Typography>
        </Grid>
      </Grid>
      <Grid container mt={5}>
        <Grid item xs={12}>
          {!isLoaded && (
            <Skeleton
              animation="wave"
              variant="rectangular"
              width="100%"
              height="100%"
            />
          )}
          <Box
            component="img"
            loading="eager"
            decoding="async"
            onLoad={() => setIsLoaded(true)}
            src={data.image}
            alt="Intro Image"
            sx={{
              width: "100%",
              height: "100%",
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
