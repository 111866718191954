import React, { useState } from "react";
import {
  Card,
  CardContent,
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  FormControlLabel,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
export default function Feature(props) {
  const [airbag, setAirbag] = useState("");
  const [tyrePresssure, setTyrePressure] = useState(false);
  const [childSeatAnchorPoints, setchildSeatAnchorPoints] = useState(false);
  const [seatBeltWarning, setSeatBeltWarning] = useState(false);

  const [abs, setAbs] = useState(false);
  const [ebd, setEbd] = useState(false);
  const [ba, setBa] = useState(false);
  const [esp, setEsp] = useState(false);
  const [fourWheel, setFourWheelDrive] = useState(false);
  const [hillHoldControl, setHillHoldControl] = useState(false);
  const [tcs, setTcs] = useState(false);
  const [hillDescentControl, setHillDescentControl] = useState(false);

  const [engineImmobiliser, setEngineImmobiliser] = useState(false);
  const [centeralLocking, setCenteralLocking] = useState(false);
  const [speedSensingDoorLock, setSpeedSensingDoorLock] = useState(false);
  const [childSafetyLock, setChildSafetyLock] = useState(false);

  const [ac, setAc] = useState(false);
  const [frontac, setFrontAc] = useState(false);
  const [rearac, setRearAc] = useState(false);
  const [heater, setHeater] = useState(false);
  const [vanityMirror, setVanityMirror] = useState(false);
  const [antiglareMirror, setAntiGlareMirror] = useState(false);
  const [parkingAssist, setparkingAssist] = useState(false);
  const [parkingSensor, setparkingSensor] = useState(false);
  const [cruisecontrol, setcruiseControl] = useState(false);
  const [headlightIgnition, setHeadlightIgnition] = useState(false);
  const [keylessstart, setKeylessStart] = useState(false);
  const [steeringAdjustment, setSteeringAdjustment] = useState(false);
  const [power, setPower] = useState(false);

  const [applecarplay, setApplecarplay] = useState(false);
  const [androidauto, setAndroidauto] = useState(false);

  const [seatUpholestry, setSeatUpholestry] = useState(false);
  const [drivesideAdjust, setdriverSideAdjust] = useState(false);
  const [leatherWrapped, setLeatherWrapped] = useState(false);
  const [driverArmrest, setDriverArmrest] = useState(false);

  const [foldingRearSeat, setFoldingRearSeat] = useState(false);
  const [splitRearSeat, setSplitRearSeat] = useState(false);
  const [splitThirdRowSeat, setSplitThirdRowSeat] = useState(false);
  const [frontSeatbackPockets, setFrontSeatBackPockets] = useState(false);
  const [headrests, setHeadrests] = useState(false);

  const [cupholders, setcupHolders] = useState(false);
  const [driverArmrestStorage, setDriverArmrestStorage] = useState(false);
  const [cooledGloveBox, setCooledGloveBox] = useState(false);
  const [sunGlassHolder, setSunglassHolder] = useState(false);
  const [thirdRowCupHolder, setThirdRowCupHolder] = useState(false);

  const [orvm, setOrvm] = useState(false);
  const [powerWindow, setPowerWindow] = useState(false);
  const [oneTouchDown, setOneTouchDown] = useState(false);
  const [oneTouchUp, setOneTouchUp] = useState(false);
  const [adjustableOrvm, setAdjustableOrvm] = useState(false);
  const [exteriorDoorHandle, setExteriorDoorHandle] = useState(false);
  const [chromeexteriorDoorHandle, setChromeExteriorDoorHandle] =
    useState(false);
  const [doorPockets, setDoorPockets] = useState(false);
  const [bootlidOpener, setBootlidOpener] = useState(false);

  const [sunroof, setsunRoof] = useState(false);
  const [roofMountedAntenna, setRoofMountedAntenna] = useState(false);
  const [bodyColouredBumpers, setBodyColouredBumpers] = useState(false);
  const [chromeFinish, setChromeFinish] = useState(false);

  const [projectorheadlight, setProjectorHeadlight] = useState(false);
  const [autoheadlamps, setAutoheadlamps] = useState(false);
  const [homeheadlamps, setHomeheadlamps] = useState(false);
  const [drl, setDrl] = useState(false);
  const [tailights, setTailLights] = useState(false);
  const [cabinLamps, setCabinlamps] = useState(false);
  const [lightonVanityMirror, setLightonVanityMirror] = useState(false);
  const [rearreadinglamp, setRearreadinglamp] = useState(false);
  const [gloveBoxlamp, setGloveboxlamp] = useState(false);
  const [headlightHeightAdjuster, setHeadlightHEightAdjuster] = useState(false);

  const [tripMeter, setTripMeter] = useState(false);
  const [averageFuelConsumption, setAverageFuelConsumption] = useState(false);
  const [distancetoEmpty, setDistancetoEmpty] = useState(false);
  const [clock, setClock] = useState(false);
  const [lowFuelWarning, setLowFuelWarning] = useState(false);
  const [doorAjarWarning, setDooeAjarWarning] = useState(false);
  const [adjustableCluster, setAdjustableCluster] = useState(false);
  const [gearIndicator, setGearIndicator] = useState(false);
  const [shiftIndicator, setShiftIndicator] = useState(false);
  const [tachometer, setTachometer] = useState(false);
 
  const [musicSystem, setMusicSystem] = useState(false);
  const [speaker, setSpeaker] = useState(false);
  const [steeringmountedctrls, setSteeringmountedctrls] = useState(false);
  const [voiceCommand, setVoiceCommand] = useState(false);
  const [gps, setGps] = useState(false);
  const [bluetooth, setBluetooth] = useState(false);
  const [aux, setAux] = useState(false);
  const [radio, setRadio] = useState(false);
  const [headUnitSize, setHeadUnitSize] = useState(false);
  const [usb, setUsb] = useState(false);

  const [des12, setdes12] = useState("");

  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (props.client.oldCarId !== "") {
      props.addFeature(
        props.login.token,
        props.login.user_id,
        props.client.oldCarId,
        airbag,
        tyrePresssure,
        childSeatAnchorPoints,
        seatBeltWarning,
        abs,
        ebd,
        ba,
        esp,
        fourWheel,
        hillHoldControl,
        tcs,
        hillDescentControl,
        engineImmobiliser,
        centeralLocking,
        speedSensingDoorLock,
        childSafetyLock,
        ac,
        frontac,
        rearac,
        heater,
        vanityMirror,
        antiglareMirror,
        parkingAssist,
        parkingSensor,
        cruisecontrol,
        headlightIgnition,
        keylessstart,
        steeringAdjustment,
        power,
        applecarplay,
        androidauto,
        seatUpholestry,
        drivesideAdjust,
        leatherWrapped,
        driverArmrest,
        foldingRearSeat,
        splitRearSeat,
        splitThirdRowSeat,
        frontSeatbackPockets,
        headrests,
        cupholders,
        driverArmrestStorage,
        cooledGloveBox,
        sunGlassHolder,
        thirdRowCupHolder,
        orvm,
        powerWindow,
        oneTouchDown,
        oneTouchUp,
        adjustableOrvm,
        exteriorDoorHandle,
        chromeexteriorDoorHandle,
        doorPockets,
        bootlidOpener,
        sunroof,
        roofMountedAntenna,
        bodyColouredBumpers,
        chromeFinish,
        projectorheadlight,
        autoheadlamps,
        homeheadlamps,
        drl,
        tailights,
        cabinLamps,
        lightonVanityMirror,
        rearreadinglamp,
        gloveBoxlamp,
        headlightHeightAdjuster,
        tripMeter,
        averageFuelConsumption,
        distancetoEmpty,
        clock,
        lowFuelWarning,
        doorAjarWarning,
        adjustableCluster,
        gearIndicator,
        shiftIndicator,
        tachometer,
        musicSystem,
        speaker,
        steeringmountedctrls,
        voiceCommand,
        gps,
        bluetooth,
        aux,
        radio,
        usb,
        headUnitSize,
        des12
      );
      navigate("/oldcar");
    } else {
      console.log("Old Car id is missing");
    }
  };

  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      sx={{ marginTop: "71px", marginBottom: "20px" }}
      className="KIAbodyLatinbasic_Regular"
    >
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                variant="h6"
                sx={{
                  marginBottom: 2,
                  fontWeight: "bold",
                  color: "#cf2230",
                  fontFamily: "KIAbodyLatinbasic_Regular",
                  fontSize: { md: "22px", sm: "20px", xs: "17px" },
                }}
              >
                FEATURES
              </Typography>
            </Box>
            <form onSubmit={handleSubmit}>
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  margin: "10px",
                  fontWeight: "800",
                }}
              >
                Safety
              </Typography>
              <Grid item xs={12} sm={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Airbags</InputLabel>
                  <Select
                    label="airbag"
                    name="airbag"
                    value={airbag}
                    onChange={(e) => setAirbag(e.target.value)}
                  >
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="6">6</MenuItem>
                    <MenuItem value="7">7</MenuItem>
                    <MenuItem value="8">8</MenuItem>
                    <MenuItem value="9">9</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                    <MenuItem value="12">12</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="tyrePressure"
                      color="primary"
                      checked={tyrePresssure} // Assuming tyrePressure is a boolean state
                      onChange={(e) => setTyrePressure(e.target.checked)} // Update state based on checked status
                    />
                  }
                  label="Tyre Pressure Monitoring System (TPMS)"
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="childSeatAnchorPoints"
                      color="primary"
                      checked={childSeatAnchorPoints}
                      onChange={(e) =>
                        setchildSeatAnchorPoints(e.target.checked)
                      }
                    />
                  }
                  label="Child Seat Anchor Points"
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="seatBeltWarning"
                      color="primary"
                      checked={seatBeltWarning}
                      onChange={(e) => setSeatBeltWarning(e.target.checked)}
                    />
                  }
                  label="Seat Belt Warning"
                />
              </Grid>
          
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  margin: "10px",
                  fontWeight: "800",
                }}
              >
                Braking & Traction
              </Typography>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="Anti-Lock Braking System (ABS)"
                        color="primary"
                        checked={abs}
                        onChange={(e) => setAbs(e.target.checked)}
                      />
                    }
                    label="Anti-Lock Braking System (ABS)"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="Electronic Brake-force Distribution (EBD)"
                        color="primary"
                        checked={ebd}
                        onChange={(e) => setEbd(e.target.checked)}
                      />
                    }
                    label="Electronic Brake-force Distribution (EBD)"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="Brake Assist (BA)"
                        color="primary"
                        checked={ba}
                        onChange={(e) => setBa(e.target.checked)}
                      />
                    }
                    label="Brake Assist (BA)"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="Electronic Stability Program (ESP)"
                        color="primary"
                        checked={esp}
                        onChange={(e) => setEsp(e.target.checked)}
                      />
                    }
                    label="Electronic Stability Program (ESP)"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="Four-Wheel-Drive"
                        color="primary"
                        checked={fourWheel}
                        onChange={(e) => setFourWheelDrive(e.target.checked)}
                      />
                    }
                    label="Four-Wheel-Drive"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="Hill Hold Control"
                        color="primary"
                        checked={hillHoldControl}
                        onChange={(e) => setHillHoldControl(e.target.checked)}
                      />
                    }
                    label="Hill Hold Control"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="Traction Control System (TC/TCS)"
                        color="primary"
                        checked={tcs}
                        onChange={(e) => setTcs(e.target.checked)}
                      />
                    }
                    label="Traction Control System (TC/TCS)"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="Hill Descent Control"
                        color="primary"
                        checked={hillDescentControl}
                        onChange={(e) =>
                          setHillDescentControl(e.target.checked)
                        }
                      />
                    }
                    label="Hill Descent Control"
                  />
                </Grid>
              </Grid>

              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  margin: "10px",
                  fontWeight: "800",
                }}
              >
                Locks & Security
              </Typography>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="engineImmobiliser"
                      color="primary"
                      checked={engineImmobiliser}
                      onChange={(e) => setEngineImmobiliser(e.target.checked)}
                    />
                  }
                  label="Engine Immobiliser"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="centralLocking"
                      color="primary"
                      checked={centeralLocking}
                      onChange={(e) => setCenteralLocking(e.target.checked)}
                    />
                  }
                  label="Central Locking"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="speedSensingDoorLock"
                      color="primary"
                      checked={speedSensingDoorLock}
                      onChange={(e) =>
                        setSpeedSensingDoorLock(e.target.checked)
                      }
                    />
                  }
                  label="Speed Sensing Door Lock"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="childSafetyLock"
                      color="primary"
                      checked={childSafetyLock}
                      onChange={(e) => setChildSafetyLock(e.target.checked)}
                    />
                  }
                  label="Child Safety Lock"
                />
              </Grid>

              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  margin: "10px",
                  fontWeight: "800",
                }}
              >
                Comfort & Convenience
              </Typography>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="ac"
                      color="primary"
                      checked={ac}
                      onChange={(e) => setAc(e.target.checked)}
                    />
                  }
                  label="Air Conditioner"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="frontac"
                      color="primary"
                      checked={frontac}
                      onChange={(e) => setFrontAc(e.target.checked)}
                    />
                  }
                  label="Front AC"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="rearac"
                      color="primary"
                      checked={rearac}
                      onChange={(e) => setRearAc(e.target.checked)}
                    />
                  }
                  label="Rear AC"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="heater"
                      color="primary"
                      checked={heater}
                      onChange={(e) => setHeater(e.target.checked)}
                    />
                  }
                  label="Heater"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Vanity Mirrors on Sun Visors"
                      color="primary"
                      checked={vanityMirror}
                      onChange={(e) => setVanityMirror(e.target.checked)}
                    />
                  }
                  label="Vanity Mirrors on Sun Visors"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Anti-glare Mirrors"
                      color="primary"
                      checked={antiglareMirror}
                      onChange={(e) => setAntiGlareMirror(e.target.checked)}
                    />
                  }
                  label="Anti-glare Mirrors"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="parkingAssist"
                      color="primary"
                      checked={parkingAssist}
                      onChange={(e) => setparkingAssist(e.target.checked)}
                    />
                  }
                  label="Parking Assist"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Parking Sensors"
                      color="primary"
                      checked={parkingSensor}
                      onChange={(e) => setparkingSensor(e.target.checked)}
                    />
                  }
                  label="Parking Sensors"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Cruise Control"
                      color="primary"
                      checked={cruisecontrol}
                      onChange={(e) => setcruiseControl(e.target.checked)}
                    />
                  }
                  label="Cruise Control"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Headlight and Ignition On Reminder"
                      color="primary"
                      checked={headlightIgnition}
                      onChange={(e) => setHeadlightIgnition(e.target.checked)}
                    />
                  }
                  label="Headlight and Ignition On Reminder"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Keyless Start/ Button Start"
                      color="primary"
                      checked={keylessstart}
                      onChange={(e) => setKeylessStart(e.target.checked)}
                    />
                  }
                  label="Keyless Start/ Button Start"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="SteeringHeightAdjustment"
                      color="primary"
                      checked={steeringAdjustment}
                      onChange={(e) => setSteeringAdjustment(e.target.checked)}
                    />
                  }
                  label="Steering Height Adjustment"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="12V Power Outlets"
                      color="primary"
                      checked={power}
                      onChange={(e) => setPower(e.target.checked)}
                    />
                  }
                  label="12V Power Outlets"
                />
              </Grid>
            

              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  margin: "10px",
                  fontWeight: "800",
                }}
              >
                Mobile App Features
              </Typography>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="applecarplay"
                      color="primary"
                      checked={applecarplay}
                      onChange={(e) => setApplecarplay(e.target.checked)}
                    />
                  }
                  label="Apple Carplay"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="androidauto"
                      color="primary"
                      checked={androidauto}
                      onChange={(e) => setAndroidauto(e.target.checked)}
                    />
                  }
                  label="Android Auto"
                />
              </Grid>
          

              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  margin: "10px",
                  fontWeight: "800",
                }}
              >
                Seats & Upholstery
              </Typography>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Seat Upholstery"
                      color="primary"
                      checked={seatUpholestry}
                      onChange={(e) => setSeatUpholestry(e.target.checked)}
                    />
                  }
                  label="Seat Upholstery"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="driverSide"
                      color="primary"
                      checked={drivesideAdjust}
                      onChange={(e) => setdriverSideAdjust(e.target.checked)}
                    />
                  }
                  label="Driver Side Seat Adjustment"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Leather-wrapped Steering Wheel"
                      color="primary"
                      checked={leatherWrapped}
                      onChange={(e) => setLeatherWrapped(e.target.checked)}
                    />
                  }
                  label="Leather-wrapped Steering Wheel"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Driver Armrest"
                      color="primary"
                      checked={driverArmrest}
                      onChange={(e) => setDriverArmrest(e.target.checked)}
                    />
                  }
                  label="Driver Armrest"
                />
              </Grid>
         
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Folding Rear Seat"
                      color="primary"
                      checked={foldingRearSeat}
                      onChange={(e) => setFoldingRearSeat(e.target.checked)}
                    />
                  }
                  label="Folding Rear Seat"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="splitRearSeat"
                      color="primary"
                      checked={splitRearSeat}
                      onChange={(e) => setSplitRearSeat(e.target.checked)}
                    />
                  }
                  label="Split Rear Seat"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Split Third Row Seat"
                      color="primary"
                      checked={splitThirdRowSeat}
                      onChange={(e) => setSplitThirdRowSeat(e.target.checked)}
                    />
                  }
                  label="Split Third Row Seat"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Front Seatback Pockets"
                      color="primary"
                      checked={frontSeatbackPockets}
                      onChange={(e) =>
                        setFrontSeatBackPockets(e.target.checked)
                      }
                    />
                  }
                  label="Front Seatback Pockets"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Headrests"
                      color="primary"
                      checked={headrests}
                      onChange={(e) => setHeadrests(e.target.checked)}
                    />
                  }
                  label="Headrests"
                />
              </Grid>
         
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  margin: "10px",
                  fontWeight: "800",
                }}
              >
                Storage
              </Typography>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="cupholders"
                      color="primary"
                      checked={cupholders}
                      onChange={(e) => setcupHolders(e.target.checked)}
                    />
                  }
                  label="Cup Holders"
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Driver Armrest Storage"
                      color="primary"
                      checked={driverArmrestStorage}
                      onChange={(e) =>
                        setDriverArmrestStorage(e.target.checked)
                      }
                    />
                  }
                  label="Driver Armrest Storage"
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Cooled Glovebox"
                      color="primary"
                      checked={cooledGloveBox}
                      onChange={(e) => setCooledGloveBox(e.target.checked)}
                    />
                  }
                  label="Cooled Glovebox"
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Sunglass Holder"
                      color="primary"
                      checked={sunGlassHolder}
                      onChange={(e) => setSunglassHolder(e.target.checked)}
                    />
                  }
                  label="Sunglass Holder"
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Third Row Cup Holders"
                      color="primary"
                      checked={thirdRowCupHolder}
                      onChange={(e) => setThirdRowCupHolder(e.target.checked)}
                    />
                  }
                  label="Third Row Cup Holders"
                />
              </Grid>

         
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  margin: "10px",
                  fontWeight: "800",
                }}
              >
                Doors, Windows, Mirrors & Wipers
              </Typography>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="ORVM Colour"
                      color="primary"
                      checked={orvm}
                      onChange={(e) => setOrvm(e.target.checked)}
                    />
                  }
                  label="Body Color ORVM"
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Power Windows"
                      color="primary"
                      checked={powerWindow}
                      onChange={(e) => setPowerWindow(e.target.checked)}
                    />
                  }
                  label="Power Windows"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="One Touch Down"
                      color="primary"
                      checked={oneTouchDown}
                      onChange={(e) => setOneTouchDown(e.target.checked)}
                    />
                  }
                  label="One Touch Down"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="One Touch Up"
                      color="primary"
                      checked={oneTouchUp}
                      onChange={(e) => setOneTouchUp(e.target.checked)}
                    />
                  }
                  label="One Touch Up"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Adjustable ORVMs"
                      color="primary"
                      checked={adjustableOrvm}
                      onChange={(e) => setAdjustableOrvm(e.target.checked)}
                    />
                  }
                  label="Electric Adjustable ORVMs"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Body color Exterior Door Handles"
                      color="primary"
                      checked={exteriorDoorHandle}
                      onChange={(e) => setExteriorDoorHandle(e.target.checked)}
                    />
                  }
                  label="Body Color Exterior Door Handles"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="chromeExterior Door Handles"
                      color="primary"
                      checked={chromeexteriorDoorHandle}
                      onChange={(e) =>
                        setChromeExteriorDoorHandle(e.target.checked)
                      }
                    />
                  }
                  label="Chrome Exterior Door Handles"
                />
              </Grid>
          
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Door Pockets"
                      color="primary"
                      checked={doorPockets}
                      onChange={(e) => setDoorPockets(e.target.checked)}
                    />
                  }
                  label="Door Pockets"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Bootlid Opener"
                      color="primary"
                      checked={bootlidOpener}
                      onChange={(e) => setBootlidOpener(e.target.checked)}
                    />
                  }
                  label="Bootlid Opener"
                />
              </Grid>


              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  margin: "10px",
                  fontWeight: "800",
                }}
              >
                Exterior
              </Typography>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Sunroof / Moonroof"
                      color="primary"
                      checked={sunroof}
                      onChange={(e) => setsunRoof(e.target.checked)}
                    />
                  }
                  label="Sunroof / Moonroof"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Roof-mounted Antenna"
                      color="primary"
                      checked={roofMountedAntenna}
                      onChange={(e) => setRoofMountedAntenna(e.target.checked)}
                    />
                  }
                  label="Roof-mounted Antenna"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Body-coloured Bumpers"
                      color="primary"
                      checked={bodyColouredBumpers}
                      onChange={(e) => setBodyColouredBumpers(e.target.checked)}
                    />
                  }
                  label="Body-coloured Bumpers"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Chrome Finish Exhaust Pipe"
                      color="primary"
                      checked={chromeFinish}
                      onChange={(e) => setChromeFinish(e.target.checked)}
                    />
                  }
                  label="Chrome Finish Exhaust Pipe"
                />
              </Grid>

          
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  margin: "10px",
                  fontWeight: "800",
                }}
              >
                Lighting
              </Typography>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="projectorHeadlights"
                      color="primary"
                      checked={projectorheadlight}
                      onChange={(e) => setProjectorHeadlight(e.target.checked)}
                    />
                  }
                  label="Projector Headlights"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Automatic Headlamps"
                      color="primary"
                      checked={autoheadlamps}
                      onChange={(e) => setAutoheadlamps(e.target.checked)}
                    />
                  }
                  label="Automatic Headlamps"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Follow Me Home Headlamps"
                      color="primary"
                      checked={homeheadlamps}
                      onChange={(e) => setHomeheadlamps(e.target.checked)}
                    />
                  }
                  label="Follow Me Home Headlamps"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="drl"
                      color="primary"
                      checked={drl}
                      onChange={(e) => setDrl(e.target.checked)}
                    />
                  }
                  label="DRL"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Taillights"
                      color="primary"
                      checked={tailights}
                      onChange={(e) => setTailLights(e.target.checked)}
                    />
                  }
                  label="LED Taillights"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="cabinLamps"
                      color="primary"
                      checked={cabinLamps}
                      onChange={(e) => setCabinlamps(e.target.checked)}
                    />
                  }
                  label="Cabin Lamps"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Light on Vanity Mirrors"
                      color="primary"
                      checked={lightonVanityMirror}
                      onChange={(e) => setLightonVanityMirror(e.target.checked)}
                    />
                  }
                  label="Light on Vanity Mirrors"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Rear Reading Lamp"
                      color="primary"
                      checked={rearreadinglamp}
                      onChange={(e) => setRearreadinglamp(e.target.checked)}
                    />
                  }
                  label="Rear Reading Lamp"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Glovebox Lamp"
                      color="primary"
                      checked={gloveBoxlamp}
                      onChange={(e) => setGloveboxlamp(e.target.checked)}
                    />
                  }
                  label="Glovebox Lamp"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Headlight Height Adjuster"
                      color="primary"
                      checked={headlightHeightAdjuster}
                      onChange={(e) =>
                        setHeadlightHEightAdjuster(e.target.checked)
                      }
                    />
                  }
                  label="Headlight Height Adjuster"
                />
              </Grid>
        
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  margin: "10px",
                  fontWeight: "800",
                }}
              >
                Instrumentation
              </Typography>
            
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Trip Meter"
                      color="primary"
                      checked={tripMeter}
                      onChange={(e) => setTripMeter(e.target.checked)}
                    />
                  }
                  label="Trip Meter"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Average Fuel Consumption"
                      color="primary"
                      checked={averageFuelConsumption}
                      onChange={(e) =>
                        setAverageFuelConsumption(e.target.checked)
                      }
                    />
                  }
                  label="Average Fuel Consumption"
                />
              </Grid>
           
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Distance to Empty"
                      color="primary"
                      checked={distancetoEmpty}
                      onChange={(e) => setDistancetoEmpty(e.target.checked)}
                    />
                  }
                  label="Distance to Empty"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="clock"
                      color="primary"
                      checked={clock}
                      onChange={(e) => setClock(e.target.checked)}
                    />
                  }
                  label="Clock"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Low Fuel Level Warning"
                      color="primary"
                      checked={lowFuelWarning}
                      onChange={(e) => setLowFuelWarning(e.target.checked)}
                    />
                  }
                  label="Low Fuel Level Warning"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Door Ajar Warning"
                      color="primary"
                      checked={doorAjarWarning}
                      onChange={(e) => setDooeAjarWarning(e.target.checked)}
                    />
                  }
                  label="Door Ajar Warning"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Adjustable Cluster Brightness"
                      color="primary"
                      checked={adjustableCluster}
                      onChange={(e) => setAdjustableCluster(e.target.checked)}
                    />
                  }
                  label="Adjustable Cluster Brightness"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Gear Indicator"
                      color="primary"
                      checked={gearIndicator}
                      onChange={(e) => setGearIndicator(e.target.checked)}
                    />
                  }
                  label="Gear Indicator"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Shift Indicator"
                      color="primary"
                      checked={shiftIndicator}
                      onChange={(e) => setShiftIndicator(e.target.checked)}
                    />
                  }
                  label="Shift Indicator"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Tachometer"
                      color="primary"
                      checked={tachometer}
                      onChange={(e) => setTachometer(e.target.checked)}
                    />
                  }
                  label="Tachometer"
                />
              </Grid>
           
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  margin: "10px",
                  fontWeight: "800",
                }}
              >
                Entertainment, Information & Communication
              </Typography>

          
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Integrated (in-dash) Music System"
                      color="primary"
                      checked={musicSystem}
                      onChange={(e) => setMusicSystem(e.target.checked)}
                    />
                  }
                  label="Integrated (in-dash) Music System"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Speakers"
                      color="primary"
                      checked={speaker}
                      onChange={(e) => setSpeaker(e.target.checked)}
                    />
                  }
                  label="Speakers"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Steering-mounted controls"
                      color="primary"
                      checked={steeringmountedctrls}
                      onChange={(e) =>
                        setSteeringmountedctrls(e.target.checked)
                      }
                    />
                  }
                  label="Steering-mounted controls"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Voice Command"
                      color="primary"
                      checked={voiceCommand}
                      onChange={(e) => setVoiceCommand(e.target.checked)}
                    />
                  }
                  label="Voice Command"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="GPS Navigation System"
                      color="primary"
                      checked={gps}
                      onChange={(e) => setGps(e.target.checked)}
                    />
                  }
                  label="GPS Navigation System"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Bluetooth Compatibility"
                      color="primary"
                      checked={bluetooth}
                      onChange={(e) => setBluetooth(e.target.checked)}
                    />
                  }
                  label="Bluetooth Compatibility"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="AUX Compatibility"
                      color="primary"
                      checked={aux}
                      onChange={(e) => setAux(e.target.checked)}
                    />
                  }
                  label="AUX Compatibility"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="AM/FM Radio"
                      color="primary"
                      checked={radio}
                      onChange={(e) => setRadio(e.target.checked)}
                    />
                  }
                  label="AM/FM Radio"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="USB Compatibility"
                      color="primary"
                      checked={usb}
                      onChange={(e) => setUsb(e.target.checked)}
                    />
                  }
                  label="USB Compatibility"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Head Unit Size"
                      color="primary"
                      checked={headUnitSize}
                      onChange={(e) => setHeadUnitSize(e.target.checked)}
                    />
                  }
                  label="Head Unit Size"
                />
              </Grid>

              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  margin: "10px",
                  fontWeight: "800",
                }}
              >
                Manufacturer Warranty
              </Typography>

              <Grid item xs={12}>
                <TextField
                  label="Description"
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                  sx={{ marginBottom: 2 }}
                  value={des12}
                  onChange={(e) => setdes12(e.target.value)}
                />
              </Grid>
              <Box
                sx={{ display: "flex", justifyContent: "center", gap: "22px" }}
              >
                <Button
                 type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: "#E03012",
                    fontWeight: "bold",
                    width: "150px",
                    "&:hover": {
                      backgroundColor: "#E03012",
                    },
                  }}
                >
                  Submit
                </Button>
              </Box>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
