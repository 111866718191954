import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Box,
  Grid,
  Dialog,
  DialogContent,
  TextField,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import "./Home.css";
import "../../../App.css";

const Home = (props) => {
  const [openTestDriveModal, setOpenTestDriveModal] = useState(false);
  const [openServiceModal, setOpenServiceModal] = useState(false);

  const handleTestDriveOpen = () => setOpenTestDriveModal(true);
  const handleTestDriveClose = () => setOpenTestDriveModal(false);

  const handleServiceOpen = () => setOpenServiceModal(true);
  const handleServiceClose = () => setOpenServiceModal(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    modelType: "",
    carRegistrationNumber: "",
    preferredServiceCenterLocation: "",
    date: "",
  });
  const [formData1, setFormData1] = useState({
    name: "",
    email: "",
    mobile: "",
    modelType: "",
    date: "",
    address: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setFormData1((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmitService = (e) => {
    e.preventDefault();

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      alert("Please enter a valid email address.");
      return;
    }

    // Mobile validation (10 digits, numeric only)
    const mobileRegex = /^[0-9]{10}$/;
    if (!mobileRegex.test(formData.mobile)) {
      alert("Please enter a valid 10-digit mobile number.");
      return;
    }

    props.AddServiceForm(
      formData.name,
      formData.email,
      formData.mobile,
      formData.modelType,
      formData.carRegistrationNumber,
      formData.preferredServiceCenterLocation,
      formData.date
    );

    // Reset formData to empty strings
    setFormData({
      name: "",
      email: "",
      mobile: "",
      modelType: "",
      carRegistrationNumber: "",
      preferredServiceCenterLocation: "",
      date: "",
    });
    handleServiceClose();
  };
  const handleSubmitTestDrive = (e) => {
    e.preventDefault();
    // Perform validation or submit action here
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData1.email)) {
      alert("Please enter a valid email address.");
      return;
    }

    // Mobile validation (10 digits, numeric only)
    const mobileRegex = /^[0-9]{10}$/;
    if (!mobileRegex.test(formData1.mobile)) {
      alert("Please enter a valid 10-digit mobile number.");
      return;
    }

    props.BookTestDrive(
      formData1.name,
      formData1.email,
      formData1.mobile,
      formData1.modelType,
      formData1.date,
      formData1.address
    );
    setFormData1({
      name: "",
      email: "",
      mobile: "",
      modelType: "",
      date: "",
      address: "",
    });
    handleTestDriveClose();
  };

  const items = [
    { text: "Book a test drive", action: "modal" },
    { text: "Get Quotations", link: "/quotations" },
    { text: "Book Service Appointment", action: "modal-service" },
    { text: "Download Brochure", link: "/brochure" },
  ];
  useEffect(() => {
    props.viewAddesByStatus();
  }, []);
  return (
    <Grid container>
      {/* 4 card section */}
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} sm={6} md={5}>
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "relative",
              }}>
              {props.client.addesByStatus?.mediaType === "image" ? (
                <img
                  src={props.client.addesByStatus?.image}
                  alt="Car"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "fill",
                  }}
                />
              ) : (
                <video
                  src={props.client.addesByStatus?.image}
                  autoPlay
                  muted
                  loop
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                />
              )}
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={7}>
            <Grid container>
              {items.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  key={index}
                  id={`right${index + 1}`}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    margin: 0,
                    padding: "8px",
                    letterSpacing: { xs: "0.1px", md: "0.2px" },
                    wordSpacing: { xs: "0.3px", md: "2px" },
                    cursor: "pointer",
                    fontFamily: "KIAbodyLatinbasic_Regular",
                    border: "1px solid transparent",
                    "&:hover": {
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                    },
                  }}
                  onClick={() => {
                    if (item.action === "modal") {
                      handleTestDriveOpen();
                    } else if (item.action === "modal-service") {
                      handleServiceOpen();
                    }
                  }}>
                  {item.link ? (
                    <Link
                      to={item.link}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "7px",
                        textDecoration: "none",
                        justifyContent: "center",
                        fontSize: { xs: "12px", sm: "14px", md: "16px" },
                        color: "inherit",
                        width: "100%",
                        height: "100%",
                        fontFamily: "KIAbodyLatinbasic_Regular",
                      }}>
                      <i
                        className={`fa-solid fa-${
                          index === 0
                            ? "car"
                            : index === 1
                            ? "quote-left"
                            : index === 2
                            ? "gears"
                            : "book"
                        }`}
                        style={{ marginRight: "8px" }}></i>
                      {item.text}
                    </Link>
                  ) : (
                    <>
                      <i
                        className={`fa-solid fa-${
                          index === 0
                            ? "car"
                            : index === 1
                            ? "quote-left"
                            : index === 2
                            ? "gears"
                            : "book"
                        }`}
                        style={{ marginRight: "8px" }}></i>
                      <p
                        style={{
                          margin: 0,
                          fontFamily: "KIAbodyLatinbasic_Regular",
                          // fontSize: { xs: "12px", sm: "14px", md: "18px" },
                        }}>
                        {item.text}
                      </p>
                    </>
                  )}
                </Grid>
              ))}
            </Grid>

            {/* Service */}
            <Dialog
              open={openServiceModal}
              onClose={handleServiceClose}
              maxWidth="md"
              fullWidth
              BackdropProps={{
                style: {
                  backgroundColor: "rgba(0, 0, 0, 0.3)",
                  backdropFilter: "blur(5px)",
                },
              }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "10px",
                }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "90%",
                  }}>
                  <Typography
                    sx={{
                      color: "#D62E31",
                      textAlign: "center",
                      fontSize: { md: "40px", xs: "20px", sm: "35px" },
                      fontFamily: "KIAbodyLatinbasic, Arial, sans-serif",
                      fontWeight: "bolder",
                    }}>
                    Service Appointment Form
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "50px",
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={handleServiceClose}>
                  <CloseIcon />
                </Box>
              </Box>

              <DialogContent>
                <form onSubmit={handleSubmitService}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        className="KIAbodyLatinbasic_Regular"
                        sx={{
                          fontSize: "12px",
                          textAlign: "center",
                          fontFamily: "KIAbodyLatinbasic_Regular",
                        }}>
                        Please fill in the mandatory details for Service
                        Appointment.
                      </Typography>
                    </Grid>

                    {/* Name and Email */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        margin="dense"
                        label="Name"
                        name="name"
                        fullWidth
                        required
                        value={formData.name}
                        onChange={handleChange}
                        InputLabelProps={{
                          sx: { "& .MuiInputLabel-asterisk": { color: "red" } },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        margin="dense"
                        label="Email"
                        name="email"
                        type="email"
                        fullWidth
                        required
                        value={formData.email}
                        onChange={handleChange}
                        InputLabelProps={{
                          sx: { "& .MuiInputLabel-asterisk": { color: "red" } },
                        }}
                      />
                    </Grid>

                    {/* Mobile and Model */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        margin="dense"
                        label="Mobile"
                        name="mobile"
                        type="number"
                        fullWidth
                        required
                        value={formData.mobile}
                        onChange={handleChange}
                        InputLabelProps={{
                          sx: { "& .MuiInputLabel-asterisk": { color: "red" } },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth margin="dense" required>
                        <InputLabel
                          sx={{
                            "& .MuiInputLabel-asterisk": { color: "red" },
                          }}>
                          Model
                        </InputLabel>
                        <Select
                          name="modelType"
                          value={formData.modelType}
                          onChange={handleChange}>
                          <MenuItem value="seltos">Seltos</MenuItem>
                          <MenuItem value="sonet">Sonet</MenuItem>
                          <MenuItem value="carens">Carens</MenuItem>
                          <MenuItem value="ev6">EV6</MenuItem>
                          <MenuItem value="carnival">Carnival</MenuItem>
                          <MenuItem value="ev9">EV9</MenuItem>
                          <MenuItem value="syros">Syros</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    {/* Car Registration and Service Center */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        required
                        label="Car Registration Number"
                        type="text"
                        variant="standard"
                        placeholder="e.g: OD39ZZ5697"
                        name="carRegistrationNumber"
                        value={formData.carRegistrationNumber}
                        onChange={handleChange}
                        InputLabelProps={{
                          sx: {
                            "& .MuiInputLabel-asterisk": { color: "#d32f2f" },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth required variant="standard">
                        <InputLabel
                          sx={{
                            "& .MuiInputLabel-asterisk": { color: "#d32f2f" },
                          }}>
                          Preferred Service Center Location
                        </InputLabel>
                        <Select
                          name="preferredServiceCenterLocation"
                          value={formData.preferredServiceCenterLocation}
                          onChange={handleChange}>
                          <MenuItem value="bbsr">Bhubaneswar</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    {/* Preferred Date */}
                    <Grid item xs={12}>
                      <TextField
                        margin="dense"
                        label="Preferred Date for Service"
                        name="date"
                        type="datetime-local"
                        fullWidth
                        value={formData.date}
                        onChange={handleChange}
                        InputLabelProps={{
                          shrink: true,
                          sx: { "& .MuiInputLabel-asterisk": { color: "red" } },
                        }}
                        required
                      />
                    </Grid>

                    {/* Submit Button */}
                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{
                          backgroundColor: "#D23132",
                          fontWeight: "bolder",
                          "&:hover": {
                            backgroundColor: "#D23132",
                          },
                        }}>
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </DialogContent>
            </Dialog>

            {/* Test Drive */}
            <Dialog
              open={openTestDriveModal}
              onClose={handleTestDriveClose}
              maxWidth="md"
              fullWidth
              BackdropProps={{
                style: {
                  backgroundColor: "rgba(0, 0, 0, 0.3)",
                  backdropFilter: "blur(5px)",
                },
              }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <Box
                  sx={{
                    width: "800px",
                  }}>
                  <Typography
                    sx={{
                      color: "#D62E31",
                      textAlign: "center",
                      fontSize: { md: "40px", xs: "20px", sm: "35px" },
                      fontFamily: "KIAbodyLatinbasic, Arial, sans-serif",
                      fontWeight: "bolder",
                    }}>
                    Book a Test Drive
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "50px",
                  }}
                  onClick={handleTestDriveClose}>
                  <CloseIcon />
                </Box>
              </Box>
              <DialogContent>
                <form onSubmit={handleSubmitTestDrive}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "12px",
                          textAlign: "center",
                          fontWeight: "700",
                          fontFamily: "KIAbodyLatinbasic_Regular",
                        }}>
                        Please fill in the mandatory details for booking a Test
                        Drive.
                      </Typography>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="dense"
                          label="Name"
                          name="name"
                          fullWidth
                          required
                          value={formData1.name}
                          onChange={handleChange1}
                          InputLabelProps={{
                            sx: {
                              "& .MuiInputLabel-asterisk": { color: "red" },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="dense"
                          label="Email"
                          name="email"
                          type="email"
                          fullWidth
                          required
                          value={formData1.email}
                          onChange={handleChange1}
                          InputLabelProps={{
                            sx: {
                              "& .MuiInputLabel-asterisk": { color: "red" },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="dense"
                          label="Mobile"
                          name="mobile"
                          type="number"
                          fullWidth
                          required
                          value={formData1.mobile}
                          onChange={handleChange1}
                          InputLabelProps={{
                            sx: {
                              "& .MuiInputLabel-asterisk": { color: "red" },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth margin="dense" required>
                          <InputLabel
                            sx={{
                              "& .MuiInputLabel-asterisk": { color: "red" },
                            }}>
                            Model Type
                          </InputLabel>
                          <Select
                            name="modelType"
                            value={formData1.modelType}
                            onChange={handleChange1}>
                            <MenuItem value="seltos">Seltos</MenuItem>
                            <MenuItem value="sonet">Sonet</MenuItem>
                            <MenuItem value="carens">Carens</MenuItem>
                            <MenuItem value="ev6">EV6</MenuItem>
                            <MenuItem value="carnival">Carnival</MenuItem>
                            <MenuItem value="ev9">EV9</MenuItem>
                            <MenuItem value="syros">Syros</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="dense"
                          label="Test Drive Date & Time"
                          name="date"
                          type="datetime-local"
                          fullWidth
                          value={formData1.date}
                          onChange={handleChange1}
                          InputLabelProps={{
                            shrink: true,
                            sx: {
                              "& .MuiInputLabel-asterisk": { color: "red" },
                            },
                          }}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="dense"
                          label="Address"
                          name="address"
                          type="text"
                          fullWidth
                          value={formData1.address}
                          onChange={handleChange1}
                          InputLabelProps={{
                            shrink: true,
                            sx: {
                              "& .MuiInputLabel-asterisk": { color: "red" },
                            },
                          }}
                          required
                        />
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{
                          backgroundColor: "#D23132",
                          fontWeight: "bolder",
                          "&:hover": {
                            backgroundColor: "#D23132",
                          },
                        }}>
                        Submit
                      </Button>
                    </Box>
                  </Grid>
                </form>
              </DialogContent>
            </Dialog>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Home;
