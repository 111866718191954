import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Collapse,
  List,
  ListItem,
  ListItemText,
  TextField,
  InputAdornment,
  Card,
  CardContent,
  CardMedia,
  Button,
  Modal,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "./preowned.css";
import { debounce } from "lodash";
import Wsp from '../../contact/Component/Floatingwsp';

const CarPage = (props) => {
  const [isBudgetOpen, setIsBudgetOpen] = useState(false);
  const [selectedBudget, setSelectedBudget] = useState(null);
  const [isSellerTypeOpen, setIsSellerTypeOpen] = useState(false);
  const [isOwnerOpen, setIsOwnerOpen] = useState(false);
  const [isFuelOpen, setIsFuelOpen] = useState(false);
  const [selectedFuels, setSelectedFuels] = useState([]);
  const [isTransmissionOpen, setIsTransmissionOpen] = useState(false);
  const [selectedTransmissions, setSelectedTransmissions] = useState([]);
  const [query, setQuery] = useState("");
  const [isBrandOpen, setIsBrandOpen] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState([]);

  const [selectedOwners, setSelectedOwners] = useState([]);
  const toggleFuelDropdown = () => setIsFuelOpen(!isFuelOpen);
  const toggleBrandDropdown = () => setIsBrandOpen(!isBrandOpen);
  const toggleTransmissionDropdown = () =>
    setIsTransmissionOpen(!isTransmissionOpen);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setSelectedFuels((prevSelected) =>
      checked
        ? [...prevSelected, name]
        : prevSelected.filter((fuel) => fuel !== name)
    );
    props.viewAllOldCar(
      selectedBudget,
      selectedFuels,
      selectedTransmissions,
      selectedOwners,
      query,
      selectedBrand
    );
  };
  const handleCheckboxChangeBrand = (event) => {
    const { name, checked } = event.target;
    setSelectedBrand((prevSelected) =>
      checked
        ? [...prevSelected, name]
        : prevSelected.filter((brand) => brand !== name)
    );
    props.viewAllOldCar(
      selectedBudget,
      selectedFuels,
      selectedTransmissions,
      selectedOwners,
      query,
      selectedBrand
    );
  };
  const handleTransmissionChange = (event) => {
    const { name, checked } = event.target;
    setSelectedTransmissions((prevSelected) =>
      checked
        ? [...prevSelected, name]
        : prevSelected.filter((trans) => trans !== name)
    );
    props.viewAllOldCar(
      selectedBudget,
      selectedFuels,
      selectedTransmissions,
      selectedOwners,
      query,
      selectedBrand
    );
  };
  const handleOwnerChange = (event) => {
    const { name, checked } = event.target;
    setSelectedOwners((prevSelected) =>
      checked
        ? [...prevSelected, name]
        : prevSelected.filter((owner) => owner !== name)
    );
    props.viewAllOldCar(
      selectedBudget,
      selectedFuels,
      selectedTransmissions,
      selectedOwners,
      query,
      selectedBrand
    );
  };
  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      if (searchQuery) {
        // Send the search query data, e.g., API call here
        props.viewAllOldCar(
          selectedBudget,
          selectedFuels,
          selectedTransmissions,
          selectedOwners,
          searchQuery,
          selectedBrand
        );
      }
    }, 500),
    []
  );
  const toggleBudgetDropdown = () => {
    setIsBudgetOpen(!isBudgetOpen);
  };

  const handleBudgetSelect = (budget) => {
    setSelectedBudget(budget); // Set selected budget without filtering
    props.viewAllOldCar(
      budget,
      selectedFuels,
      selectedTransmissions,
      selectedOwners,
      query,
      selectedBrand
    );
  };
  const handleInputChange = (e) => {
    const searchQuery = e.target.value;
    setQuery(searchQuery);
    debouncedSearch(searchQuery);
  };
  const toggleSellerTypeDropdown = () => {
    setIsSellerTypeOpen(!isSellerTypeOpen);
  };
  const handleClearAll = () => {
    setQuery("");
    setSelectedBudget("");
    setSelectedFuels([]);
    setSelectedBrand([]);
    setSelectedTransmissions([]);
    setSelectedOwners([]);
    props.viewAllOldCar(
      selectedBudget,
      selectedFuels,
      selectedTransmissions,
      selectedOwners,
      query,
      selectedBrand
    );
  };
  const toggleOwnerDropdown = () => {
    setIsOwnerOpen(!isOwnerOpen);
  };

  const [openModal, setOpenModal] = useState(false);
  const [setSelectedCar] = useState(null);
  const navigate = useNavigate();

  const handleOpenModal = (car) => {
    setSelectedCar(car);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(
    Array(props.oldcar?.all_oldCars?.length).fill(0)
  );

  const handleNextImage = (carIndex) => {
    setCurrentImageIndex((prevState) =>
      prevState.map((index, idx) =>
        idx === carIndex
          ? (index + 1) % props.oldcar.all_oldCars[carIndex].image.length
          : index
      )
    );
  };

  const handlePrevImage = (carIndex) => {
    setCurrentImageIndex((prevState) =>
      prevState.map((index, idx) =>
        idx === carIndex
          ? (index - 1 + props.oldcar.all_oldCars[carIndex].image.length) %
            props.oldcar.all_oldCars[carIndex].image.length
          : index
      )
    );
  };

  const handleImageClick = (carId, brand) => {
    navigate(`/car-details/${carId}/${brand}`);
  };

  useEffect(() => {
    props.viewAllOldCar(
      selectedBudget,
      selectedFuels,
      selectedTransmissions,
      selectedOwners,
      query,
      selectedBrand
    );

    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [
    selectedBudget,
    selectedFuels,
    selectedTransmissions,
    selectedOwners,
    query,
    selectedBrand,
  ]);

  return (
    <Box sx={{ marginTop: { md: "50px", sm: "50px", xs: "50px" },overflowX:'hidden' }}>
      <div id="banner-image-preowned"></div>
      <Box sx={{ padding: 4 }}>
        <Typography
          variant="h4"
          sx={{
            marginBottom: "12px",
            fontWeight: "700",
            fontFamily: "KIAbodyLatinbasic_Bold",
            fontSize: { xs: "22px", md: "30px", sm: "25px" },
          }}
        >
          Certified Pre-Owned Cars for Sale – All Makes & Models at Central KIA
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "12px", sm: "13px", md: "15px" },
            color: "grey",
            marginBottom: "27px",
            fontFamily: "KIAbodyLatinbasic_Regular",
            lineHeight: "1.51",
          }}
        >
         Presenting Kia Certified Pre-Owned Car Program that offers a new-age customer experience of buying a pre-owned car. All certified cars come to you after rigorous check and with an ownership assurance that will make every drive an inspiring one. You can also exchange your ‘any make, any model’ car with a new Kia car.
        </Typography>

        <Typography
             variant="h4"
             sx={{
               marginBottom: "12px",
               fontWeight: "700",
               fontFamily: "KIAbodyLatinbasic_Bold",
               fontSize: { xs: "22px", md: "30px", sm: "25px" },
             }}
        >
        Why Kia Certified Pre-Owned?
        </Typography>
        <Typography
           variant="body1"
           sx={{
             fontSize: { xs: "12px", sm: "13px", md: "15px" },
             marginBottom: "27px",
             fontFamily: "KIAbodyLatinbasic_Regular",
             lineHeight: "1.51",
           }}
        >
            <ul>
              <li> High-quality & reliable pre-owned cars</li>
              <li>Complete peace of mind for buyer and seller</li>
              <li>Exciting deals with easy to own packages</li>
              <li>Professional & transparent process</li>
              <li>Hassle-free and exemplary experience</li>
            </ul>
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {/* Left Filter Box */}
        <Grid item xs={12} md={3}>
          <Box
            sx={{
              padding: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "start",
              boxShadow: "0px 0px 10px grey",
              position: "sticky",
              top: "20px",
            }}
          >
            {/* Filters Section */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "14px",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  width: "30%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "KIAbodyLatinbasic_Regular",
                }}
              >
                <FilterAltIcon />
                Filters
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#0979BD",
                  fontFamily: "KIAbodyLatinbasic_Bold",
                }}
                onClick={handleClearAll}
              >
                Clear All
              </Typography>
            </Box>

            {/* Brand */}
            <Box sx={{ width: "100%", marginBottom: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px",
                  cursor: "pointer",
                  borderRadius: "4px",
                  backgroundColor: "#f9f9f9",
                  border: "1px solid #ddd",
                }}
                onClick={toggleBrandDropdown}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "KIAbodyLatinbasic_Bold",
                  }}
                >
                  Brand
                </Typography>
                <IconButton size="small">
                  {isBrandOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Box>

              {/* Collapsible Fuel Options */}
              <Collapse in={isBrandOpen}>
                <FormGroup sx={{ padding: "10px" }}>
                  {[
                    "MG",
                    "Maruti",
                    "Hyundai",
                    "Tata",
                    "Kia",
                    "Honda",
                    "Toyota",
                    "Mahindra",
                    "Nissan",
                    "Ford",
                    "Volkswagen",
                    "Skoda",
                    "Renault",
                    "Jeep",
                    "Mercedes-Benz",
                    "BMW",
                    "Audi",
                    "Jaguar",
                    "Land Rover",
                    "Volvo",
                  ].map((brand) => (
                    <FormControlLabel
                      key={brand}
                      control={
                        <Checkbox
                          name={brand}
                          checked={selectedBrand.includes(brand)}
                          onChange={handleCheckboxChangeBrand}
                        />
                      }
                      label={brand}
                    />
                  ))}
                </FormGroup>
              </Collapse>
            </Box>

            {/* Budget */}
            <Box sx={{ width: "100%", marginBottom: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px",
                  cursor: "pointer",
                  borderRadius: "4px",
                  border: "1px solid #ddd", // Optional: border for better visibility
                  backgroundColor: "#f9f9f9", // Optional: background color
                }}
                onClick={toggleBudgetDropdown}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "KIAbodyLatinbasic_Regular",
                  }}
                >
                  Budget
                </Typography>
                <IconButton size="small">
                  {isBudgetOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Box>

              {/* Collapsible Budget Options */}
              <Collapse in={isBudgetOpen}>
                <List>
                  {[
                    "Below ₹3 Lakh",
                    "₹3-5 Lakh",
                    "₹5-8 Lakh",
                    "₹8-12 Lakh",
                    "₹12-20 Lakh",
                    "₹20 Lakh+",
                  ].map((budget, index) => (
                    <ListItem
                      button
                      key={index}
                      onClick={() => handleBudgetSelect(budget)}
                      selected={selectedBudget === budget} // Highlight selected item
                      sx={{
                        "&:hover": {
                          backgroundColor: "#f0f0f0", // Change background on hover
                        },
                      }}
                    >
                      <ListItemText
                        primary={budget}
                        sx={{
                          fontFamily: "KIAbodyLatinbasic_Regular",
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </Box>
            <Divider sx={{ width: "100%", color: "grey" }} />
            {/* Fuel */}
            <Box sx={{ width: "100%", marginBottom: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px",
                  cursor: "pointer",
                  borderRadius: "4px",
                  fontFamily:"KIAbodyLatinbasic_Bold",
                  backgroundColor: "#f9f9f9",
                  border: "1px solid #ddd",
                }}
                onClick={toggleFuelDropdown}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "KIAbodyLatinbasic_Bold",
                  }}
                >
                  Fuel
                </Typography>
                <IconButton size="small">
                  {isFuelOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Box>

              {/* Collapsible Fuel Options */}
              <Collapse in={isFuelOpen}>
                <FormGroup sx={{ padding: "10px" }}>
                  {["Petrol", "Diesel", "CNG", "LPG", "Electric", "Hybrid"].map(
                    (fuel) => (
                      <FormControlLabel
                        key={fuel}
                        control={
                          <Checkbox
                            name={fuel}
                            checked={selectedFuels.includes(fuel)}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label={fuel}
                      />
                    )
                  )}
                </FormGroup>
              </Collapse>
            </Box>

            <Divider sx={{ width: "100%", color: "grey" }} />

            {/* Transmission */}
            <Box sx={{ width: "100%", marginBottom: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px",
                  cursor: "pointer",
                  borderRadius: "4px",
                  backgroundColor: "#f9f9f9",
                  border: "1px solid #ddd",
                }}
                onClick={toggleTransmissionDropdown}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "KIAbodyLatinbasic_Bold",
                  }}
                >
                  Transmission
                </Typography>
                <IconButton size="small">
                  {isTransmissionOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Box>

              <Collapse in={isTransmissionOpen}>
                <FormGroup sx={{ padding: "10px" }}>
                  {["Automation", "Manual", "Clutchless Manual"].map(
                    (transmission) => (
                      <FormControlLabel
                        key={transmission}
                        control={
                          <Checkbox
                            name={transmission}
                            checked={selectedTransmissions.includes(
                              transmission
                            )}
                            onChange={handleTransmissionChange}
                          />
                        }
                        label={transmission}
                      />
                    )
                  )}
                </FormGroup>
              </Collapse>
            </Box>
            <Divider sx={{ width: "100%", color: "grey" }} />

            {/* Owners */}
            <Box sx={{ width: "100%", marginBottom: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px",
                  cursor: "pointer",
                  borderRadius: "4px",
                  backgroundColor: "#f9f9f9",
                  border: "1px solid #ddd",

                }}
                onClick={toggleOwnerDropdown}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "KIAbodyLatinbasic_Regular",
                  }}
                >
                  Owners
                </Typography>
                <IconButton size="small">
                  {isOwnerOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Box>

              <Collapse in={isOwnerOpen}>
                <FormGroup sx={{ padding: "10px" }}>
                  {[
                    "First Owner",
                    "Second Owner",
                    "Third or More",
                    "Unregistered Car",
                  ].map((owner) => (
                    <FormControlLabel
                      key={owner}
                      control={
                        <Checkbox
                          name={owner}
                          checked={selectedOwners.includes(owner)}
                          onChange={handleOwnerChange}
                        />
                      }
                      label={owner}
                    />
                  ))}
                </FormGroup>
              </Collapse>
            </Box>

            <Divider sx={{ width: "100%", color: "grey" }} />
          </Box>
        </Grid>

        {/* Right Side */}
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 2,
            }}
          >
            <TextField
              label="Search Cars"
              variant="outlined"
              fullWidth
              onChange={handleInputChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          {/* Cards Section */}
          <Grid container spacing={2}>
            {Array.isArray(props.oldcar?.all_oldCars) &&
              props.oldcar?.all_oldCars?.map((car, index) => (
                <Grid item xs={12} md={6} lg={6} key={car.id}>
                  <Card sx={{ boxShadow: 3 }}>
                    <Box sx={{ position: "relative" }}>
                      <Box
                        onClick={() => handleImageClick(car._id, car.brand)}
                        sx={{ cursor: "pointer" }}
                      >
                        <CardMedia
                          component="img" loading="eager" decoding="async"
                          image={car.image[currentImageIndex[index]]}
                          alt={car.carName}
                          sx={{ height: "250px", objectFit: "cover" }}
                        />
                      </Box>
                      <IconButton
                        onClick={() => handlePrevImage(index)}
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "10px",
                          transform: "translateY(-50%)",
                          backgroundColor: "rgba(255,255,255,0.7)",
                        }}
                      >
                        <ChevronLeftIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleNextImage(index)}
                        sx={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          backgroundColor: "rgba(255,255,255,0.7)",
                        }}
                      >
                        <ChevronRightIcon />
                      </IconButton>
                    </Box>
                    <CardContent>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "800", paddingBottom: "8px" }}
                      >
                        {car.carName}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ paddingBottom: "5px" }}
                      >
                        {car.fuelType}
                      </Typography>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        style={{ display: "flex" }}
                      >
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ fontWeight: "bold" }}
                        >
                          ₹{car.discountedPrice}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{
                            marginLeft: "10px",
                            textDecoration: "line-through",
                            opacity: 0.7,
                          }}
                        >
                          ₹{car.price}
                        </Typography>
                      </Grid>
                      {/* <Button
                        variant="contained"
                        fullWidth
                        sx={{
                          backgroundColor: "#E03012",
                          color: "white",
                          fontWeight: "900",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenModal(car);
                        }}
                      >
                        Get Seller Details
                      </Button> */}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>

          {/* Modal for Seller Details */}
          <Modal open={openModal} onClose={handleCloseModal}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
              }}
            >
              <Typography
                variant="h6"
                component="h2"
                sx={{ fontWeight: "800" }}
              >
                Fill in your Details
              </Typography>
              <Typography variant="h6" component="h2" sx={{ fontSize: "12px" }}>
                Provide your contact details to get seller's contact
              </Typography>
              <TextField
                label="Enter Your Full Name"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Enter Mobile Number"
                fullWidth
                margin="normal"
              />
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#E03012",
                  color: "white",
                  FontWeight: "900",
                }}
                fullWidth
              >
                Submit
              </Button>
            </Box>
          </Modal>
        </Grid>
      </Grid>
      <Wsp/>
    </Box>
  );
};

export default CarPage;
