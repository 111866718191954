import BannerD from "../../Image/Banner/banner-Kv-d.jpg";
import BannerM from "../../Image/Banner/banner-Kv-m.jpg";
const bannerData = [
  {
    cover: BannerD,
    mobileCover: BannerM,
  },
  {
    cover:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Home%2FSyrosbanner.mp4?alt=media&token=8975ab28-3598-4fe5-b269-2544522a01db",
  },
];

const imagesHome = [
  {
    cover:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Home%2Fkia%20syros.jpg?alt=media&token=4cbcb05b-9b29-4561-b502-a83bb3a9bb41",
    limk: "/syros",
  },
  {
    cover:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Home%2Fkiacarouselseltos.jpg?alt=media&token=ba953e1c-0017-4703-9350-29c64252c4b7",
    limk: "/seltos",
  },
  {
    cover:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Home%2Fkiacarouselsonet.jpg?alt=media&token=9a4934c5-56da-494c-9050-33092f58deba",
    link: "/sonet",
  },
  {
    cover:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Home%2Fkiacarouselcarens.jpg?alt=media&token=ddf8ae59-c815-4cab-ad51-4406de38beb6",
    link: "/carens",
  },
  {
    cover:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Home%2Fkiacarouselev6.jpg?alt=media&token=06327df5-5594-445c-86cd-70aeb60f66e7",
    link: "/ev6",
  },
  {
    cover:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Home%2Fkiacarouselcarnival.jpg?alt=media&token=2b3fc1bf-32f7-4c15-913b-175cdd30b022",
    link: "/carnival",
  },
  {
    cover:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Home%2Fkiacarouselev9.jpg?alt=media&token=3dfb963b-e0f5-4e87-a5af-088d7bcb48c4",
    link: "/ev9",
  },
];

const featureData = [
  {
    title:
      "Central Kia: The Largest Kia Dealership Network in Odisha | Seltos, Sonet, Carens, EV6 | Sales, Service & Spare",
    description: `Your Trusted Destination for Kia Vehicles in Odisha`,
    description1: `Central Kia is proud to be the largest Kia dealership network in Odisha, offering an extensive range of Kia cars to suit every lifestyle and budget. Whether you're looking for the stylish and powerful Kia Seltos, the compact and feature-packed Kia Sonet, the spacious and family-friendly Kia Carens, or the futuristic and eco-friendly Kia EV6, we have the perfect car for you.`,
    description2: `Wide Network of Showrooms & Service Centers
We have strategically located showrooms in Bhubaneswar, Cuttack, Angul, Jharsuguda, Sambalpur, Jajpur, and Berhampur, ensuring easy access to the latest Kia models, expert guidance, and seamless purchasing experience. Our commitment to customer satisfaction extends beyond sales, with advanced service centers in Bhubaneswar, Sambalpur, and Berhampur providing professional maintenance, repairs, and genuine Kia spare parts.`,
    textAlign: "Right",
    image:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Home%2FDJI_0909.jpg?alt=media&token=2a39f62e-eff4-490b-88cd-fc3edb6f2826",
  },
  {
    title: "Wide Presence Across Odisha",
    description: `We make it easy for you to experience the best of Kia, with
              showrooms located in:`,
    list: [
      "Angul",
      "Berhampur",
      "Bhubaneswar",
      "Cuttack",
      "Jajpur",
      "Jharsuguda",
      "Sambalpur",
    ],
    description1: `No matter where you are in Odisha, a Central Kia showroom is always within reach, providing you with seamless access to the latest Kia models, expert consultations, and a premium car-buying experience. Our widespread network ensures that you never have to travel far to explore the cutting-edge design, advanced technology, and superior performance of Kia vehicles.

Whether you're in a bustling city or a growing town, our showrooms are strategically located to bring you personalized assistance, competitive pricing, and hassle-free financing options. Our team of Kia-certified professionals is dedicated to guiding you through every step of your car-buying journey, from selecting the perfect model to securing the best deals.

At Central Kia, we don’t just sell cars—we build relationships. Our commitment to customer satisfaction, after-sales support, and high-quality maintenance services ensures that your Kia experience remains exceptional long after you drive off the lot.`,
    textAlign: "Left",
    image:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Home%2FMapCentralKia.jpg?alt=media&token=6758d159-5b68-454d-b2c0-1e2c900cf823",
  },
  {
    title:
      "State-of-the-Art Service Centers – Keeping Your Kia in Peak Condition",
    description: `At Central Kia, we are committed to ensuring that your Kia stays in top-notch condition, delivering optimal performance and reliability for years to come. Our state-of-the-art service centers in Bhubaneswar, Cuttack, Angul, Jharsuguda, Sambalpur, Jajpur, and Berhampur are equipped with the latest diagnostic tools, advanced technology, and a team of Kia-certified technicians who provide expert care for your vehicle.`,
    description1: `Comprehensive Kia Service & Maintenance  `,
    list1: [
      "Routine Maintenance & Inspections – Regular servicing, oil changes, and multi-point checks to keep your Kia running smoothly.  ",
      "Advanced Diagnostics & Repairs– Cutting-edge tools to identify and fix any mechanical or electrical issues efficiently.  ",
      "Genuine Kia Parts & Accessories – We use only authentic Kia spare parts to ensure durability, safety, and optimal vehicle performance. ",
      "Body & Paint Services – Expert solutions for scratches, dents, and accident repairs, restoring your Kia’s pristine look. ",
      "Tire & Wheel Alignment – Professional balancing, alignment, and replacement services for improved handling and safety. ",
    ],
    textAlign: "Right",
    image:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Home%2Fresponsive-d.jpg?alt=media&token=73595bb8-1c9d-4479-bcd0-b1ecc652e654",
  },
  {
    title: "Why Choose Central Kia? – Your Trusted Kia Dealership in Odisha",
    description: `At Central Kia, we are committed to delivering an exceptional car-buying and ownership experience, ensuring that every customer enjoys world-class service, competitive deals, and top-tier vehicle maintenance. Here's why we are the preferred Kia dealership in Odisha:`,
    list: [
      "Customer-Centric Approach : We prioritize your needs and preferences, offering personalized assistance at every step of your Kia journey. Our expert team is here to guide you—from helping you choose the perfect model to securing financing and ensuring a smooth after-sales experience.",
      "Exclusive Offers & Financing Options : Drive home your dream Kia with our unbeatable deals, flexible financing options, and special discounts. Whether you’re looking for a budget-friendly EMI plan or a customized finance package, we make owning a Kia more affordable than ever.",
      "Comprehensive Services for a Hassle-Free Experience. At Central Kia, we offer end-to-end services, including:",
    ],
    sublist: [
      "Test Drives at Your Convenience – Experience your favorite Kia model before making a decision.",
      "Expert Vehicle Consultation – Get professional advice to choose the right Kia for your lifestyle and budget.",
      "Smooth Buying Process – Enjoy a seamless car-buying experience with transparent pricing and hassle-free documentation.",
      "After-Sales Support – Our dedicated team ensures you get the best service, from maintenance to spare parts and beyond.",
    ],
    textAlign: "Left",
    image:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Home%2Fadaptive_d.jpg?alt=media&token=9d996193-6e66-4feb-a41b-c9ea02aaf9ff",
  },
  {
    title: "Experience the Central Kia Difference",
    description: `Experience the Central Kia Difference – Where Excellence Meets Trust Join the growing family of thousands of satisfied customers who rely on Central Kia for their automotive needs. We are more than just a dealership—we are your trusted partner in finding the perfect Kia, ensuring expert maintenance, and delivering an exceptional customer experience.`,
    description1: `What Sets Central Kia Apart?`,
    description2: `Unmatched Kia Selection – Explore our wide range of Kia models, from the stylish Sonet and Seltos to the spacious Carens and the innovative EV6. Our knowledgeable team helps you choose the Kia that best fits your lifestyle and budget.`,
    description3: `Seamless Buying Experience – Enjoy a hassle-free car-buying process with transparent pricing, quick documentation, and flexible financing options. We make your dream of owning a Kia effortless and enjoyable.`,
    description4: `World-Class Customer Service – At Central Kia, your satisfaction is our top priority. From the moment you step into our showroom to long after you drive away, we provide personalized assistance, expert guidance, and prompt support.`,
    description5: `State-of-the-Art Service & Genuine Parts – Our modern service centers in Bhubaneswar, Cuttack, Angul, Jharsuguda, Sambalpur, Jajpur, and Berhampur ensure that your Kia receives top-notch care using Kia-certified technicians and genuine parts. Whether it’s routine maintenance or major repairs, we keep your Kia running like new.`,
    textAlign: "Right",
    image:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Home%2Fconnected-desktop.jpg?alt=media&token=264ee128-dfdf-4a4e-8533-427612ea1753",
  },
];

export { imagesHome, bannerData, featureData };
