import React from "react";
import Faq from "../../../../Common/Components/Faq";
import Wsp from "../../../contact/Component/Floatingwsp";
import {
    banner2Data,
  BannerData,
  CarouselData,
  CarouselData1,
  CircleData,
  DetailInfo,
  FaqData,
  IntroData,
  StepData,
  TrimData,
} from "./Data";
import DetailedInfo from "../../../../Common/Components/DetailedInfo";
import MeetTheFamily from "../../../../Common/Components/MeetTheFamily";
import CarouselPart from "../../../../Common/Components/CarouselPart";
import VideoFrame from "../../../../Common/Components/VideoFrame";
import CarStepper from "../../../../Common/Components/CarStepper";
import ShowInterest from "../../../../Common/Components/ShowInterest";
import { Box, Grid } from "@mui/material";
import Banner2 from "../../../../Common/Components/Banner2";
import CarModel from "../../../../Common/Components/CarModel";
import Intro from "../../../../Common/Components/Intro";
import Banner from "../../../../Common/Components/Banner";
import InterestDealer from "../../../../Common/Components/InterestDealer";

export default function CARNIVAL() {
  return (
    <>
      <Banner bannerData={BannerData} />
          <InterestDealer />
    <Intro data={IntroData} />
    <CarModel
        model="Carnival Limousine+"
        bgCar={
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fint2.mp4?alt=media&token=4111ad1b-e446-42ad-855e-2f81601538a2"
        }
        CircleData={CircleData}
      />
      <Banner2 bannerData={banner2Data} />
      <CarStepper
        StepData={StepData}
        carLink={
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carnival%2FC1-E.mp4?alt=media&token=d43ed4f0-ff4b-4a7a-8d36-c69bdb75f528"
        }
        stepTimings={[1.0, 3.0, 5.0, 9.0, 11.0, 13.0]}
      />
      <ShowInterest someValue="Carnival" />
      <Grid container>
        <Grid item xs={12}>
          <Box
            component="img" loading="eager" decoding="async"
            src="https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carnival%2Fcarnivalfull.jpg?alt=media&token=d5dc8bc6-69b9-4aeb-86d0-d2ba5720dc36"
            alt="Image"
            sx={{
              width: "100%",
              height: "auto",
            }}
          />
        </Grid>
      </Grid>
      {CarouselData.map((item) => (
        <CarouselPart data={item} />
      ))}
      <VideoFrame videoUrl="https://youtu.be/5zlAtoY8gko?si=g_nNLrs0oEb5rrD7" />
      {CarouselData1.map((item) => (
        <CarouselPart data={item} />
      ))}
      <MeetTheFamily TrimData={TrimData} data={CircleData} />
      <DetailedInfo DetailInfo={DetailInfo} />
      <Faq FaqData={FaqData} />
      <Wsp />
    </>
  );
}
