import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

export default function ShowInterest(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [dealer, setDealer] = useState("");
  const [consentChecked, setConsentChecked] = useState(false);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!firstName) newErrors.firstName = "First Name is required.";
    if (!lastName) newErrors.lastName = "Last Name is required.";
    if (!mobile) newErrors.mobile = "Mobile Number is required.";
    if (!email) newErrors.email = "Email ID is required.";
    if (!pincode) newErrors.pincode = "Pincode is required.";
    if (!city) newErrors.city = "Please select your city.";
    if (!dealer) newErrors.dealer = "Please select your dealer.";
    if (!consentChecked) newErrors.consentChecked = "Consent is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      props.kepInTouch(
        firstName,
        lastName,
        mobile,
        email,
        pincode,
        city,
        dealer,
        consentChecked,
        props.someValue
      );

      setFirstName("");
      setLastName("");
      setMobile("");
      setEmail("");
      setPincode("");
      setCity("");
      setDealer("");
      setConsentChecked(false);
      setErrors({});
    }
  };

  return (
    <Grid container py={"2%"} px={"6%"} backgroundColor="rgba(0, 0, 0, 0.1)">
      <Grid item xs={12}>
        <Typography
          fontFamily="KIAbodyLatinbasic_Bold"
          sx={{ fontSize: "clamp(24px, 4vw, 30px)" }}
        >
          Show Interest
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <Grid container spacing={3} p={3}>
            {/* Input Fields */}
            {[
              {
                label: "First Name",
                type: "text",
                value: firstName,
                setValue: setFirstName,
                name: "firstName",
              },
              {
                label: "Last Name",
                type: "text",
                value: lastName,
                setValue: setLastName,
                name: "lastName",
              },
              {
                label: "Mobile Number",
                type: "tel",
                value: mobile,
                setValue: setMobile,
                name: "mobile",
              },
              {
                label: "Email",
                type: "email",
                value: email,
                setValue: setEmail,
                name: "email",
                placeholder: "name@example.com",
              },
              {
                label: "Pincode",
                type: "number",
                value: pincode,
                setValue: setPincode,
                name: "pincode",
              },
            ].map(({ label, type, value, setValue, name, placeholder }) => (
              <Grid item xs={12} sm={6} key={name}>
                <TextField
                  variant="standard"
                  label={label}
                  type={type}
                  fullWidth
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  error={!!errors[name]}
                  helperText={errors[name] || ""}
                  placeholder={placeholder}
                  InputProps={{
                    style: { paddingTop: "7px", paddingBottom: "7px" },
                  }}
                  sx={{ "&:hover fieldset": { border: "1px solid black" } }}
                />
              </Grid>
            ))}

            {/* City Dropdown */}
            <Grid item xs={12} sm={6}>
              <TextField
                variant="standard"
                label="Select a City"
                select
                fullWidth
                value={city}
                onChange={(e) => setCity(e.target.value)}
                error={!!errors.city}
                helperText={errors.city || ""}
              >
                {[
                  "Angul",
                  "Berhampur",
                  "Bhubaneswar",
                  "Cuttack",
                  "Jajpur",
                  "Jharsuguda",
                  "Sambalpur",
                ].map((city) => (
                  <MenuItem key={city.toLowerCase()} value={city.toLowerCase()}>
                    {city}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/* Dealer Dropdown */}
            <Grid item xs={12} sm={6}>
              <TextField
                variant="standard"
                label="Select DealerShip"
                select
                fullWidth
                value={dealer}
                onChange={(e) => setDealer(e.target.value)}
                error={!!errors.dealer}
                helperText={errors.dealer || ""}
              >
                {[
                  "Angul",
                  "Berhampur",
                  "Bhubaneswar",
                  "Cuttack",
                  "Jajpur",
                  "Jharsuguda",
                  "Sambalpur",
                ].map((dealer) => (
                  <MenuItem
                    key={dealer.toLowerCase()}
                    value={dealer.toLowerCase()}
                  >
                    {dealer}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/* Consent Checkbox */}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={consentChecked}
                    onChange={(e) => setConsentChecked(e.target.checked)}
                  />
                }
                label="I authorize Central Kia to save my contact details.*"
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: "12px",
                    fontFamily: "KIAbodyLatinbasic_Regular",
                    color: errors.consentChecked ? "red" : "#555",
                  },
                }}
              />
              {errors.consentChecked && (
                <Typography variant="caption" color="error">
                  {errors.consentChecked}
                </Typography>
              )}
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12} sm={3}>
              <Button
                fullWidth
                variant="contained"
                sx={{
                  color: "#FFF",
                  backgroundColor: "#91999d",
                  px: { xs: 3, md: 6 },
                  py: { xs: 1, md: 1.5 },
                  textTransform: "capitalize",
                  fontSize: { xs: "14px", md: "16px" },
                  borderRadius: 0,
                  fontWeight: 600,
                  "&:hover": { backgroundColor: "#91999d" },
                }}
                type="submit"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
