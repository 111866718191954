import React, { useRef, useEffect } from "react";
import {
  Grid,
  Typography,
  IconButton,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { styled } from "@mui/system";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { ArrowLeft, ArrowRight } from "iconsax-react";
import { Circle } from "@mui/icons-material";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFF",
    color: "rgba(0, 0, 0, 0.5)",
    fontSize: "12px",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    padding: "8px 16px",
    borderRadius: 0,
  },
}));

export default function TrimCarousel({ trim, CircleData }) {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current && prevRef.current && nextRef.current) {
      swiperRef.current.params.navigation.prevEl = prevRef.current;
      swiperRef.current.params.navigation.nextEl = nextRef.current;
      swiperRef.current.navigation.init();
      swiperRef.current.navigation.update();
    }
  }, [trim]);

  return (
    <>
      {trim?.length === 1 ? (
        trim.map((trim, index) => (
          <Grid container justifyContent={"center"}>
            <Grid item xs={12} sm={6} md={4}>
              <Grid backgroundColor={"#05141f"} p={"28px 50px"}>
                <Typography
                  fontFamily="KIAbodyLatinbasic_Bold"
                  sx={{
                    fontSize: "clamp(24px, 4vw, 33px)",
                    color: "#FFF",
                  }}
                >
                  {trim.title}
                </Typography>
                <Typography
                  fontFamily="KIAbodyLatinbasic_Regular"
                  sx={{
                    fontSize: "clamp(17px, 4vw, 23px)",
                    color: "#FFF",
                  }}
                >
                  ₹ {trim.cost}{" "}
                  <span style={{ color: "#FFFFFF99", fontSize: "13px" }}>
                    Starting MSRP*
                  </span>
                </Typography>
              </Grid>
              <Grid backgroundColor={"#FFF"} p={"28px 50px"}>
                <Grid container display={"flex"}>
                  {CircleData.map((item) => (
                    <Grid item xs={1.5}>
                      <CustomTooltip title={item.title}>
                        <Circle
                          sx={{
                            color: item.color,
                            border: "0.5px solid lightgray",
                            borderRadius: "50%",
                          }}
                        />
                      </CustomTooltip>
                    </Grid>
                  ))}
                </Grid>
                <Grid py={5}>
                  <Typography
                    fontFamily="KIAbodyLatinbasic_Bold"
                    variant="subtitle2"
                  >
                    Features
                  </Typography>
                  <Typography
                    fontFamily="KIAbodyLatinbasic_Regular"
                    variant="subtitle2"
                    py={0.8}
                  >
                    {trim.feature1}
                  </Typography>
                  <Typography
                    fontFamily="KIAbodyLatinbasic_Regular"
                    variant="subtitle2"
                    py={0.8}
                  >
                    {trim.feature2}
                  </Typography>
                  <Typography
                    fontFamily="KIAbodyLatinbasic_Regular"
                    variant="subtitle2"
                    py={0.8}
                  >
                    {trim.feature3}
                  </Typography>
                  <Typography
                    fontFamily="KIAbodyLatinbasic_Regular"
                    variant="subtitle2"
                    py={0.8}
                  >
                    {trim.feature4}
                  </Typography>
                  <Typography
                    fontFamily="KIAbodyLatinbasic_Regular"
                    variant="subtitle2"
                    py={0.8}
                  >
                    {trim.feature5}
                  </Typography>
                  <Typography
                    fontFamily="KIAbodyLatinbasic_Regular"
                    variant="subtitle2"
                    py={0.8}
                  >
                    {trim.feature6}
                  </Typography>
                  <Typography
                    fontFamily="KIAbodyLatinbasic_Regular"
                    variant="subtitle2"
                    py={0.8}
                  >
                    {trim.feature7}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))
      ) : (
        <Grid
          container
          justifyContent="center"
          sx={{ position: "relative", margin: "auto" }}
        >
          {/* Left Arrow */}
          <IconButton
            ref={prevRef}
            sx={{
              position: "absolute",
              left: "0px", // Adjust spacing from swiper
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 10,
              color: "#FFF",
              padding: { xs: "10px", sm: "20px", md: "30px" },
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 1)",
              },
            }}
          >
            <ArrowLeft size={25} />
          </IconButton>

          <Swiper
            modules={[Navigation]}
            spaceBetween={20}
            onSwiper={(swiper) => (swiperRef.current = swiper)}
            navigation={{
              prevEl: prevRef.current,
              nextEl: nextRef.current,
            }}
            watchOverflow={true}
            breakpoints={{
              320: { slidesPerView: 1 },
              480: { slidesPerView: 2 },
              768: { slidesPerView: 3 },
            }}
          >
            {trim?.length > 0 ? (
              trim.map((trim, index) => (
                <SwiperSlide key={index} style={{ padding: "16px 12px" }}>
                  <Grid>
                    <Grid backgroundColor={"#05141f"} p={"28px 50px"}>
                      <Typography
                        fontFamily="KIAbodyLatinbasic_Bold"
                        sx={{
                          fontSize: "clamp(24px, 4vw, 33px)",
                          color: "#FFF",
                        }}
                      >
                        {trim.title}
                      </Typography>
                      <Typography
                        fontFamily="KIAbodyLatinbasic_Regular"
                        sx={{
                          fontSize: "clamp(17px, 4vw, 23px)",
                          color: "#FFF",
                        }}
                      >
                        ₹ {trim.cost}{" "}
                        <span style={{ color: "#FFFFFF99", fontSize: "13px" }}>
                          Starting MSRP*
                        </span>
                      </Typography>
                    </Grid>
                    <Grid backgroundColor={"#FFF"} p={"28px 50px"}>
                      <Grid container display={"flex"}>
                        {CircleData.map((item) => (
                          <Grid item xs={1.5}>
                            <CustomTooltip title={item.title}>
                              <Circle
                                sx={{
                                  color: item.color,
                                  border: "0.5px solid lightgray",
                                  borderRadius: "50%",
                                }}
                              />
                            </CustomTooltip>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid py={5}>
                        <Typography
                          fontFamily="KIAbodyLatinbasic_Bold"
                          variant="subtitle2"
                        >
                          Features
                        </Typography>
                        <Typography
                          fontFamily="KIAbodyLatinbasic_Regular"
                          variant="subtitle2"
                          py={0.8}
                        >
                          {trim.feature1}
                        </Typography>
                        <Typography
                          fontFamily="KIAbodyLatinbasic_Regular"
                          variant="subtitle2"
                          py={0.8}
                        >
                          {trim.feature2}
                        </Typography>
                        <Typography
                          fontFamily="KIAbodyLatinbasic_Regular"
                          variant="subtitle2"
                          py={0.8}
                        >
                          {trim.feature3}
                        </Typography>
                        <Typography
                          fontFamily="KIAbodyLatinbasic_Regular"
                          variant="subtitle2"
                          py={0.8}
                        >
                          {trim.feature4}
                        </Typography>
                        <Typography
                          fontFamily="KIAbodyLatinbasic_Regular"
                          variant="subtitle2"
                          py={0.8}
                        >
                          {trim.feature5}
                        </Typography>
                        <Typography
                          fontFamily="KIAbodyLatinbasic_Regular"
                          variant="subtitle2"
                          py={0.8}
                        >
                          {trim.feature6}
                        </Typography>
                        <Typography
                          fontFamily="KIAbodyLatinbasic_Regular"
                          variant="subtitle2"
                          py={0.8}
                        >
                          {trim.feature7}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </SwiperSlide>
              ))
            ) : (
              <Typography sx={{ color: "#FFF" }}>No trims available</Typography>
            )}
          </Swiper>

          {/* Right Arrow */}
          <IconButton
            ref={nextRef}
            sx={{
              position: "absolute",
              right: "0px", // Adjust spacing from swiper
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 10,
              color: "#FFF",
              padding: { xs: "10px", sm: "20px", md: "30px" },
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 1)",
              },
            }}
          >
            <ArrowRight size={25} />
          </IconButton>
        </Grid>
      )}
    </>
  );
}
