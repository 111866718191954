const BannerData = {
  videoUrl:
    "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FNew%20Kia%20Syros%20SUV.mp4?alt=media&token=a0de9b7a-a956-4828-957e-c4b2dcc64e35",
  title: "Kia Syros",
  subtitle: "Evolved by the future.",
  price: "₹8 99 900*",
  features: [
    {
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FADAS_1.png?alt=media&token=6e292831-22ac-478a-8326-71669d08cb71",
      text: "ADAS Level 2 with 16 Autonomous Safety Features",
    },
    {
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FSunroof_1.png?alt=media&token=5f73875d-7059-4a26-8f96-3ce75dcbee25",
      text: "Dual Pane Panoramic Sunroof",
    },
    {
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FOTA_1.png?alt=media&token=ebdf3761-048a-4a53-99b9-a4e56a687539",
      text: "OTA Software Updates with Kia Remote Diagnostics",
    },
  ],
};

const IntroData = {
  heading: "Introducing, The Kia Syros.",
  subheading: "DRIVEN BY THE FUTURE.",
  description:
    "The Kia Syros isn’t merely an evolution; it’s a revolutionary leap in SUV design. With every curve, every angle, and every feature, it embodies the bold new Kia design language. More than just an SUV, it’s a movement, shaped to be as forward-thinking as the future it’s destined for. From its sleek and groundbreaking exterior to the cutting-edge technology inside, the Syros is crafted to inspire and lead, redefining what a new generation of SUVs should be.",
  image:
    "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2Fsection_2_PC.jpg?alt=media&token=df3bcd35-3690-436f-a12b-1251e0d3b1ad",
};

const CarouselData = [
  {
    sub: "EXTERIOR",
    title: "A new era of SUV design.",
    data: "The Kia Syros is a bold reinvention in the SUV world. With its strong, upright stance and high squared shoulders, it commands attention at first glance. The chiselled bonnet and futuristic LED headlamps with DRLs not only highlight its power but also embody the cutting-edge design language Kia embraces for the future. This isn’t just a vehicle; it’s a statement of progress, where every detail is purposefully designed to lead the way in innovation and style.",
    carData: [
      {
        title: "Ice Cube MFR LED Headlamps with Starmap LED DRLs",
        description:
          "Precision lighting that blends a futuristic vision with striking style.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FEx-1.mp4?alt=media&token=ff4c7de0-ba28-4032-a395-05b1f3d487f8",
      },
      {
        title: "Starmap LED Tail Lamps",
        description:
          "A signature look that lights the way, leaving an indelible impression.​",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FEx-2.jpg?alt=media&token=f3912cd2-a87e-4bd8-960c-27137d9d211a",
      },
      {
        title: "Streamline Door Handles",
        description:
          "A first-in-segment touch of the future, making access as smooth as the design.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FEx-3.jpg?alt=media&token=8d8d9b13-1cc2-4433-9e9d-f44e1aa252c2",
      },
      {
        title: "R17 - 43.66cm (17”) Crystal Cut Alloy Wheels",
        description:
          "Turning heads and tackling terrain, with wheels that stand tall.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FEx-4.jpg?alt=media&token=92c60ba8-c465-4bbc-b4c6-3ba1a921fcaf",
      },
      {
        title: "Kia Signature Digital Tiger Face",
        description:
          "A bold statement of otherworldly presence that mesmerizes.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FEx-5.jpg?alt=media&token=82f9607f-1567-4072-8c01-8154fc7fd326",
      },
      {
        title: "Shark Finn Antenna and Integrated Spoiler",
        description:
          "Designed for seamless flow, combining style with enhanced connectivity.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FEx-7.jpg?alt=media&token=1ac5d48f-6c1f-4006-893a-9f2d2409acad",
      },
      {
        title: "Robust Front & Rear Skid Plate with Silver Metallic Finish",
        description:
          "Rugged protection that perfectly complements the refined, future-ready design.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FEx-8.jpg?alt=media&token=05061cfa-3474-4a22-8df4-c4a3f494f616",
      },
    ],
  },
  {
    sub: "INTERIOR",
    title: "Every detail speaks the language of tomorrow.",
    data: "The Kia Syros is a groundbreaking evolution, crafted to redefine the driving experience. With a low-floor design paired with high ground clearance, it offers a dynamic blend of comfort and performance. Inside, the Trinity Panoramic Display, advanced materials, and futuristic textures set the tone for innovation that makes this new species of SUV stand out from the crowd.",
    carData: [
      {
        title: "Dual Pane Panoramic Sunroof",
        description:
          "Expands your world with a view that bridges the present and the possibilities ahead.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FInt-1.jpg?alt=media&token=6a7f99c9-43c6-4bb6-9df1-43d8cda767c0",
      },
      {
        title: "Electric Parking Brake with Auto Hold",
        description:
          "It holds the brake in place, giving you complete control at every stop.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FInt-2.jpg?alt=media&token=02d11bbe-c821-4769-9300-17eab75cc7e8",
      },
      {
        title: "76.2cm (30”) Trinity Panoramic Display panel",
        description:
          "Navigate, control, and entertain seamlessly as you embrace the future of driving.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FInt-3.mp4?alt=media&token=d3520979-35c7-426c-a291-1e985e210d33",
      },
      {
        title: "Harman Kardon Premium 8 Speakers Sound System",
        description:
          "Experience sounds like never before, with unparalleled precision and depth.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FInt-4.jpg?alt=media&token=cb7bf214-2ff0-4b62-860a-1508a320ae82",
      },
      {
        title:
          "Second Row Seat Recline & Slide with Seat Ventilation Cushion (Seat only)",
        description:
          "First-in-segment innovation, designed to deliver unmatched comfort for every journey.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FInt-6.png?alt=media&token=0eb20c19-109e-49fc-a2a5-9acc016243c7",
      },
      {
        title: "64 Colour Ambient Mood Lighting",
        description:
          "Illuminates the cabin with a mood that evolves with you, from night to day.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FInt-5.mp4?alt=media&token=185ba2a2-36a7-473a-b564-434a1ebcbd39",
      },
    ],
  },
  {
    sub: "PERFORMANCE",
    title: "A powerful new Species.",
    data: "Engineered for those who seek a driving experience that moves beyond the ordinary, the Syros combines cutting-edge performance and advanced technology, creating an evolution in motion that redefines what it means to drive into the future.",
    carData: [
      {
        title: "High-performance D1.5 CRDi VGT Diesel Engine",
        description:
          "With 116 PS of power and 250 Nm of torque, the 1.5 CRDi VGT Diesel Engine is built for both strength and efficiency. A smooth ride with maximum performance on every terrain.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FPer-1.png?alt=media&token=d3a5863f-4fad-4097-8da1-5453cb15f043",
      },
      {
        title: "Dynamic Smartstream G1.0 T-GDi Petrol Engine",
        description:
          "The 1.0 T-GDi Petrol Engine, with 120 PS of power and 172 Nm of torque, combines responsive acceleration with exceptional fuel efficiency—perfectly tuned for the future of driving.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FPer-2.jpg?alt=media&token=25326439-5417-4163-9d25-8ebc116af3db",
      },
    ],
  },
  {
    sub: "ENGINEERED FOR PEACE OF MIND",
    title: "The Evolution of Confidence.",
    data: "Empowering every journey with a seamless blend of advanced technology and thoughtful design, the Syros is built for those who seek to move ahead with assurance. With 20 robust standard safety pack integrated into the new species of SUV, it’s ready to keep you and your loved ones secure at every turn.",
    carData: [
      {
        title: "6 Airbags as Standard",
        description: "Guarding you with six points of defense, always.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FEng-3.png?alt=media&token=3ab408bb-bf04-41fb-a261-91bdb850284f",
      },
      {
        title: "Front and Rear Parking Sensors",
        description:
          "4 parking sensors each at the front and rear that guides you into the tightest spots.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FEng-6.jpg?alt=media&token=5b47fe19-3b4e-475b-ac09-fa4ec15c1db3",
      },
      {
        title: "ESS (Emergency Stop Signal)",
        description:
          "Ensures you're visible to others when you need to stop fast.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FEng-7.jpg?alt=media&token=5172a74e-02c9-4b8d-855d-cc735d1fec18",
      },
      {
        title: "Highline Tyre Pressure Monitor",
        description: "Monitors your tyre pressure for a smoother, safer drive.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FEng-4.jpg?alt=media&token=412fefbe-5045-4edf-9814-5e754bc4a0be",
      },
      {
        title: "Front and Rear All Seat 3-Point Seatbelts with Reminder",
        description:
          "Reminds you to buckle up, keeping everyone safe on every trip.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FEng-5.jpg?alt=media&token=c0465674-8af4-4573-8e39-18997fbdb6fb",
      },
    ],
  },
  {
    sub: "INTUITIVE ADAS LEVEL 2",
    title: "Next-gen intelligence, evolving your drive.",
    data: "With 16 autonomous features at your fingertips, ADAS Level 2 brings the future to your present. Experience enhanced control, unmatched precision, and a drive that adapts to you, effortlessly navigating through every road with intelligent safety features that ensure peace of mind at every turn.",
    carData: [
      {
        title: "Smart Cruise Control with Stop & Go (SSC w/ S&G)",
        description:
          "Automatically maintains distance and manages stop-and-go traffic.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FAds-1.mp4?alt=media&token=b92cec3a-5f8d-43ca-9c64-c156915c0d9c",
      },
      {
        title: "Front Collision Warning (FCW)",
        description: "Notifies you when there’s a risk of a front collision.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FAds-3.mp4?alt=media&token=7c068bee-8b1d-41d3-b2e5-51922088eafa",
      },
      {
        title: "Front Collision-Avoidance Assist - Car (FCA – Car)",
        description:
          "Helps prevent accidents by alerting you of cars on your path.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FAds-4.jpg?alt=media&token=6efc69ff-766f-4bd5-b072-9600962f0dc4",
      },
      {
        title: "Front Collision - Avoidance Assist - Cycle (FCA - Cyclist)",
        description: "Detects cyclists ahead and helps prevent collisions.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FAds-4.mp4?alt=media&token=d74d2c2d-ba8c-49df-87f2-4ac310f93a21",
      },
      {
        title:
          "Front Collision - Avoidance Assist - Junction Turning (FCA - JT)",
        description:
          "Helps prevent accidents at intersections by assisting with braking.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FAds-5.mp4?alt=media&token=06ad45b2-cda6-456e-95fb-895c663386d1",
      },
      {
        title: "Lane Keep Assist (LKA)",
        description:
          "Keeps you within your lane by providing steering assistance.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FAds-6.mp4?alt=media&token=28be9248-c27b-4b77-938a-de13f500f694",
      },
      {
        title: "High Beam Assist (HBA)",
        description:
          "Automatically switches between high and low beams for better visibility.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FAds-7.mp4?alt=media&token=ead26c9b-5897-4ba0-8ceb-3c38dab111fc",
      },
      {
        title: "360 Degree Camera",
        description: "Offers a full view around the car to assist in parking.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FAds-14.mp4?alt=media&token=2ad64a0d-9574-4c65-91e0-87a7a0835319",
      },
      {
        title: "Parking Collision Avoidance Assist – Reverse (PCA-R)",
        description:
          "Brakes automatically when reversing if an obstacle is detected.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FAds-8.mp4?alt=media&token=91c3889d-750a-46c2-a634-c2d88d80bcf2",
      },
    ],
  },
  {
    sub: "FUTURISTIC CONNECTED CAR TECHNOLOGY",
    title: "Kia Connect 2.0",
    data: "Embrace the future with Kia Connect 2.0. Over 80+ Kia Connect features bring you closer to your Syros, giving you seamless control and an evolved driving experience.",
    carData: [
      {
        title: "Over-the-air (OTA) Software Updates",
        description: "Stay updated with seamless over-the-air updates.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FFut-1.mp4?alt=media&token=d46a59e7-9492-4c48-93c4-68f1d0a5c337",
      },
      {
        title: "76.2cm (30”) Trinity Panoramic Display panel",
        description:
          "Best-in-segment display that brings navigation and connectivity to your fingertips.​",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FInt-3.mp4?alt=media&token=d3520979-35c7-426c-a291-1e985e210d33",
      },
      {
        title: "Kia Remote Diagnostics",
        description:
          "Remotely diagnose and resolve issues with Kia's service team.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FFut-3.png?alt=media&token=a214147b-d892-4ec1-9b33-e65d7ff66173",
      },
      {
        title: "Wake-up Command",
        description:
          "With one command, your car comes to life, ready to take you places.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FFut-4.png?alt=media&token=dfd7c16c-d3a9-45c7-b5cb-7a58f1397e6a",
      },
      {
        title: "Multilingual VR Commands",
        description:
          "Navigate seamlessly with voice commands in multiple languages.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FFut-5.png?alt=media&token=65ea4a11-173d-4567-9e86-2aa7ea5ef108",
      },
      {
        title: "Call Center Assisted Navigation",
        description:
          "Connect instantly with our call center for on-the-spot navigation assistance.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FFut-7.png?alt=media&token=cbaa366a-c09c-4dee-b78e-a56a9d035a6d",
      },
    ],
  },
  {
    sub: "EVOLVE YOUR DRIVE",
    title: "Accessories designed for a new species of SUV.",
    data: "The future of SUVs is here, and now you can make it truly yours. Customize your Syros with exclusive accessories that bring innovation and sophistication together.",
    carData: [
      {
        title: "The Kia Syros Robust-A Package",
        description:
          "Built for those who crave adventure, this package enhances durability and resilience, ensuring your Syros is ready for every challenge ahead.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2Fwe_2.mp4?alt=media&token=5b734666-da3c-49bd-8177-0708cdab0430",
      },
      {
        title: "The Kia Syros Robust-B Package",
        description:
          "Designed to reinforce the commanding presence of your Syros, this package adds toughness and refinement, making every drive feel unstoppable.​",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2Fwe_3.mp4?alt=media&token=12aeb292-494d-4de4-a6c8-acdbcca26bc6",
      },
      {
        title: "The Kia Syros Luxe Package",
        description:
          "Premium design meets innovation in this package, enhancing comfort, sophistication, and cutting-edge convenience for a truly evolved driving experience.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2Fwe_1.mp4?alt=media&token=5fd4480b-4756-4479-86cb-cd8b89b535a6",
      },
    ],
  },
];

const TrimData = [
  {
    title: "HTK",
    cost: "8,99,900",
    feature1: "Standard 20 Hi Safety Package",
    feature2: "Streamline Door Handles",
    feature3:
      "30.48 cm (12”) Full Segment LCD Cluster with Advanced 10.5 cm (4.2”) Color TFT MID",
    feature4: "Manual Air conditioner and Rear AC Vents",
    feature5: "Kia Signature Digital Tiger Face",
    feature6: "Rear Door Sunshade Curtains",
    feature7: "Double D-Cut Steering Wheel",
  },
  {
    title: "HTK(0)",
    cost: "9,99,900",
    feature1: "Standard 20 Hi Safety Package",
    feature2: "Streamline Door Handles",
    feature3: "31.24 cm (12.3”) HD Touchscreen with 4 Speakers and 2 Tweeters",
    feature4: "Sunroof",
    feature5: "Bold Bridge Type Roof Rails",
    feature6: "Driver Seat Height Adjust",
    feature7: "R16 - 40.56 cm (16”) Crystal Cut Alloy Wheels (D1.5)",
  },
  {
    title: "HTK+",
    cost: "11,49,900",
    feature1: "Streamline Door Handles",
    feature2: "31.24 cm (12.3”) HD Touchscreen with 4 Speakers and 2 Tweeters",
    feature3: "Dual Pane Panoramic Sunroof",
    feature4: "R16 - 40.56 cm (16”) Crystal Cut Alloy Wheels",
    feature5: "60:40 Split Rear Seats with Sliding and Reclining Function",
    feature6: "Electric Parking Brake with Auto Hold (G1.0T 7DCT only)",
    feature7: "Smart Key with Push Button Start (G1.0T 7DCT only)",
  },
  {
    title: "HTK",
    cost: "13,29,900",
    feature1: "31.24 cm (12.3”) HD Touchscreen with 4 Speakers and 2 Tweeters",
    feature2: "Ice Cube MFR LED Headlamps",
    feature3: "Starmap LED DRL with integrated turn signal",
    feature4: "Starmap LED Combination Tail Lamps",
    feature5: "60:40 Split Rear Seats with Sliding and Reclining Function",
    feature6: "Front Ventilated Seats",
    feature7: "Leatherette seats",
  },
  {
    title: "HTX+",
    cost: "15,99,900",
    feature1: "Dual Pane Panoramic Sunroof",
    feature2:
      "60:40 Split Rear Seats with Sliding, Reclining and Ventilation (Seat only) Function",
    feature3: "Trinity Panoramic Display",
    feature4: "Kia Connect 2.0",
    feature5: "OTA Software Updates with Kia Remote Diagnostics",
    feature6: "Harman Kardon Premium 8 Speakers Sound System",
    feature7:
      "Note: ADAS Pack available at additional Rs 80K only on the top end trim",
  },
];

const CircleData = [
  {
    title: "Frost Blue",
    color: "#456274",
    imgSrc: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2F09-d.png?alt=media&token=4b62b2b7-febe-4fb8-b01b-7845ad26c5f7",
  },
  {
    title: "Sparkling Silver",
    color: "#e0e0df",
    imgSrc: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2F08-d.png?alt=media&token=feec65c2-2b81-4cb7-874a-cea9d4a96f54",
  },
  {
    title: "Gravity Grey",
    color: "#444444",
    imgSrc: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2F08-d%20(1).png?alt=media&token=be47b6d6-1ccb-4e8d-a4ca-f43d32951131",
  },
  {
    title: "Imperial Blue",
    color: "#2b2c71",
    imgSrc: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2F08-d%20(2).png?alt=media&token=46aabe96-369c-4b1a-9292-cc199b2dfe4d",
  },
  {
    title: "Intense Red",
    color: "#b2001e",
    imgSrc: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2F08-d%20(3).png?alt=media&token=8bf8d756-6e4e-4011-9c78-904fa61c2e06"
  },
  {
    title: "Pewter Olive",
    color: "#3e493b",
    imgSrc: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2F08-d%20(4).png?alt=media&token=7acc7ec8-46e3-4d20-98d1-8de9359d0292"
  },
  {
    title: "Glacier White Pearl",
    color: "#f3f9fb",
    imgSrc: 'https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2F08-d%20(5).png?alt=media&token=6813954a-d58a-4a3b-9353-72ffed93f133'
  },
  {
    title: "Aurora Black Pearl",
    color: "#151617",
    imgSrc: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2F08-d%20(6).png?alt=media&token=5841467a-164f-448d-a6ce-9d49d487a48b"
  },
];

const DetailInfo = [
  {
    bgColor: "#FFF",
    color: "#000",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2Fbook.png?alt=media&token=60d7f5b9-c30b-4c19-a810-1ecf835516c8",
    title: "Bring home the Kia Syros",
    desc: "Experience an evolution by the future",
    link: "",
  },
  {
    bgColor: "#05141f",
    color: "#FFF",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2Fdownload.png?alt=media&token=53d6a48a-54b2-4093-b4f8-ebcaa1873a4c",
    title: "Download Brochure",
    desc: "Know it all about the new species of SUV",
    link: "https://www.kia.com/content/dam/kia2/in/en/our-vehicles/syros/Kia_Syros_2025_Desktop.pdf",
  },
  {
    bgColor: "#FFF",
    color: "#000",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2Fcall.png?alt=media&token=dbd67b81-8ded-4bf9-bc21-f649fe1dec89",
    title: "Call us for more information",
    desc: "We'll be happy to answer all your queries. 1800 108 5000",
    link: "",
  },
];

const FaqData = [
  {
    question: "What is the price of the Kia Syros?",
    answer:
      "Kia Syros starts at price of ₹ 8 99 900*(Ex-showroom price all India)",
  },
  {
    question: "How many features does the Kia Syros offer in its ADAS system?",
    answer:
      "The Kia Syros offers Robust 20 standard safety pack and intuitive features in its ADAS system. With ADAS Level 2.0 technology, you can experience cutting-edge safety and convenience features that bring the future to your present.",
  },
  {
    question: "How many airbags are there in the Kia Syros?",
    answer:
      "The Kia Syros comes equipped with 6 airbags as standard, ensuring enhanced safety for both the driver and passengers.",
  },
  {
    question: "Does the Kia Syros come with a sunroof?",
    answer:
      "The Kia Syros SUV comes with Dual Pane Panoramic Sunroof with a view that bridges the present and the possibilities ahead.",
  },
  {
    question: "What are Kia Syros engine options?",
    answer:
      "There are two options for Kia Syros- D1.5 CRDi VGT Diesel and G1.0 T-GDi Petrol Engine",
  },
];

const StepData = [
  {
    title: "Starmap LED DRLs",
    desc: "Command attention, no matter where the road takes you.",
  },
  {
    title: "Ice Cube MFR LED Headlamps",
    desc: "Illuminate the road ahead with cutting-edge precision, bringing tomorrow's tech to your drive. ",
  },
  {
    title: "Streamline Door Handles",
    desc: "A sleek silhouette that enhances the car’s aerodynamic profile and takes design beyond the ordinary.",
  },
  {
    title: "Starmap LED Tail Lamps",
    desc: "Shine brighter, stand bolder—these lights guide the way to a future filled with innovation and style. ",
  },
  {
    title: "Dual Pane Panoramic Sunroof",
    desc: "Open up to the future and enjoy unobstructed views that stretch as far as your imagination. ",
  },
  {
    title: "76.2cm (30`) Trinity Panoramic Display panel",
    desc: "12.3” HD Touchscreen + 30.48 cm (12”) LCD Cluster + 5” Fully Automatic Air Conditioner Control – The future of in-car control and comfort. ",
  },
];

export {
  CarouselData,
  TrimData,
  CircleData,
  DetailInfo,
  FaqData,
  StepData,
  BannerData,
  IntroData
};
