import { Grid, Typography } from "@mui/material";
import React from "react";
import Caro from "./Caro";

export default function CarBanner({ data }) {
  return (
    <>
      <Grid container py={"2%"} px={"7%"}>
        <Grid item xs={12}>
          <Typography
            variant="inherit"
            color="gray"
            letterSpacing={2}
            fontFamily="KIAbodyLatinbasic_Regular"
            sx={{
              fontSize: "clamp(14px, 2vw, 16px)",
            }}
          >
            {data.subheading}
          </Typography>
          <Typography
            variant="h4"
            fontFamily="KIAbodyLatinbasic_Bold"
            sx={{
              fontSize: "clamp(24px, 4vw, 27px)",
              lineHeight: { xs: 1.5, sm: 2, md: 2.5},
            }}
          >
            {data.title}
          </Typography>
          <Typography
            fontFamily="KIAbodyLatinbasic_Regular"
            textAlign="justify"
            sx={{
              fontSize: "clamp(12px, 1.8vw, 15px)",
              width: { xs: "100%", sm: "86%", md: "72%" },
            }}
          >
            {data.data}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Caro trim={data.carData} />
      </Grid>
    </>
  );
}
