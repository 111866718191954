import React, { useEffect, useRef, useState } from "react";
import "./AnimationSection.css";
const AnimationSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const contentRef = useRef(null);
  const ani1 =
    "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV9%2Fani1.jpg?alt=media&token=798cc0e4-d34f-4c9c-901f-86c76e31d026";
  const ani2 =
    "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV9%2Fani2.jpg?alt=media&token=6deda349-9f04-4509-8116-1b5e3a603539";
  const ani3 =
    "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV9%2Fani3.jpg?alt=media&token=f8488257-4ec8-4810-8b63-9ed8af24f372";
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    if (contentRef.current) {
      observer.observe(contentRef.current);
    }

    return () => {
      if (contentRef.current) {
        observer.unobserve(contentRef.current);
      }
    };
  }, []);

  return (
    <>
      <div className="animation-container">
        <div className="image-section">
          <img src={ani1} alt="Left content" style={{ width: "100%" }} />
        </div>
        <div
          ref={contentRef}
          className={`center-content ${isVisible ? "visible" : ""}`}>
          <h2>A Vision of Comfort and Craftsmanship</h2>
          <h4>Trinity Panoramic Display</h4>
          <p>
            Immerse yourself in a cutting-edge driving experience with the
            Trinity Panoramic Display. Seamlessly blending three high-definition
            screens, it offers a unified view of navigation, controls, and
            entertainment at your fingertips.
          </p>
        </div>
        <div className="image-section">
          <img src={ani2} alt="Right content" style={{ width: "100%" }} />
        </div>
      </div>

      <div className="animation-container">
        <div
          ref={contentRef}
          className={`center-content ${isVisible ? "visible" : ""}`}>
          <h2>2nd Row Captain Seats with Relaxation & Massage Function</h2>
          <p>
            The 2nd row captain seats with relaxation and massage functions
            transform your ride into a sanctuary. Experience customizable
            comfort and luxury, all the way.
          </p>
        </div>

        <div className="image-section">
          <img src={ani3} alt="Left content" style={{ width: "100%" }} />
        </div>

        <div
          ref={contentRef}
          className={`center-content ${isVisible ? "visible" : ""}`}>
          <h2>Head-up Display – Windshield Projection</h2>
          <p>
            Stay focused on the road with the Head-up Display, projecting key
            driving information directly onto your windshield. Enjoy a safer,
            distraction-free experience with navigation, speed, and alerts
            always in your line of sight.
          </p>
        </div>
      </div>
    </>
  );
};

export default AnimationSection;
