import { Box, Button, Grid, Skeleton } from "@mui/material";
import React, { useState } from "react";

export default function VideoFrame({ videoUrl }) {
  const [isLoaded, setIsLoaded] = useState(false);
  // Function to safely extract YouTube video ID
  const getYoutubeThumbnail = (url) => {
    if (!url) return ""; // Handle undefined URL

    let videoId = "";
    const regex =
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/|youtube\.com\/shorts\/)([^"&?\/\s]{11})/;
    const match = url.match(regex);
    if (match) videoId = match[1];

    return videoId
      ? `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`
      : "";
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box position="relative" width="100%" height="120vh">
          {/* Display Thumbnail as Cover */}
          {!isLoaded && (
            <Skeleton
              animation="wave"
              variant="rectangular"
              width="100%"
              height="100%"
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                objectFit: "cover",
              }}
            />
          )}
          <Box
            component="img"
            loading="eager"
            decoding="async"
            onLoad={() => setIsLoaded(true)}
            src={getYoutubeThumbnail(videoUrl)}
            alt="Video Thumbnail"
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />

          {/* Semi-Transparent Overlay */}
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.2)",
            }}
          />

          {/* Watch Now Button */}
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#1E1D1D",
                color: "#FFF",
                px: 3,
                py: 1.5,
                textTransform: "capitalize",
                fontSize: { xs: "18px", md: "24px" },
                borderRadius: 4,
                fontWeight: 600,
                "&:hover": {
                  color: "#1E1D1D",
                  backgroundColor: "#FFF",
                },
              }}
              onClick={() => videoUrl && window.open(videoUrl, "_blank")}
            >
              Watch Now
            </Button>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
