import React from 'react';
import {
  Typography,
  Button,
  Container,
  CardMedia,
  CardContent,
  Box,
  Grid,
  Card,
} from '@mui/material';
import DriveEtaIcon from '@mui/icons-material/DriveEta'; 
import DescriptionIcon from '@mui/icons-material/Description'; 
import Showinterest from '../../../show interest/Container/showinterestCon';
import Slidingev6 from '../../../Cars/EV6/Component/Slidingev6';
import Contentcarrousel from '../../../Cars/EV6/Component/Contentcarrousel';
import Animationeffect from '../../EV6/Component/Animationeffect'
import Ev6brochure from '../../../Brochure/EV6/Kia-EV6_Desktop.pdf'

import BookIcon from '@mui/icons-material/LibraryBooks';
import CallIcon from '@mui/icons-material/Call';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Cardscarrousel from '../../EV6/Component/cardcarrousel'
import Faqs from '../../../Cars/EV6/Component/faqs'
import Colorev from './color'
import Wsp from '../../../contact/Component/Floatingwsp';

const styles = {
  cardContainer: {
    display: "flex",
    justifyContent: "space-around",
    padding: "20px",
    flexWrap: "wrap", 
  },
  card: {
    maxWidth: 500,
    marginBottom: "20px",
  },
  cardMedia: {
    height: 200,
    width: "100%",
    objectFit: "cover",
  },
  title: {
    fontWeight: "bold",
  },
  description: {
    color: "#555",
  },
};


const Ev6 = () => {

  const cards = [
    {
      image: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV6%2Fevcard.jpg?alt=media&token=15c32ff8-77d5-42b9-8bba-4c7a97bad368",
      title: "All Wheel Drive",
      description: "The dual motor AWD system delivers superior acceleration and all-weather grip. Switches between AWD and RWD within 0.4seconds depending on the requirement",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV6%2Fevcard2.jpg?alt=media&token=f33f15db-c456-4f04-aa2c-4b9cec7abca4", 
      title: "Drive Modes",
      description: "Choose from Normal, Sport or Eco drive modes. Each mode provides a significant performance change to suit the requirement along with a unique driver interface",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV6%2Fevcard3.jpg?alt=media&token=17717f4a-66d2-466d-9f10-0a34a8fee715", 
      title: "Aerodynamics",
      description: "The super sleek design with active air flaps and aerodynamic enhancements make EV6 move quickly and efficiently",
    },
  ];

  const cards1 = [
    {
      image: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV6%2Fevcard4.jpg?alt=media&token=22521d29-65db-48d7-b582-ed4f40ad7ff0",
      title: "Vehicle-to-load (V2L)",
      description: "Fully charged, EV6 offers 3.6 kW of power to run multiple devices inside and outside by attaching Vehicle-to-load Connector",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV6%2Fevcard5.png?alt=media&token=efb1776b-81d2-4e50-a815-aa139977e201", 
      title: "708 Km Driving Range",
      description: "With a 77.4 kWh battery pack, the Kia EV6 lets you explore more of what the world has to offer",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV6%2Fevcard6.jpg?alt=media&token=50ab5711-9468-440e-b5c4-e84c91893088", 
      title: "Electric Global Modular Platform (E-GMP)",
      description: "The New E-GMP platform introduces a flat floor, facilitating comfort and utility along with greater power, driving range and control",
    },
  ];

  const cards2 = [
    {
      image: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV6%2Fevcard7.png?alt=media&token=41c929b6-28c7-4f6c-8d0a-0131a8137a10",
      title: "EV Remote Climate Control",
      description: "Climate stays delightful without taking anything away from the enviroment. Just switch on the climate control using the Kia Connect application. You can even schedule Remote Cooling based on your convenience",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV6%2Fevcard8.png?alt=media&token=7c731967-3b25-4951-a36f-da3d40bca835", 
      title: "EV Remote Charging Control",
      description: "Once the connector is plugged in, you can switch on/off the charging as per you need. Schedule the charge without any discomfort",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV6%2Fevcard9.png?alt=media&token=873d4831-dc34-480a-83bf-abd58fab3378", 
      title: "Surround View Monitoring",
      description: "Stay in control of the world outside. View a video around your EV6 according to the camera angles and monitor your surroundings",
    },
  ];

  const cards3 = [
    {
      image: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV6%2Fevcard10.jpg?alt=media&token=bdac3d63-9b01-43f8-9bbc-104834da4bb1",
      title: '31.24 cm (12.3") Curved Driver Display Screen',
      description: "The interface lays out all essential pieces of information for clarity about range, forecast battery charge time, Vehicle-to-load status and much more ",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV6%2Fevcard11.jpg?alt=media&token=ef745004-bb1b-49dc-a52a-49d77ade3610", 
      title: "The Meridian Premium Sound System with 14 Speakers",
      description: "The Meridian Premium Sound System is the ultimate luxury for ears, 14 speakers create an exceptional experience to elevate the senses",
    },
  ];

  const cards4 = [
    {
      image: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV6%2Fevcard12.jpg?alt=media&token=81b8dfa2-8e75-4e2b-827e-d78b3ce8cd0e",
      title: 'Smart Power Tailgate',
      description: "The Power Tailgate can be set to open automatically to the desired height",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV6%2Fevcard13.jpg?alt=media&token=adf45343-3280-4a28-8d33-f5b7dd42219e", 
      title: "Shift by Wire",
      description: "Change gear position simply by turning the dial of Shift by wire left or right",
    },
  ];

  const cards5 = [
    {
      image: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV6%2Fevcard14.jpg?alt=media&token=94d29fcf-fd74-40c7-98ba-d71056bfad34",
      title: "Rear-cross Traffic Avoidance Assist (RCTA)",
      description: "This warns in case a vehicle or pedestrian is coming while reversing. It automatically assissts with emergency breaking if collision is sensed",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV6%2Fevcard15.jpg?alt=media&token=59a9695e-111b-4e4d-b059-3ee2c8a5078d", 
      title: "Safe Exit Assist (SEA)",
      description: "Child lock is automatically activated along with a warning sound when an occupant attempts to open their door while a vehicle is approaching from the back",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV6%2Fevcard16.jpg?alt=media&token=b99ec683-bd3b-4817-aa81-aab2616ed42a", 
      title: "Blind Spot Collision-Avoidance Assist (BCA)",
      description: "When you signal a lane change, you are warned if a risk of collision is detected with a vehicle beside you or approaching from the rear",
    },
  ];


  return (
    <Box sx={{overflowX:'hidden'}}>
        <Box sx={{ position: 'relative', height: '100vh', overflow: 'hidden' }}>
            <video
                playsInline
                autoPlay
                loop
                muted
                preload="auto"
                style={{
                    position: 'absolute',
                    width: '100vw',
                    height: '100%',
                    objectFit: 'cover',
                }}
            >
            <source src={"https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV6%2Fev6.mp4?alt=media&token=b91370fc-5761-45ec-b483-94f49429317c"} type="video/mp4" />
            </video>

        </Box>
        <Wsp/>

        <Box sx={{backgroundColor:'#FFFFFF'}}>
          
            {/* Secord card section */}
            <Box sx={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} sm={12} md={6}>
                        <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', boxShadow: 3 ,gap:'15px'}}>
                            <DriveEtaIcon sx={{ fontSize: '40px', color: '#000'  }} />
                            <Typography variant="h6" sx={{ marginTop: '10px',fontWeight:'bold',fontFamily:"KIAbodyLatinbasic_Bold" }}>
                            Register your interest
                            </Typography>
                            <Typography variant="body1" sx={{color:'#848484',fontSize:'13px',fontWeight:700,fontFamily:"KIAbodyLatinbasic_Bold"}}>Fill in the required details by clicking here, We will contact you as soon as possible.</Typography>
                            <Button
                                variant="contained"
                                sx={{
                                  marginTop: '10px',
                                  backgroundColor: "#E50063",
                                  color: 'white',
                                  borderRadius: '8px',
                                  fontFamily:"KIAbodyLatinbasic_Bold",
                                  fontWeight:800,
                                  height: '50px',
                                  '&:hover':{
                                    backgroundColor: "#E50063",
                                  }
                                }}
                                onClick={() => {
                                  const element = document.getElementById("showinterest");
                                  if (element) {
                                    element.scrollIntoView({ behavior: "smooth" });
                                  }
                                }}
                              >
                                Click Here
                            </Button>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', boxShadow: 3,gap:'15px' }}>
                            <DescriptionIcon sx={{ fontSize: '40px', color: '#000' }} />
                                <Typography variant="h6" sx={{ marginTop: '10px',fontWeight:'bold',fontFamily:"KIAbodyLatinbasic_Bold", fontWeight:800 }}>
                                Download Brochure
                                </Typography>
                                <Typography variant='body1' sx={{color:'#848484',fontSize:'13px',fontWeight:700,fontFamily:"KIAbodyLatinbasic_Regular"}}>Click here to download the Brochure and price list of all new EV6 on your device</Typography>
                                <Button variant="contained" sx={{ marginTop: '10px',backgroundColor:"#E50063",color:'white',borderRadius:'8px',height:'50px',fontFamily:"KIAbodyLatinbasic_Bold",
                                  '&:hover': {
                                    backgroundColor: '#E50063',
                                  }
                                  }}
                                onClick={() => {
                                  const link = document.createElement('a');
                                  link.href = Ev6brochure; 
                                  link.download = 'Kia-EV6_brochure.pdf';
                                  document.body.appendChild(link);
                                  link.click();
                                  document.body.removeChild(link);
                                }}
                                >
                                Click To Download
                                </Button>
                        </Card>
                    </Grid>
                </Grid>
            </Box>

            {/* Sliding ev6 */}
            <Slidingev6/>

            {/* color */}
            <Colorev/>

              {/* Content carrousels */}
              <Contentcarrousel/>

              {/* show interest */}
              <Box id="showinterest">
                <Showinterest/>
              </Box>

              {/* Animation effect */}
              <Animationeffect/>

              {/* 1.Content and full sized image */}
              <Box>
                <Box 
                  sx={{
                    backgroundColor: '#1d1d1d', 
                    color: 'white',
                    textAlign: 'center',
                    py: 6, 
                    px: 3, 
                  }}
                >
                  <Container maxWidth="md">
                    <Typography variant="h3" component="h1" sx={{fontWeight:'bold',fontFamily:"KIAbodyLatinbasic_Bold"}} gutterBottom>
                      Electrifying performance
                    </Typography>
                    <Typography variant="body1" sx={{ lineHeight: 1.6,fontFamily:"KIAbodyLatinbasic_Regular" }}>
                      The Electric is in the engineering but the EV6 stands for so much more.<br />
                      It emotes the excitement that comes from its performance.<br />
                      The powerful all-electric motor’s 605 Nm torque gives instantaneous acceleration,<br />
                      while a low centre of gravity offers sporty handling, making the EV6 an absolute pleasure to drive.
                    </Typography>
                  </Container>
                </Box>

                <Box sx={{ width: '100vw', height: {md:'70vh',sm:'60vh',xs:'40vh'}, overflow: 'hidden' }}>
                <Grid
                  container
                  sx={{
                    width: '100%',
                    height: {md:'70vh',sm:'60vh',xs:'40vh'},
                    backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV6%2Fev1.jpg?alt=media&token=96d93456-fc23-4236-a9a2-25b363844b1b)`,
                    backgroundSize: 'cover', 
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      color: '#fff',
                      textShadow: '1px 1px 4px rgba(0, 0, 0, 0.7)',
                    }}
                  >

                  </Grid>
                </Grid>
              </Box>
              </Box>

              {/* 3 card section */}
              <Grid container spacing={4} justifyContent="center" alignItems="center"sx={{ marginLeft:{md:'50px',sm:'20px',xs:'7px'},marginTop:'10px' }}width='90%'>
                  {cards.map((card, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <Card style={styles.card}>
                        <CardMedia
                          component="img" loading="eager" decoding="async"
                          style={styles.cardMedia}
                          image={card.image}
                          alt={card.title}
                        />
                        <CardContent>
                          <Typography variant="h6" sx={{color:'#666666',fontFamily:'KIAbodyLatinbasic_Bold',marginTop:'15px',fontSize:{md:'22px',xs:'18px'}}} style={styles.title}>
                            {card.title}
                          </Typography>
                          <Typography style={styles.description} sx={{color:'#666666',fontFamily:'KIAbodyLatinbasic_Regular',marginTop:'15px',fontSize:{md:'18px',xs:'16px'}}}>
                            {card.description}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
              </Grid>

                {/* 2.Full image  and 3 cards*/}
              <Box>
              <Box sx={{ width: '100vw', height: {md:'100vh',xs:'60vh'}, overflow: 'hidden' }}>
                <Grid
                  container
                  sx={{
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV6%2Fevfullimage.jpg?alt=media&token=3af958a6-2e9f-42ef-b31e-cbe0912358c1)`,
                    backgroundSize: '100% 100%', 
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      color: '#fff',
                      textShadow: '1px 1px 4px rgba(0, 0, 0, 0.7)',
                    }}
                  >

                  </Grid>
                </Grid>
              </Box>
              </Box>

              {/* 2nd part : 3 cards */}
              <Grid container spacing={4} justifyContent="center" alignItems="center"sx={{ marginLeft:{md:'50px',sm:'20px',xs:'7px'},marginTop:'10px' }}width='90%'>
                  {cards1.map((card, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <Card style={styles.card}>
                        <CardMedia
                          component="img" loading="eager" decoding="async"
                          style={styles.cardMedia}
                          image={card.image}
                          alt={card.title}
                        />
                        <CardContent>
                          <Typography variant="h6" sx={{color:'#666666',fontFamily:'KIAbodyLatinbasic_Bold',marginTop:'15px',fontSize:{md:'22px',xs:'18px'}}} style={styles.title}>
                            {card.title}
                          </Typography>
                          <Typography style={styles.description} sx={{color:'#666666',fontFamily:'KIAbodyLatinbasic_Regular',marginTop:'15px',fontSize:{md:'18px',xs:'16px'}}}>
                            {card.description}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
              </Grid>

              {/* 3.Content and full sized image */}
              <Box>
                <Box 
                  sx={{
                    backgroundColor: '#1d1d1d', 
                    color: 'white',
                    textAlign: 'center',
                    py: 6, 
                    px: 3, 
                  }}
                >
                  <Container maxWidth="md">
                    <Typography variant="h3" component="h1" sx={{fontWeight:'bold',fontFamily:"KIAbodyLatinbasic_Bold"}} gutterBottom>
                      Connected to inspire
                    </Typography>
                    <Typography variant="body1" sx={{ lineHeight: 1.6,fontFamily:"KIAbodyLatinbasic_Regular" }}>
                    Kia Connect with more than 60 inspirational features keeps EV6 connected with you wherever you are.<br />
                    Inside, it makes driving a lot more convenient with access<br />
                    to the full range of communication, information, and entertainment at any time. <br />
                    </Typography>
                  </Container>
                </Box>

                <Box sx={{ width: '100vw', height: {md:'100vh',xs:'60vh'}, overflow: 'hidden' }}>
                <Grid
                  container
                  sx={{
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV6%2Fevfullimage2.jpg?alt=media&token=79eba487-6dca-4871-abfa-c106c49345fb)`,
                    backgroundSize: '100% 100%', 
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      color: '#fff',
                      textShadow: '1px 1px 4px rgba(0, 0, 0, 0.7)',
                    }}
                  >

                  </Grid>
                </Grid>
              </Box>
              </Box>

              {/*3rd sec:  3 card section */}
              <Grid container spacing={4} justifyContent="center" alignItems="center"sx={{ marginLeft:{md:'50px',sm:'20px',xs:'7px'},marginTop:'10px' }}width='90%'>
                  {cards2.map((card, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <Card style={styles.card}>
                        <CardMedia
                          component="img" loading="eager" decoding="async"
                          style={styles.cardMedia}
                          image={card.image}
                          alt={card.title}
                        />
                        <CardContent>
                          <Typography variant="h6" sx={{color:'#666666',fontFamily:'KIAbodyLatinbasic_Bold',marginTop:'15px',fontSize:{md:'22px',xs:'18px'}}} style={styles.title}>
                            {card.title}
                          </Typography>
                          <Typography style={styles.description} sx={{color:'#666666',fontFamily:'KIAbodyLatinbasic_Regular',marginTop:'15px',fontSize:{md:'18px',xs:'16px'}}}>
                            {card.description}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>

              {/* 4.Content and full sized image */}
              <Box>
                <Box 
                  sx={{
                    backgroundColor: '#1d1d1d', 
                    color: 'white',
                    textAlign: 'center',
                    py: 6, 
                    px: 3, 
                  }}
                >
                  <Container maxWidth="md">
                    <Typography variant="h3" component="h1" sx={{fontWeight:'bold',fontFamily:'KIAbodyLatinbasic_Bold'}} gutterBottom>
                    Step into the future
                    </Typography>
                    <Typography variant="body1" sx={{ lineHeight: 1.6,fontFamily:"KIAbodyLatinbasic_Regular" }}>
                    Stepping into the EV6 is like stepping into a future studded with infinite luxuries.<br />
                    A minimalistic yet hi-tech driver-centric cockpit offers the most immersive experience through design,<br />
                    intuitive technologies, and sustainable elements, to make your journeys even more mesmerizing <br />
                    </Typography>
                  </Container>
                </Box>

                <Box sx={{ width: '100vw', height: {md:'100vh',xs:'60vh'}, overflow: 'hidden' }}>
                <Grid
                  container
                  sx={{
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV6%2Fevfullimage3.jpg?alt=media&token=e3af4126-7109-44c8-b8c6-4718ff3a254f)`,
                    backgroundSize: '100% 100%', 
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      color: '#fff',
                      textShadow: '1px 1px 4px rgba(0, 0, 0, 0.7)',
                    }}
                  >

                  </Grid>
                </Grid>
              </Box>
              </Box>

              {/*4th sec:  2 card section */}
              <Grid container spacing={4} justifyContent="center" alignItems="center"sx={{ marginLeft:{md:'50px',sm:'20px',xs:'7px'},marginTop:'10px' }}width='90%'>
                  {cards3.map((card, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <Card style={styles.card}>
                        <CardMedia
                          component="img" loading="eager" decoding="async"
                          style={styles.cardMedia}
                          image={card.image}
                          alt={card.title}
                        />
                        <CardContent>
                          <Typography variant="h6" sx={{color:'#666666',fontFamily:'KIAbodyLatinbasic_Bold',marginTop:'15px',fontSize:{md:'22px',xs:'18px'}}} style={styles.title}>
                            {card.title}
                          </Typography>
                          <Typography style={styles.description} sx={{color:'#666666',fontFamily:'KIAbodyLatinbasic_Regular',marginTop:'15px',fontSize:{md:'18px',xs:'16px'}}}>
                            {card.description}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
              </Grid>

              {/* 5.Content and full sized image */}
              <Box>
                <Box 
                  sx={{
                    backgroundColor: '#1d1d1d', 
                    color: 'white',
                    textAlign: 'center',
                    py: 6, 
                    px: 3, 
                  }}
                >
                  <Container maxWidth="md">
                    <Typography variant="h3" component="h1" sx={{fontWeight:'bold',fontFamily:"KIAbodyLatinbasic_Bold"}} gutterBottom>
                    Less to search, more to discover
                    </Typography>
                    <Typography variant="body1" sx={{ lineHeight: 1.6,fontFamily:"KIAbodyLatinbasic_Regular" }}>
                    Let our technology handle the details, so you can stay connected to the journey. By priortising information,<br />
                    the fully electric Kia EV6 lets you take in more of the world outside and spend less time scanning screens and dials.<br />
                    This is what makes EV6 the most convenient electric vehicle on the streets<br />
                    </Typography>
                  </Container>
                </Box>

                <Box sx={{ width: '100vw', height: {md:'100vh',xs:'60vh'}, overflow: 'hidden' }}>
                <Grid
                  container
                  sx={{
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV6%2Fevfullimage4.jpg?alt=media&token=7e44265b-bd4e-42ee-9fde-3cfa8c250a11)`,
                    backgroundSize: '100% 100%', 
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      color: '#fff',
                      textShadow: '1px 1px 4px rgba(0, 0, 0, 0.7)',
                    }}
                  >

                  </Grid>
                </Grid>
              </Box>
              </Box>

              {/*5th sec:  2 card section */}
              <Grid container spacing={4} justifyContent="center" alignItems="center"sx={{ marginLeft:{md:'50px',sm:'20px',xs:'7px'},marginTop:'10px' }}width='90%'>
                  {cards4.map((card, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <Card style={styles.card}>
                        <CardMedia
                          component="img" loading="eager" decoding="async"
                          style={styles.cardMedia}
                          image={card.image}
                          alt={card.title}
                        />
                        <CardContent>
                          <Typography variant="h6" sx={{color:'#666666',fontFamily:'KIAbodyLatinbasic_Bold',marginTop:'15px',fontSize:{md:'22px',xs:'18px'}}} style={styles.title}>
                            {card.title}
                          </Typography>
                          <Typography style={styles.description} sx={{color:'#666666',fontFamily:'KIAbodyLatinbasic_Regular',marginTop:'15px',fontSize:{md:'18px',xs:'16px'}}}>
                            {card.description}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>

              {/* 6.Content and full sized image */}
              <Box>
                <Box 
                  sx={{
                    backgroundColor: '#1d1d1d', 
                    color: 'white',
                    textAlign: 'center',
                    py: 6, 
                    px: 3, 
                  }}
                >
                  <Container maxWidth="md">
                    <Typography variant="h3" component="h1" sx={{fontWeight:'bold',fontFamily:"KIAbodyLatinbasic_Bold"}} gutterBottom>
                    Safety from every possible angle
                    </Typography>
                    <Typography variant="body1" sx={{ lineHeight: 1.6,fontFamily:"KIAbodyLatinbasic_Regular" }}>
                    The Kia EV6 is packed with more than 10 Advanced Driver Assistance System (ADAS) features like<br />
                    Forward Collision-Avoidance Assist (FCA), Lane Keep Assist (LKA), 8 airbags, and more
                    </Typography>
                  </Container>
                </Box>

                <Box sx={{ width: '100vw', height: {md:'100vh',xs:'60vh'}, overflow: 'hidden' }}>
                <Grid
                  container
                  sx={{
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV6%2Fevfullimage5.jpg?alt=media&token=441031f8-8fba-4f75-a690-82053596750e)`,
                    backgroundSize: '100% 100%', 
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      color: '#fff',
                      textShadow: '1px 1px 4px rgba(0, 0, 0, 0.7)',
                    }}
                  >

                  </Grid>
                </Grid>
              </Box>
              </Box>

              {/*6th sec:  3 card section */}
              <Grid container spacing={4} justifyContent="center" alignItems="center"sx={{ marginLeft:{md:'50px',sm:'20px',xs:'7px'},marginTop:'10px' }}width='90%'>
                  {cards5.map((card, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <Card style={styles.card}>
                        <CardMedia
                          component="img" loading="eager" decoding="async"
                          style={styles.cardMedia}
                          image={card.image}
                          alt={card.title}
                        />
                        <CardContent>
                          <Typography variant="h6" sx={{color:'#666666',fontFamily:'KIAbodyLatinbasic_Bold',marginTop:'15px',fontSize:{md:'22px',xs:'18px'}}} style={styles.title}>
                            {card.title}
                          </Typography>
                          <Typography style={styles.description} sx={{color:'#666666',fontFamily:'KIAbodyLatinbasic_Regular',marginTop:'15px',fontSize:{md:'18px',xs:'16px'}}}>
                            {card.description}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>


                  {/* cardcarrousel */}
                  <Cardscarrousel/>

                {/* Info */}
                <Box sx={{ padding: '50px 0', textAlign: 'center' }}>
                  <Typography 
                    variant="h5" 
                    color="text.secondary" 
                    sx={{ fontWeight: 'bold', mb: 5,fontFamily:'KIAbodyLatinbasic_Bold', }}
                  >
                    DETAILED INFORMATION
                  </Typography>

                  <Grid container spacing={4} justifyContent="center">
                   

                    {/* Download Brochure Card */}
                    <Grid item xs={12} md={4}>
                      <Box
                        sx={{
                          bgcolor: '#0b1d28',
                          color: '#fff',
                          padding: '20px',
                          borderRadius: '4px',
                        }}
                      >
                        <PictureAsPdfIcon sx={{ fontSize: 60, color: 'white', mb: 2 }} />
                        <Typography variant="h6" fontWeight="bold" sx={{fontFamily:'KIAbodyLatinbasic_Bold'}}>
                          Download Brochure
                        </Typography>
                        <Typography color="white" sx={{ mb: 2,fontFamily:'KIAbodyLatinbasic_Bold' }}>
                        Get to know all the facts and figures you need to know about the fully electric vehicle Kia EV6. A modern yet iconic car.
                        </Typography>
                        <Button 
                          variant="contained" 
                          color="secondary" 
                          onClick={() => {
                            const link = document.createElement('a');
                            link.href = Ev6brochure; 
                            link.download = 'Kia-EV6_brochure.pdf';
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                          }}
                          sx={{fontFamily:"KIAbodyLatinbasic_Bold"}}
                        >
                          Download
                        </Button>
                      </Box>
                    </Grid>

                    {/* Call Us Card */}
                    <Grid item xs={12} md={4}>
                      <Box>
                        <CallIcon sx={{ fontSize: 60, color: 'black', mb: 2 }} />
                        <Typography variant="h6" fontWeight="bold" fontFamily="KIAbodyLatinbasic_Bold">
                          Call us for more information
                        </Typography>
                        <Typography color="text.secondary" sx={{ mb: 1,fontFamily:'KIAbodyLatinbasic_Regular' }}>
                          We’ll be happy to answer all your queries.
                        </Typography>
                        <Typography variant="subtitle1" fontWeight="bold" fontFamily="KIAbodyLatinbasic_Bold">
                        09237386213
                        </Typography>
                        <Button 
                          variant="outlined" 
                          color="inherit" 
                          onClick={() => {
                            const isMobile = /Mobi|Android/i.test(navigator.userAgent);
                            if (isMobile) {
                              window.location.href = 'tel:09237386213';
                            } else {
                              alert('Please call 09237386213 for more information.');
                            }
                          }}
                        >
                          Call Us
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>


              {/* Faqs */}
              <Faqs/>

        </Box>
    </Box>

  );
};

export default Ev6;
