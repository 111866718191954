import React, { useEffect, useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { Grid, Typography, Box, Skeleton } from "@mui/material";
import { ArrowLeft, ArrowRight } from "iconsax-react";

export default function CarouselPart({ data }) {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const totalSlides = data.carData.length;
  const swiperRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (progress >= 100) {
        setProgress(0);

        if (activeSlideIndex + 1 >= totalSlides) {
          setActiveSlideIndex(0);
          swiperRef.current?.slideTo(0);
        } else {
          setActiveSlideIndex((prev) => prev + 1);
          swiperRef.current?.slideNext();
        }
      } else {
        setProgress((prev) => prev + 10);
      }
    }, 500);

    return () => clearInterval(interval);
  }, [progress, activeSlideIndex, totalSlides]);

  const [isLoaded, setIsLoaded] = useState(false);
  const mediaSrc = data.carData[activeSlideIndex]?.media || "";
  const isVideo = mediaSrc.split("?")[0].toLowerCase().endsWith(".mp4");

  return (
    <>
      {/* Header Section */}
      <Grid container pt={"2.5%"} px={"4.5%"}>
        <Grid item xs={12}>
          <Typography
            variant="inherit"
            color="gray"
            letterSpacing={2}
            fontFamily="KIAbodyLatinbasic_Bold"
            sx={{ fontSize: "clamp(14px, 2vw, 16px)" }}
          >
            {data.sub}
          </Typography>
          <Typography
            variant="h4"
            fontFamily="KIAbodyLatinbasic_Bold"
            lineHeight={1.6}
            sx={{ fontSize: "clamp(24px, 4vw, 30px)" }}
          >
            {data.title}
          </Typography>
          <Typography
            fontFamily="KIAbodyLatinbasic_Regular"
            textAlign="justify"
            lineHeight={1.8}
            sx={{
              fontSize: "clamp(12px, 1.8vw, 16px)",
              width: { xs: "100%", sm: "86%", md: "76%" },
            }}
          >
            {data.data}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        style={{
          display: "flex",
          gap: 10,
          alignItems: "center",
          justifyContent: "end",
          paddingRight: "5%",
        }}
      >
        <Typography
          variant="caption"
          style={{
            borderRadius: "20px",
            backgroundColor: "#F2F2F2",
            fontFamily: "KIAbodyLatinbasic_Regular",
            padding: "3px 15px",
          }}
        >
          {activeSlideIndex + 1}/{totalSlides}
        </Typography>
        <Box sx={{}} onClick={() => swiperRef.current?.slidePrev()}>
          <ArrowLeft
            size="25"
            style={{
              borderRadius: "50%",
              border: "1px solid #000",
              padding: 3,
            }}
          />
        </Box>
        <Box sx={{}} onClick={() => swiperRef.current?.slideNext()}>
          <ArrowRight
            size="25"
            style={{
              borderRadius: "50%",
              border: "1px solid #000",
              padding: 3,
            }}
          />
        </Box>
      </Grid>

      {/* Carousel Section */}
      <Grid py={3} sx={{ position: "relative", width: "90%", margin: "auto" }}>
        {/* Swiper Component */}
        <Swiper
          breakpoints={{
            0: { slidesPerView: 1 }, // xs (mobile)
            600: { slidesPerView: 2 }, // sm
            960: { slidesPerView: 3 }, // md and above
          }}
          spaceBetween={30}
          loop={true}
          autoplay={false}
          navigation={false} // Disable default navigation
          modules={[Navigation]}
          onSwiper={(swiper) => (swiperRef.current = swiper)} // Set Swiper instance to ref
          onSlideChange={(swiper) => {
            setActiveSlideIndex(swiper.realIndex);
            setProgress(0);
          }}
        >
          {data.carData.map((slide, index) => (
            <SwiperSlide key={index}>
              <Grid
                sx={{
                  position: "relative",
                  paddingLeft: "20px",
                  minHeight: "100px",
                }}
              >
                {/* Progress Gradient Border */}
                <Box
                  sx={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "3px",
                    height: "100%",
                    background:
                      index === activeSlideIndex
                        ? `linear-gradient(to bottom, black ${progress}%, lightgray ${progress}%)`
                        : "lightgray",
                    transition: "background 0.5s ease-in-out",
                  }}
                />

                {/* Slide Content */}
                <Typography
                  fontFamily="KIAbodyLatinbasic_Bold"
                  sx={{
                    fontSize: "clamp(12px, 1.8vw, 16px)",
                    color: index === activeSlideIndex ? "black" : "gray",
                  }}
                >
                  {slide.title}
                </Typography>
                <Typography
                  variant="body2"
                  fontFamily="KIAbodyLatinbasic_Regular"
                  sx={{
                    paddingTop: 1,
                    lineHeight: 1.8,
                    color: index === activeSlideIndex ? "black" : "gray",
                  }}
                >
                  {slide.description}
                </Typography>
              </Grid>
            </SwiperSlide>
          ))}
        </Swiper>
      </Grid>

      {/* Image Section */}
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              width: "100%",
              height: { xs: "50vh", sm: "100vh" },
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              position: "relative",
            }}
          >
            {!isLoaded && (
              <Skeleton
                animation="wave"
                variant="rectangular"
                width="100%"
                height="100%"
                sx={{ position: "absolute", top: 0, left: 0, zIndex: 2 }}
              />
            )}

            {isVideo ? (
              <video
                src={mediaSrc}
                autoPlay
                loop
                muted
                playsInline
                onCanPlayThrough={() => setIsLoaded(true)}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  opacity: isLoaded ? 1 : 0,
                  transition: "opacity 1s ease-in-out",
                }}
              />
            ) : (
              <Box
                component="img"
                loading="lazy"
                decoding="async"
                src={mediaSrc}
                alt="Intro Media"
                onLoad={() => setIsLoaded(true)}
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  opacity: isLoaded ? 1 : 0,
                  transition: "opacity 1s ease-in-out",
                }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
