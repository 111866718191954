import React, { useState } from "react";
import {
  Typography,
  Button,
  Box,
  Grid,
  Paper,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import Footer from "../../Footer/Component/Footer";
import Wsp from '../../contact/Component/Floatingwsp';

const Enquiry = (props) => {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [model, setModel] = useState("");
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation
    const errors = {};
    if (!name) errors.name = "Name is required.";
    if (!mobile) errors.mobile = "Mobile Number is required.";
    if (!email) errors.email = "Email ID is required.";
    if (!city) errors.city = "City is required.";
    if (!model) errors.model = "Model is required.";

    setErrors(errors);

    props.AddEnquiryForm(name, mobile, email, city, model);
    setName("");
    setMobile("");
    setEmail("");
    setCity("");
    setModel("");
  };

  return (
    <Box>
      {/* Background Section */}
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: { md: "100vh", sm: "100vh", xs: "40vh" },
          backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fseltos1.jpg?alt=media&token=32bf45bf-6c39-4a46-8a36-dbe71e5cf654)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      ></Box>

      {/* Enquiry Form */}
      <Grid container justifyContent="center" sx={{ mt: 4 }}>
        <Grid item xs={11} md={6}>
          <Paper elevation={3} sx={{ p: 4 }}>
            <Typography
              variant="h5"
              sx={{
                mb: 2,
                fontWeight: "bold",
                textAlign: "center",
                fontFamily: "KIAbodyLatinbasic_Bold",
              }}
            >
              Enquiry Form
            </Typography>
            <Box
              component="form"
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <Grid container spacing={2}>
                {/* Name */}
                <Grid item xs={12}>
                  <TextField
                    label="Name"
                    variant="outlined"
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    error={!!errors.name}
                    helperText={errors.name}
                  />
                </Grid>

                {/* Mobile Number */}
                <Grid item xs={12}>
                  <TextField
                    label="Mobile Number"
                    variant="outlined"
                    fullWidth
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    error={!!errors.mobile}
                    helperText={errors.mobile}
                  />
                </Grid>

                {/* Email ID */}
                <Grid item xs={12}>
                  <TextField
                    label="Email ID"
                    variant="outlined"
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                </Grid>

                {/* City */}
                <Grid item xs={12}>
                  <TextField
                    label="City"
                    variant="outlined"
                    fullWidth
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    error={!!errors.city}
                    helperText={errors.city}
                  />
                </Grid>

                {/* Model */}
                <Grid item xs={12}>
                  <InputLabel id="model-select-label">Model</InputLabel>
                  <Select
                    labelId="model-select-label"
                    fullWidth
                    value={model}
                    onChange={(e) => setModel(e.target.value)}
                    error={!!errors.model}
                  >
                    <MenuItem value="seltos">Seltos</MenuItem>
                    <MenuItem value="sonet">Sonet</MenuItem>
                    <MenuItem value="carens">Carens</MenuItem>
                    <MenuItem value="ev6">EV6</MenuItem>
                    <MenuItem value="carnival">Carnival</MenuItem>
                    <MenuItem value="ev9">EV9</MenuItem>
                    <MenuItem value="syros">Syros</MenuItem>
                  </Select>
                  {errors.model && (
                    <FormHelperText error>{errors.model}</FormHelperText>
                  )}
                </Grid>

                {/* Submit */}
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        backgroundColor: "#414141",
                        color: "white",
                        fontWeight: "700",
                        "&:hover": {
                          backgroundColor: "#333333",
                        },
                      }}
                      size="large"
                    >
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
                <Wsp/>
    </Box>
  );
};

export default Enquiry;
