import React, { useState } from "react";
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

export default function Faq({FaqData}) {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Grid container pt={'10%'} pb={"12%"} px={"7%"} backgroundColor={"#eff1f2"}>
      <Grid item xs={12}>
        <Typography
          variant="inherit"
          letterSpacing={2}
          fontFamily="KIAbodyLatinbasic_Bold"
          sx={{ fontSize: "clamp(24px, 4vw, 40px)" }}
        >
          Frequently Asked Questions
        </Typography>
      </Grid>
      <Grid item xs={12} mt={3}>
        {FaqData.map((faq, index) => (
          <Accordion
            key={index}
            expanded={expanded === index}
            onChange={handleChange(index)}
            sx={{
              maxWidth: { xs: "100%", sm: "100%", md: "90%" },
              boxShadow: "0px 0px 0px #000",
            }}
            disableGutters // Removes extra padding when expanded
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              sx={{
                backgroundColor: expanded === index ? "#FFF" : "#eff1f2",
                transition: "background-color 0.3s ease",
                minHeight: "48px !important",
                "&.Mui-expanded": {
                  minHeight: "48px !important",
                },
              }}
            >
              <Typography
                fontFamily="KIAbodyLatinbasic_Bold"
                sx={{ padding: "2%" }}
              >
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                marginBottom: "0 !important",
              }}
            >
              <Typography
                fontFamily="KIAbodyLatinbasic_Regular"
                fontSize={"14px"}
                sx={{ paddingBottom: "3%", paddingX: '2%' }}
              >
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </Grid>
  );
}
