import React from 'react'
import Caro from '../../../Common/Components/Caro'
import { bannerData, imagesHome } from './Data'
import Home from '../Container/homeCon'
import Cardreview from "../Container/cardsCon";
import CrissCross from './CrissCross';
import Wsp from '../../contact/Component/Floatingwsp';

export default function HOME() {
  return (
    <div>
      <Caro trim={bannerData} />
      <Home />
      <Caro trim={imagesHome} />
      <CrissCross />
      <Cardreview />
      <Wsp />
    </div>
  )
}
