import { Box, Grid, Skeleton, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function DetailedInfo({ DetailInfo }) {
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <Grid container pt={"2%"}>
      <Grid item xs={12}>
        <Typography
          variant="inherit"
          color="gray"
          letterSpacing={2}
          fontFamily="KIAbodyLatinbasic_Regular"
          sx={{
            fontSize: "clamp(14px, 2vw, 16px)",
            px: "7%",
          }}
        >
          DETAILED INFORMATION
        </Typography>
        <Grid container mt={3} justifyContent="center">
          {DetailInfo.map((item, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={index}
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
              backgroundColor={item.bgColor}
              p={8}
            >
              <Link
                to={item.link}
                style={{ textDecoration: "none", color: item.color }}
              >
                {!isLoaded && (
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    sx={{
                      width: "120px",
                      height: "auto",
                      marginBottom: "10px",
                    }}
                  />
                )}
                <Box
                  component="img"
                  loading="lazy"
                  decoding="async"
                  onLoad={() => setIsLoaded(true)}
                  src={item.imgSrc}
                  alt={item.title}
                  style={{
                    width: "120px",
                    height: "auto",
                    marginBottom: "10px",
                  }}
                />
                <Typography
                  fontFamily="KIAbodyLatinbasic_Bold"
                  variant="subtitle2"
                >
                  {item.title}
                </Typography>
                <Typography
                  fontFamily="KIAbodyLatinbasic_Regular"
                  variant="body2"
                  py={0.8}
                >
                  {item.desc}
                </Typography>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
