import React from "react";
import Wsp from "../../../contact/Component/Floatingwsp";
import Faq from "../../../../Common/Components/Faq";
import {
  banner2Data,
  BannerData,
  CarouselData,
  CarouselData1,
  CircleData,
  DetailInfo,
  FaqData,
  IntroData,
  StepData,
  TrimData,
} from "./Data";
import DetailedInfo from "../../../../Common/Components/DetailedInfo";
import VideoFrame from "../../../../Common/Components/VideoFrame";
import MeetTheFamily from "../../../../Common/Components/MeetTheFamily";
import CarouselPart from "../../../../Common/Components/CarouselPart";
import ShowInterest from "../../../../Common/Components/ShowInterest";
import { Box, Grid, Typography } from "@mui/material";
import CarStepper from "../../../../Common/Components/CarStepper";
import Banner2 from "../../../../Common/Components/Banner2";
import CarModel from "../../../../Common/Components/CarModel";
import Intro from "../../../../Common/Components/Intro";
import InterestDealer from "../../../../Common/Components/InterestDealer";
import Banner from "../../../../Common/Components/Banner";

export default function CARENS() {
  return (
    <>
      <Banner bannerData={BannerData} />
      <InterestDealer />
      <Intro data={IntroData} />
      <CarModel
        model="Carens"
        bgCar={
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fint2.mp4?alt=media&token=4111ad1b-e446-42ad-855e-2f81601538a2"
        }
        CircleData={CircleData}
      />
      <Banner2 bannerData={banner2Data} />
      <CarStepper
        StepData={StepData}
        carLink={
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2FKia_Carens_2023_Explore_desktop_delivery_231003_C_v01_MQ_1.mp4?alt=media&token=48eb6ec1-1914-48ee-9e4c-9499f5ec0f36"
        }
        stepTimings={[1.0, 2.0, 3.0, 4.0, 5.0, 6.0, 7.0]}
      />
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={11} py={1}>
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{
              fontFamily: "KIAbodyLatinbasic_Regular",
              textAlign: "justify",
              display: "block",
              fontSize: "11px",
            }}
          >
            *Disclaimer: The product shown in the visual is for representation
            purposes only. Color, feature, and actual product may vary. Please
            check with your local Kia dealer for more details.
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Box
            component="img" loading="eager" decoding="async"
            src="https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fcarensfullimage.jpg?alt=media&token=ca83e291-7a9e-4b88-9526-d343af42ee9d"
            alt="Image"
            sx={{
              width: "100%",
              height: "auto",
            }}
          />
        </Grid>
      </Grid>
      {CarouselData.map((item) => (
        <CarouselPart data={item} />
      ))}
      <ShowInterest someValue="Carens" />
      {CarouselData1.map((item) => (
        <CarouselPart data={item} />
      ))}
      <MeetTheFamily TrimData={TrimData} data={CircleData} />
      <VideoFrame videoUrl="https://youtu.be/jrazpf-7GNk?si=yIYFhexXioKGJzRD" />
      <DetailedInfo DetailInfo={DetailInfo} />
      <Faq FaqData={FaqData} />
      <Wsp />
    </>
  );
}
