const BannerData = {
  videoUrl:
    "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carnival%2Fcarnival.mp4?alt=media&token=19d25335-7a89-4873-8f63-2d744cb517ff",
  title: "The new Kia Carnival Limousine",
  subtitle: "Your own luxury liner.",
  price: "",
  features: [],
};

const banner2Data = {
  imgSrc:
    "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carnival%2FB-1-d.jpg?alt=media&token=191c351b-3256-4395-856a-866abe886e8a",
  data: [
    {
      title: "Mile-High Safety",
      description: "",
    },
    {
      title: "Dual Panoramic Curved Display",
      description: "",
    },
    {
      title: "Wide Electric Dual Sunroof",
      description: "",
    },
  ],
};

const IntroData = {
  heading: "Your own luxury liner.",
  subheading: "",
  description:
    "With a bold grille, dazzling projection headlamps, and design that turns heads, The new Carnival is here to announce your arrival in unparalleled style.The new Carnival Limousine is more than a car; it's an experience. It gives wings to finely crafted comfort and envelops you in opulence. Step in, and you'll forget you're still on the ground.",
  image:
    "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carnival%2Fcarnival1.jpg?alt=media&token=f952aba5-e6bd-4bc7-aaec-113dc25b8ea1",
};

const InterestDealerData = {
  disclaimer: "",
  register:
    "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2Fregister%20intrest.png?alt=media&token=2c5fb2a2-418b-4399-8994-8e590bd17314",
  dealer:
    "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FBrochure%20image.png?alt=media&token=5ce8b8e6-2d25-41f1-b458-69aa2bd51a10",
};

const CarouselData = [
  {
    sub: "LIMOUSINE+",
    title: "Extravagance. Reborn.",
    data: "Engineered to make every journey a first-class experience, the Kia Carnival Limousine+ sets the new standard for luxury, and innovation.​​",
    carData: [
      {
        title: "Front LED Fog Lamps",
        description:
          "Cut through the mist and navigate the path ahead with the ease.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carnival%2FLP-1.jpg?alt=media&token=43212bc9-7dce-4f63-b833-932e681f63b2",
      },
      {
        title:
          "2nd Row Powered Relaxation Seats with Ventilation, Heating and Leg Support",
        description:
          "Cruise with elegance for a journey more extravagant than the destination.​",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carnival%2FLP-2.jpg?alt=media&token=4870bab6-9fd1-4d8f-8f0a-a1fbda44af95",
      },
      {
        title: "Bose Premium Surround Sound with 12 Speakers",
        description:
          "Let every note and every symphony take flight and fill your private cabin.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carnival%2FLP-3.jpg?alt=media&token=d485cb38-fd19-48ec-bab0-e5ee36d49bfa",
      },
      {
        title: "Front Console Wireless Smartphone Charger",
        description:
          "Charge up and take off with the convenience of a wireless smartphone charger. No cables. No limits.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carnival%2FLP-4.jpg?alt=media&token=3c123101-e14c-447f-8d6c-7f7dd65d7273",
      },
      {
        title: "Wide Electric Dual Sunroof",
        description:
          "Limitless views for you, as if from an attitude of 30,000 feet in the sky.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carnival%2FLP-5.jpg?alt=media&token=ba20b86e-a701-4376-a97a-862f1cef727f",
      },
      {
        title: "Advanced Head-up Display",
        description: "A personalised panel for your Digital Cockpit",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carnival%2FLP-6.jpg?alt=media&token=3b457a5e-abf8-4967-a94b-f97f01c2fbfe",
      },
    ],
  },
  {
    sub: "LIMOUSINE",
    title: "Take off with Luxury.",
    data: "Built, and crafted to refine your journeys, every innovative and extravagant detail of the new Kia Carnival Limousine is meant to be your portal to sheer opulence.​",
    carData: [
      {
        title: "R18 - 45.72 Cm (18”) Diamond Cut Alloy",
        description:
          "Take off in style with Black and Chrome coating B-Type Radiator Grille in the Alloy Wheels of your limousine liner.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carnival%2FLu-1.jpg?alt=media&token=4a0a9146-e061-4725-9c78-b894d785f6dd",
      },
      {
        title: "Premium Leatherette VIP Seats",
        description:
          "First-class comfort at every mile for you. Relax, sit back and buckle up to soar above the crowd.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carnival%2FLu-2.jpg?alt=media&token=8baddd55-87b5-49c0-9590-703f777be446",
      },
      {
        title: "One Touch Power Sliding Doors",
        description:
          "Open up a world of convenience, an effortless onboarding for ultimate extravagance.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carnival%2FLu-3.jpg?alt=media&token=0881a6fc-81eb-47e2-be5f-67502de3d318",
      },
      {
        title:
          "Independently Controlled 3-zone Fully-automatic Temperature Control",
        description:
          "A customised climate zone for every mile of your journey.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carnival%2FLu-4.jpg?alt=media&token=b59d2fa7-f9c2-41bc-ab72-8dd404332626",
      },
      {
        title: "8 Airbags",
        description:
          "The ultimate peace of mind wrapped in the luxury of premium protection.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carnival%2FSa-1.jpg?alt=media&token=ea01c7bd-1703-4533-b099-1f1c61a81198",
      },
    ],
  },
];

const CarouselData1 = [
  {
    sub: "EXTERIOR",
    title: "Style that’s sky-high.",
    data: "Soar through the streets with the new Kia Carnival’s exceptionally elegant exterior, and make every drive feel like an ascent.",
    carData: [
      {
        title: "Black and Chrome “Tiger Nose Grille”",
        description:
          "With Black and Chrome coating Tiger Nose Grille, the Kia Carnival takes off from the crowd in many ways than one.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carnival%2Fext1.jpg?alt=media&token=bc214485-6803-43fa-98ea-b60fcf1271ce",
      },
      {
        title: "One Touch Sliding Door with Power Tail Gate",
        description:
          "An effortless, and smooth boarding experience, accessible at one touch. ​",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carnival%2Fext2.jpg?alt=media&token=f70dc3e1-3759-4c0c-b293-337e79fa6745",
      },
      {
        title: "Wide Dual Electric Sunroof",
        description:
          "Experience views from a new altitude like never before with the Dual Electric Sunroof. ",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carnival%2Fext3.jpg?alt=media&token=455e0912-bb6f-48b9-b2a8-7a1e2e52661d",
      },
      {
        title: "18” Diamond Cut Alloy Wheels",
        description:
          "Have a lift-off with striking design that cuts through the road with aerodynamic grace.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carnival%2Fext4.jpg?alt=media&token=f19dbc13-f6e9-45fd-a3e0-cd0ce70ccbc4",
      },
    ],
  },
  {
    sub: "INTERIOR",
    title: "Comfort of the Captain’s Quarters.",
    data: "Designed for premium comfort, and to make every journey a first-class experience, the new Kia Carnival sets the standard for luxury, technology, and extravagance alike.​",
    carData: [
      {
        title: "3 Zone Seating with Tri-Zone Cooling",
        description:
          "Personalized climate control for each zone ensuring a comfortable temperature for every one of your passengers.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carnival%2Fint1.jpg?alt=media&token=3afe0e79-2d5c-4d72-bd6d-102733784a47",
      },
      {
        title: "Infotainment System",
        description:
          "An immersive digital command center that elevates every journey with seamless connectivity and entertainment.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carnival%2Fint2.jpg?alt=media&token=5c681e68-4e1f-4dea-a8c2-61fd2feab1d3",
      },
      {
        title: "Wireless Charger",
        description:
          "Seamlessly stay connected on the go with an effortless charging station in your cabin.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carnival%2Fint3.jpg?alt=media&token=8ef659ee-7947-4bd4-967c-efdd9550482b",
      },
      {
        title: "Paddle Shifter",
        description:
          "Enjoy a more engaging driving experience with our Paddle Shifters. Quickly and easily shift gears for optimal performance.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carnival%2Fint4.jpg?alt=media&token=b29b033b-9b56-44c5-b2bf-8114d522ec8c",
      },
    ],
  },
  {
    sub: "SAFETY",
    title: "Mile-high Safety.",
    data: "Experience a journey safeguarded by advanced technology, with 23 autonomous ADAS features designed to keep you secure through every mile. The Kia Carnival provides smart protective systems that anticipate your needs on the road, ensuring utmost safety and peace of mind with every drive.",
    carData: [
      {
        title: "8 Airbags",
        description:
          "Safety for the full deck and the cabin alike, making every journey as secure as it is smooth.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carnival%2Fsafe1.jpg?alt=media&token=2ee9118d-1101-4329-971e-a2a27af56382",
      },
      {
        title: "Rear Cross-Traffic Collision Avoidance System",
        description:
          "Avoid collisions with approaching vehicles when backing up with our advanced Rear Cross-Traffic Collision Avoidance System.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carnival%2Fsafe2.jpg?alt=media&token=21eb5c48-16ab-49b5-ad7d-c84676f1c2bf",
      },
      {
        title: "360-Degree-Camera",
        description:
          "Get an aerial view of your surroundings so that your eyes are not only on the road when you drive but all-around even when you’re not.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carnival%2Fsafe3.jpg?alt=media&token=93b3b753-b8af-435d-a6f7-e7364bd998eb",
      },
      {
        title: "Smart Cruise Control",
        description:
          "Avoid collisions and maintain a safe following distance with our advanced Smart Cruise Control system.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carnival%2Fsafe4.jpg?alt=media&token=b9a8d2be-dddc-4629-9072-ddd24b721b7a",
      },
      {
        title: "Lane Keep Assist",
        description:
          "Stay on course with our Lane Keep Assist system. It helps prevent accidental lane departures and keeps you centered in your lane, ensuring a safer and more comfortable drive.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carnival%2Fsafe5.jpg?alt=media&token=525dc76c-f72f-4a50-9c96-f706b866aff5",
      },
    ],
  },
];

const TrimData = [
  {
    title: "Limousine Plus",
    cost: "63,91,000",
    feature1: "2nd Row Luxury Powered Relaxation with Leg Support (VIP Seats)",
    feature2: "12.3” AVNT with Kia Connect, India Maps and OTA",
    feature3: "Dual Sunroof",
    feature4: "One Touch Power Sliding Doors- with Hands Free Power Sliding Door function",
    feature5: "ADAS",
    feature6: "8 Airbags",
    feature7: "12.3” Full TFT LCD Cluster",
  },
];

const CircleData = [
  {
    title: "Glacier White Pearl",
    color: "#f3f9fb",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carnival%2Fcarn1.png?alt=media&token=5c148fd0-5971-4b12-b16d-fce5c658d8a8",
  },
  {
    title: "Fusion Black",
    color: "#070708",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carnival%2Fcarn2.png?alt=media&token=07afd1a0-caea-446b-8acc-2101db493c66",
  },
];

const DetailInfo = [
  {
    bgColor: "#FFF",
    color: "#000",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2Fbook.png?alt=media&token=60d7f5b9-c30b-4c19-a810-1ecf835516c8",
    title: "Your own luxury liner. ",
    desc: "Book New Carnival Limousine",
    link: "",
  },
  {
    bgColor: "#05141f",
    color: "#FFF",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2Fdownload.png?alt=media&token=53d6a48a-54b2-4093-b4f8-ebcaa1873a4c",
    title: "Download Brochure",
    desc: "Get all what you want to know about New Carnival Limousine",
    link: "https://www.kia.com/content/dam/kia2/in/en/our-vehicles/carnival/Kia_Carnival_Brochure_Desktop.pdf",
  },
  {
    bgColor: "#FFF",
    color: "#000",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2Fcall.png?alt=media&token=dbd67b81-8ded-4bf9-bc21-f649fe1dec89",
    title: "Call us for more information",
    desc: "We'll be happy to answer all your queries. 09237386213",
    link: "",
  },
];

const FaqData = [
  {
    question: "What colors does the 2025 Kia Carnival Limousine come in?",
    answer:
      "New 2025 Kia Carnival Limousine comes in two colors in India, Fusion Black and Glacier White Pearl.",
  },
  {
    question: "What is 2025 Kia Carnival Limousine price in India?",
    answer:
      "Kia Carnival Limousine plus starts at price of ₹ 63 90 000*(Ex-showroom price all India).",
  },
  {
    question: "How many seating options will the Kia Carnival Limousine offer?",
    answer:
      "The New Kia Carnival Limousine is a 7-Seater car, comes in (2+2+3).",
  },
  {
    question:
      "What are the transmission options available in the new Kia Carnival Limousine?",
    answer: "The Kia Carnival Limousine is available in 8AT.",
  },
  {
    question:
      "How many features does the new Carnival Limousine offer in its ADAS system?",
    answer:
      "The new Carnival Limousine comes with 33 autonomous ADAS features designed to keep you secure through every mile.",
  },

  {
    question: "Is there Sunroof in Kia Carnival Limousine?",
    answer:
      "Yes, New Kia Carnival Limousine comes with wide electric dual sunroof.",
  },
];

const StepData = [
  {
    title: "Tiger Nose Grille",
    desc: " With a sleek Black and Chrome  Tiger Nose Grille , the Kia Carnival ascends above the crowd, cruising in a class of its own.",
  },
  {
    title: "360 Degree Camera",
    desc: "Equipped with advanced sensors to keep you aware of potential hazards, ensuring you cruise by automatically adjusting to avoid any collisions.",
  },
  {
    title: " Smart Power Sliding Doors",
    desc: "  A simple touch for a smooth onboarding experience, the Smart Power Sliding Doors glide open for you to step into a first-class experience.",
  },
  {
    title: "LED Rear Combination Lamps",
    desc: " Enhance visibility and style with our advanced LED rear combination lamps, offering a sleek and modern design.",
  },
  {
    title: "Shark Fin Antenna",
    desc: "Merging cutting-edge connectivity with an aerodynamic profile, it exudes subtle elegance.",
  },
  {
    title: "Dual Panoramic Curved Display",
    desc: "Elevate your journey with a display that curves to your view, merging intuitive control with an expansive, panoramic experience.",
  },
];

export {
  CarouselData,
  CarouselData1,
  TrimData,
  CircleData,
  DetailInfo,
  FaqData,
  StepData,
  BannerData,
  IntroData,
  InterestDealerData,
  banner2Data,
};
