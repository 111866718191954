import React, { useState, useEffect } from "react";
import { Grid, Card, Typography, Avatar, Box, Button } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import Rating from "@mui/material/Rating";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";

const Testimonials = (props) => {
  const [expandedReviews, setExpandedReviews] = useState({});
  const [reviewsToShow, setReviewsToShow] = useState(
    props.client.all_reviews || []
  );

  useEffect(() => {
    props.fetchReviews();
    setReviewsToShow(props.client.all_reviews);
  }, []);

  const handleToggleReadMore = (index) => {
    setExpandedReviews((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return {
        truncated: words.slice(0, wordLimit).join(" "),
        remaining: words.slice(wordLimit).join(" "),
      };
    }
    return { truncated: text, remaining: "" };
  };

  const filteredReviews = reviewsToShow?.filter((review) => review.rating >= 4);

  return (
    <Grid container spacing={2} alignItems={"center"} px={"2%"}>
      <Grid item xs={12}>
        <Typography
          fontFamily="KIAbodyLatinbasic_Bold"
          textAlign={"center"}
          sx={{ fontSize: "clamp(1rem, 4vw, 2rem)", mt: 1 }}>
          What Our Customer Says
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid container>
          <Swiper
            modules={[Navigation, Pagination]}
            navigation
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            pagination={{ clickable: true }}
            spaceBetween={20}
            breakpoints={{
              640: { slidesPerView: 1 },
              768: { slidesPerView: 2 },
              980: { slidesPerView: 4 },
            }}
            style={{ padding: "2%" }}>
            {filteredReviews?.map((item, index) => {
              const { truncated, remaining } = truncateText(
                item.text || "",
                50
              );
              const isExpanded = expandedReviews[index];

              return (
                <SwiperSlide key={index}>
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "20px",
                      textAlign: "center",
                      height: "100%",
                      borderRadius: "20px",
                      boxShadow: "0px 0px 5px #007aff",
                    }}>
                    <GoogleIcon fontSize="large" sx={{ color: "#848484" }} />
                    <Rating
                      value={item.rating}
                      precision={0.1}
                      readOnly
                      sx={{ marginBottom: "10px" }}
                    />
                    <Typography variant="h6" sx={{ margin: "10px 0" }}>
                      {item.rating}/5
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        margin: "10px 0",
                        color: "#848484",
                        textAlign: "justify",
                      }}>
                      {isExpanded
                        ? `${truncated} ${remaining}`
                        : `${truncated}...`}
                    </Typography>
                    {remaining && (
                      <Button
                        onClick={() => handleToggleReadMore(index)}
                        sx={{
                          textTransform: "none",
                          color: "primary.main",
                          padding: 0,
                          fontSize: "12px",
                        }}>
                        {isExpanded ? "Show Less" : "Read More"}
                      </Button>
                    )}
                    <Avatar
                      src={item.profile_photo_url}
                      alt={item.author_name || "User"}
                      sx={{
                        bgcolor: "#E50063",
                        width: 40,
                        height: 40,
                        fontSize: "16px",
                        margin: "10px 0",
                      }}
                    />
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      {item.author_name || "Anonymous"}
                    </Typography>
                  </Card>
                </SwiperSlide>
              );
            })}
          </Swiper>
          {/* {filteredReviews?.length > visibleCount && (
            <Box sx={{ textAlign: "right", marginTop: "20px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setReviewsToShow(props.client.all_reviews)}
                sx={{ fontWeight: "bold" }}
              >
                Load More
              </Button>
            </Box>
          )} */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Testimonials;
