import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import { Typography, Box } from "@mui/material";

const CustomCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [fixedImage, setFixedImage] = useState(
    "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV9%2Fblueev9.png?alt=media&token=42765ef0-d1ff-4957-9ef4-bdb11d88cbd3"
  );

  const carImages = [
    {
      src: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV9%2Fblueev9.png?alt=media&token=42765ef0-d1ff-4957-9ef4-bdb11d88cbd3",
      alt: "ev1",
      color: "#2A8DF2",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV9%2Fblackev9.png?alt=media&token=fcc29daf-ada3-4391-b886-a0939fe7bc1a",
      alt: "ev2",
      color: "#0E0D12",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV9%2Fsilverev9.png?alt=media&token=6b91d1ec-b048-437e-b362-5879a7a8af71",
      alt: "ev3",
      color: "#D9DDDB",
    },
    {
      src: "ehttps://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV9%2Fblackishev9.png?alt=media&token=e619535d-eb98-4c96-8b8d-f8bd9486616ev1",
      alt: "ev4",
      color: "#757A81",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV9%2Fgreyec9.png?alt=media&token=534e4d50-a9a2-4023-a1df-30191471d3c5",
      alt: "ev5",
      color: "#82858C",
    },
  ];

  const handleSelect = (index) => {
    setActiveIndex(index);
    setFixedImage(carImages[index].src);
  };

  return (
    <div style={{ position: "relative", margin: 0, padding: 0 }}>
      <div style={{ textAlign: "center", padding: "10px" }}>
        <Box sx={{ paddingLeft: { xs: 6, sm: 13, md: 23 }, textAlign: "left" }}>
          <Typography
            variant="h6"
            sx={{
              color: "#9F9C9C",
              fontSize: { xs: "16px", sm: "22px" },
              marginTop: "16px",
              fontFamily: "KIAbodyLatinbasic_Bold",
              fontWeight: 800,
              paddingBottom: 1,
            }}>
            DESIGN
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: "22px", sm: "48px" },
              fontFamily: "KIAbodyLatinbasic_Bold",
              fontWeight: 800,
            }}>
            EV9
          </Typography>
        </Box>
        <img
          className="d-block w-100"
          src={carImages[activeIndex].src}
          alt={carImages[activeIndex].alt}
          style={{ maxWidth: "90%", height: "auto", paddingBottom: "10px" }}
        />
      </div>

      {/* Custom Circle Indicators */}
      <div
        style={{
          position: "absolute",
          bottom: "20px",
          left: "50%",
          transform: "translateX(-50%)",
          display: "flex",
          gap: "12px",
        }}>
        {carImages.map((car, index) => (
          <div
            key={index}
            onClick={() => handleSelect(index)}
            style={{
              width: "23px",
              height: "22px",
              borderRadius: "50%",
              backgroundColor: activeIndex === index ? car.color : car.color,
              cursor: "pointer",
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default CustomCarousel;
