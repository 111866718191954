import React, { useState } from 'react';
import { Box, Grid, Typography, IconButton } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

const images = [
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV6%2Fimage1.jpg?alt=media&token=fa4a1e34-3557-4245-a0cf-e3cab1408f36',
    descriptions: [
      { title: '', subtitle: '' },
     
    ],
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV6%2Fimage2.jpg?alt=media&token=6c254ac5-a5a1-4eea-9b44-866bd396fa15',
    descriptions: [
      { title: '', subtitle: '' },
    ],
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV6%2Fimage3.png?alt=media&token=0b6ef01a-71fc-448a-a020-b6560e90393d',
    descriptions: [
      { title: '', subtitle: '' },
    ],
  },

];

const ImageCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <Box sx={{ position: 'relative', width: '100%', height: { xs: '200px', sm:'400px',md: '700px' }, textAlign: 'center', overflowX: 'hidden' }}>
      <Grid container sx={{ height: '100%' }}>
        <Grid
          item
          xs={12}
          sx={{
            backgroundImage: `url(${images[currentIndex].src})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              bottom: '10%',
              left: '54%',
              transform: 'translateX(-50%)',
              color: '#fff',
              textShadow: '1px 1px 4px rgba(0, 0, 0, 0.8)',
              width: '80%',
            }}
          >
            <Grid container spacing={2} justifyContent="center">
              {images[currentIndex].descriptions.map((desc, index) => (
                <Grid item xs={12} sm={4} key={index}>
                  <Typography
                    variant="h6"
                    align="center"
                    sx={{
                      fontSize: { xs: '14px', sm: '18px', md: '20px' },
                      fontWeight: 'bolder',
                      fontFamily:"KIAbodyLatinbasic_Bold"
                    }}
                  >
                    {desc.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    align="center"
                    sx={{
                      fontSize: { xs: '12px', sm: '14px', md: '16px' },
                      fontFamily:'KIAbodyLatinbasic_Regular'
                    }}
                  >
                    {desc.subtitle}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>

        {/* Left Arrow */}
        <IconButton
          onClick={handlePrev}
          sx={{
            position: 'absolute',
            left: { xs: '10px', md: '40px', lg: '70px' },
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            color: '#fff',
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
          }}
        >
          <ArrowBackIos />
        </IconButton>

        {/* Right Arrow */}
        <IconButton
          onClick={handleNext}
          sx={{
            position: 'absolute',
            right: { xs: '10px', md: '40px', lg: '70px' },
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            color: '#fff',
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
          }}
        >
          <ArrowForwardIos />
        </IconButton>
      </Grid>
    </Box>
  );
};

export default ImageCarousel;
