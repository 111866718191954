const CarBanData = {
  subheading: "UNRIVALLED OWNERSHIP EXPERIENCE",
  title: "Get it all beyond price: Premium, Quality & Value.",
  data: "The new Sonet delivers a wild driving experience that’s reborn to transcend your expectations. Get ready to immerse yourself in the world of unmatched quality, unbeatable value, and unparalleled premium appeal. With a commitment to excellence, the Sonet offers exceptional value-for-money boasting the Lowest Maintenance Cost, Best Total Cost of Ownership and Highest Residual Value in its segment*.​",
  carData: [
    {
      cover:
        "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2F1-d.jpg?alt=media&token=758615a0-28ca-4d22-8ac3-5d6a40e6f470",
      small:
        "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2F90x90_3.png?alt=media&token=8d5ce4eb-9c33-4d9b-b2e1-7bdc0ea84721",
      title: "Lowest Maintenance in the Segment*",
      desc1: "Petrol - 16% lower than average",
      desc2: "Diesel - 14% lower than average",
    },

    {
      cover:
        "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2F2-d.jpg?alt=media&token=b8291bdb-de0c-4dd7-ad25-1c2d14bf7728",
      small:
        "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2F90x90_2.png?alt=media&token=a2f6801c-9e0c-4581-b894-20102172e094",
      title: "Lowest Total Cost of Ownership",
      desc1: "Segment*-best in diesel",
      desc2: "Second best in petrol",
    },
    {
      cover:
        "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2FDesk-1920x1080.jpg?alt=media&token=7973c169-3985-4b6c-b4a8-869cf6366e23",
      small:
        "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2F90x90_1.png?alt=media&token=78d53348-8f14-4b52-9ac3-01e1984e6229",
      title: "Higher Residual Value ",
      desc1: "Best-in-Segment* ",
      desc2: "3% higher than average",
    },
  ],
};

const banner2Data = {
  imgSrc:
    "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2F1920_10802.jpg?alt=media&token=489979ef-dbb5-4d3f-b422-d7cecf0ebc27",
  data: [
    {
      title: "Segment-best Safety",
      description:
        "Robust 15-Hi Safety Package as Standard, and Intuitive ADAS Level 1.0",
    },
    {
      title: "Segment-best Convenience",
      description: "360° Camera with Blind View Monitor in cluster",
    },
    {
      title: "New-age Smart",
      description: "Kia Connect with over 70 smart connected car features",
    },
  ],
};

const BannerData = {
  videoUrl:
    "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fsonet.mp4?alt=media&token=cb20fc30-e615-4d6d-b435-c4db424f809c",
  title: "New Sonet",
  subtitle: "The Wild. Reborn.",
  price: "₹7 99 900*",
  features: [
    {
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2FIcon_size1.png?alt=media&token=3994579a-cae4-409f-9a79-e0bd9d3b8ea5",
      text: "ADAS Level 1",
    },
    {
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2F360camera.png?alt=media&token=5373c911-3bb8-45ab-8eda-96ecffe65e7c",
      text: "360° Camera with Blind View Monitor in cluster",
    },
    {
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2FTaillamp.png?alt=media&token=1dd3fe04-a59a-4dbe-a3f6-711c111179f2",
      text: "Star Map LED Connected Tail Lamps",
    },
  ],
};

const IntroData = {
  heading: "DRIVEN BY INSTINCT.",
  subheading: "Introducing, The new Sonet.",
  description:
    "In a world gone tame, The new Sonet, a subcompact SUV is here to set you free. Chiseled to perfection, raw in power, and loaded with tech, it's built to give you a one-of-a-kind driving experience. So, climb in the new Kia Sonet and let your wild instincts take over.",
  image:
    "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Frange-d.jpg?alt=media&token=b3d92958-d20e-4ddd-9e82-a39f423c4a11",
};

const InterestDealerData = {
  disclaimer: "",
  register:
    "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2Fregister%20intrest.png?alt=media&token=2c5fb2a2-418b-4399-8994-8e590bd17314",
  dealer:
    "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FBrochure%20image.png?alt=media&token=5ce8b8e6-2d25-41f1-b458-69aa2bd51a10",
};

const CarouselData = [
  {
    sub: "X-Line",
    title: "The wild experience you can't resist.",
    data: "Presenting the new Sonet X-Line, now wilder than ever. Explore the wild allure of the exclusive Matte Graphite exterior, embracing a cabin experience that defies resistance. Ready to set the stage for envy as you journey through diverse horizons, this subcompact SUV in India is designed to captivate and impress.​​",
    carData: [
      {
        title: "R16 40.56cm (16”) Sporty Crystal Cut Alloy Wheels",
        description:
          "A distinctive design, featuring untamed crystal-cut patterns on a sleek black surface, is the perfect blend of sporty look with performance.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fx1.jpg?alt=media&token=ba184f7e-3573-4f9e-a157-44a3d27b0de6",
      },
      {
        title:
          "Kia Signature Tiger Nose Grille with Knurled Xclusive Black High Gloss Surround",
        description:
          "Bring out the wild in you with the fierce Kia Signature Tiger Nose grille, while the knurled glossy surround adds a touch of wild charm.​",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fx2.jpg?alt=media&token=4ff9b49c-2fb5-4b14-bfeb-28c696450b61",
      },
      {
        title: "All Door Power Window One Touch Auto Up/Down with Safety",
        description:
          "Effortlessly control all windows with a single touch while ensuring safety with auto-stop, in case of obstructions.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fx3.png?alt=media&token=69ad2bd6-4ee9-469c-b5f7-123f18a50f9a",
      },
      {
        title: "Full Digital Cluster with 26.04cm (10.25') Color LCD MID",
        description:
          "Embrace a futuristic instrument cluster that features a comprehensive and sleek interface to make every journey convenient and immersive.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fx4.jpg?alt=media&token=7cba3a1c-58a2-4be9-9fea-fcee4f872385",
      },
    ],
  },
];

const CarouselData1 = [
  {
    sub: "EXTERIOR",
    title: "A dominating stance.",
    data: "The majestic attitude of the new Sonet is sure to make a statement. The reborn new Sonet facelift is designed to look intimidating with a bold new Signature Tiger Nose Grille. The new Sonet, a popular subcompact SUV in India, commands respect and oozes dominance wherever it goes.",
    carData: [
      {
        title: "Electric Sunroof",
        description:
          "Elevate your drive with a sunroof, turning every road trip into a scenic adventure.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fext1.mp4?alt=media&token=982a1217-a535-4f70-a68f-33d8c77ed5e8",
      },
      {
        title: "Rear Spoiler",
        description:
          "Provides a sporty and dynamic appeal to The Wild Design.​​",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fext2.jpg?alt=media&token=e1fbfba4-e962-4971-a81c-0a94dc82c076",
      },
      {
        title: "Crown Jewel LED Headlamps and Star Map LED DRLs",
        description:
          "Highlight the all-new bold front and give it a dazzling road presence",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fext3.mp4?alt=media&token=bda1667f-ee89-4a06-8e64-03fe8e76cf60",
      },
      {
        title: "Signature Tiger Nose Grille",
        description:
          "Takes the untamed style quotient to stunning new heights.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fext4.jpg?alt=media&token=887d3f21-ebb8-422a-9c2f-56070e1eb661",
      },

      {
        title: "Sleek LED Fog Lamps",
        description: "Enhance the overall sleek appeal.​",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fext5.mp4?alt=media&token=909866b3-c173-402a-9021-1fa149065b0d",
      },
      {
        title: "Sporty Aerodynamic Rear Skid Plates with Dark Metallic Accents",
        description: "Adds a sporty edge to the car’s unbridled design appeal.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fext6.jpg?alt=media&token=59456ca8-1667-45ca-a9c9-06cf571585a6",
      },
      {
        title: "R16 - 40.56 cm (16”) Sporty Crystal Cut Alloy Wheels",
        description:
          "Give your wheels a striking spin as you prowl the streets.​",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fext7.jpg?alt=media&token=8e556940-d0a5-4fef-ae4a-ab8c844ab6d3",
      },
      {
        title: "Star Map LED Connected Tail Lamps",
        description:
          "For a distinctive look that seamlessly blends style and innovation.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fext8.jpg?alt=media&token=e2d46b47-a883-4216-bf19-8dc6c3448bf2",
      },
    ],
  },
  {
    sub: "INTERIOR",
    title: "Wild outside. Suave inside.",
    data: "Fierce on the outside, spacious and calm inside; the new Sonet is here to sharpen your sense of comfort. Be it the Smart Pure Air Purifier with Virus & Bacteria Protection, a futuristic Digital Screen & Cluster, or front ventilated & 4-Way Power Driver’s Seats; the abundance of tech in the new Kia Sonet will put you in a state of serenity.",
    carData: [
      {
        title: "Center Fascia AC Vents",
        description:
          "Ensure optimal airflow for driver and passenger, with the center console placed within easy reach of both.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fint1.jpg?alt=media&token=6fa46d48-52db-4b88-84a7-c0c1f5b8baa8",
      },
      {
        title: "Front Ventilated Seats",
        description: "Make every drive a breeze in hot and humid conditions. ",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fint2.jpg?alt=media&token=a841843c-85ac-4b82-b318-be13f4ce9809",
      },
      {
        title: "Smart Pure Air Purifier with Virus and Bacteria Protection^",
        description: "Ensures you breathe the cleanest air inside.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fint3.jpg?alt=media&token=65878e78-d4eb-4620-9040-393f5165bfe9",
      },
      {
        title: "Bose Premium 7- Speakers System",
        description:
          "Elevate your drive with the perfect beats, making each moment a musical adventure.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fint4.jpg?alt=media&token=65385304-a446-45da-bce6-5e6b5438708e",
      },

      {
        title: "4-Way Power Driver’s Seat",
        description:
          "Helps you get the perfect driving stance and maximum comfort.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fint5.jpg?alt=media&token=598b3637-f279-4978-bacf-a91d0bce8a88",
      },
      {
        title: "26.03 cm (10.25’’) HD Touchscreen Navigation",
        description:
          "Enables you to stay in command when it comes to finding your way effortlessly.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fint6.jpg?alt=media&token=b2f9a46b-0895-40ba-be5e-292c834d4d43",
      },
      {
        title: "LED Ambient Sound Mood Lights",
        description: "Creates the perfect atmosphere for every drive. ​",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fint7.mp4?alt=media&token=d2244c98-6839-478d-bd6e-99f754dc9294",
      },
      {
        title: "Full Digital Cluster with 26.04 cm (10.25’’) Color LCD MID",
        description:
          "Provides real-time information and vehicle status updates",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fint8.mp4?alt=media&token=deee93d1-c63e-427d-8a87-6426b2ee3df6",
      },
    ],
  },
  {
    sub: "PERFORMANCE",
    title: "Power of a beast.",
    data: "At the heart of every new Sonet is an engine throbbing with power. Power that's honed by precision driving dynamics from a wide range of refined and fuel-efficient engines mated to multiple transmission options. So, choose your favorite combination, put the pedal to the metal, and let every road hear you roar.",
    carData: [
      {
        title: "Powerful Smartstream 1.0 T – GDi Petrol Engine",
        description:
          "Dominate the urban landscape with the new Sonet and its Smartstream 1.0 T-GDi Petrol Engine, offering 88 kW power and 172 Nm torque.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fper1.jpg?alt=media&token=8e7c413b-99c9-4ca5-be3d-1ae0c8c919d9",
      },
      {
        title: "Refined 1.5 CRDi VGT Diesel Engine",
        description:
          "The new Sonet features a redefined 1.5 CRDi VGT Diesel Engine delivering 85 kW and 250 Nm torque, accompanied by a first-in-segment 6 Speed Advance AT transmission.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fper2.jpg?alt=media&token=28b6422d-0c26-45fc-9639-cdc37f370f20",
      },
      {
        title: "Advanced Smartstream 1.2 Petrol Engine",
        description:
          "Experience the thrill with the advanced Smartstream 1.2 Petrol Engine of the new Sonet, providing 61 kW power and 115 Nm torque.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fper3.jpg?alt=media&token=8d8b79ff-f33b-420e-b163-01a483cf8d4d",
      },
    ],
  },
  {
    sub: "NAVIGATE WITH TRUST, EMBRACE THE UNEXPECTED",
    title: "Drive with confidence.",
    data: "With such raw power comes the responsibility to keep you and your loved ones out of harm's way. That’s why we’ve built the new Sonet with robust 15 safety features across all variants; giving you dependable safety and a calm sense of security.",
    carData: [
      {
        title: "6 Airbags as Standard",
        description:
          "Allows the car to become your shield, protecting you from every corner.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Ftrust1.jpg?alt=media&token=5c51a50e-836d-4d34-9402-f95a8cccedc8",
      },
      {
        title:
          "Electronic Stability Control (ESC), Hill Assist Control (HAC) & Vehicle Stability Management (VSM)",
        description:
          "Comes as a part of the standard safety package, making your journey safe & reliable.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Ftrust2.jpg?alt=media&token=968f5f5d-00f3-4e52-a12f-61c253a58134",
      },
      {
        title: "Blind View Monitor (BVM)",
        description:
          "Eliminates any blind spots and surprises, giving you a more holistic view.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Ftrust3.jpg?alt=media&token=1a27e1d3-acbf-40d1-83fa-0319c14b937f",
      },
      {
        title: "Front and Rear Parking Sensors",
        description:
          "Make parking and safe maneuvering at tricky spots simple.ESS",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Ftrust4.jpg?alt=media&token=b463f3fc-20f5-4b4e-ae22-6200c59b5fab",
      },

      {
        title: "ESS (Emergency Stop Signal)",
        description:
          "Alerts the driver behind by blinking the brake lights whilst braking sharply and severely.​",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Ftrust5.jpg?alt=media&token=18b501f0-f902-48a9-b8f1-e7a1a8e0a8cf",
      },
      {
        title: "All Seat 3 point seatbelt with reminder",
        description:
          "Reminds you to strap in whether you’re in the front or back.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Ftrust6.jpg?alt=media&token=e6a171a2-3bc4-4d55-96b3-021cb7763a8c",
      },
      {
        title: "All Wheel Disc Brake",
        description:
          "Enhances braking performance, so you are always in control of any unforeseen event.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Ftrust7.jpg?alt=media&token=7c5a6f9a-ac94-4b41-bf5b-6ceb09fe9dc2",
      },
      {
        title: "360 Degree Camera",
        description: "Keeps you aware of surroundings, at all times.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Ftrust8.mp4?alt=media&token=d2ab873f-a34a-45d2-aedc-32f3ba1759a9",
      },
    ],
  },
  {
    sub: "INTUITIVE ADAS LEVEL 1",
    title: "Innovation meets safety.",
    data: "Maneuver the roads with confidence. The new Sonet is equipped with an intuitive Advance Driver Assistance System (ADAS Level 1) featuring 10 autonomous safety features that help make your drive more responsive with multiple safeguards.",
    carData: [
      {
        title: "Front Collision Warning (FCW)",
        description: "Alerts you to any unexpected obstacles ahead.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fadas1.mp4?alt=media&token=5f49476d-f5b3-4538-9e66-8f6fe4c87585",
      },
      {
        title: "Front Collision-Avoidance Assist - Cycle(FCA – Cyclist)",
        description:
          "Warns you of imminent collision with vehicles and applies brakes.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fadas10.jpg?alt=media&token=c9da877c-7b13-4141-b4c3-b99897353bd0",
      },
      {
        title: "Front Collision-Avoidance Assist - Cyclist(FCA – Cyclist)",
        description:
          "Warns of imminent collision with cyclist and applies brakes.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fadas2.jpg?alt=media&token=818b14b5-cf3c-4060-baff-394c1f89c664",
      },
      {
        title:
          "Front Collision Avoidance Assist – Pedestrian (FCA - Pedestrian)",
        description:
          "Warns you of imminent collision with pedestrians and applies brakes.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fadas3.jpg?alt=media&token=2c134122-ec16-45a7-a38e-5dfd2fd798fb",
      },

      {
        title: "Lane Keep Assist (LKA)",
        description:
          "Counter steers to prevent vehicle from departing from its lane.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fadas4.jpg?alt=media&token=20cfd67b-220a-43c1-ae23-77a5a4959739",
      },
      {
        title: "Lane Departure Warning (LDW)",
        description:
          "Alerts the driver whenever the car is going out of its lane on either side without turn indicator input.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fadas5.mp4?alt=media&token=6a25d0a2-751b-40c5-a913-4359b6be5833",
      },
      {
        title: "Lane Following Assist (LFA)",
        description: "Detects lanes and steers to keep you in the center.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fadas6.jpg?alt=media&token=4bfbfe9d-978a-4ec5-91bd-66d0dc878891",
      },
      {
        title: "Leading Vehicle Departure Alert (LVDA)",
        description: "Follows movements of the vehicle in front.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fadas7.jpg?alt=media&token=15709984-3507-42ed-9952-e7cd20c4a5c8",
      },
      {
        title: "High Beam Assist (HBA)",
        description: "Adapt your car’s headlights to oncoming traffic.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fadas8.jpg?alt=media&token=704eb4db-cd1a-45a6-a088-27eee14f65ac",
      },
      {
        title: "Driver Attention Warning (DAW)",
        description: "Warns you of any lapse in attention.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fadas9.jpg?alt=media&token=6971f961-6fea-475c-8334-7486e2fb198c",
      },
    ],
  },
  {
    sub: "CONNECTED CAR TECHNOLOGY",
    title: "Your journey, your command.",
    data: "Untamed as it may be, the new Sonet is yours to command. Next-gen Kia Connect integrated with Alexa home-to-car connectivity, and 70+ features that let you control your vehicle through a smartphone or smartwatch.",
    carData: [
      {
        title: "OTA Map & System Update",
        description:
          "Offers latest maps and system updates conveniently via Over-the-air Technology.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fcar1.jpg?alt=media&token=c19ee312-3610-4679-b89a-cd4e3998b0eb",
      },
      {
        title: "Voice Controlled Window Function",
        description:
          "Lets you pull up or open your windows without taking your hands off the wheel.​",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fcar2.jpg?alt=media&token=bb8c6975-9c98-4908-b4f9-68db5c1255ac",
      },
      {
        title: "Remote AC Control",
        description:
          "Gives you the power to set your car to the perfect temperature from anywhere.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fcar3.jpg?alt=media&token=f03bb526-af5b-4794-b94e-9e9668c972ef",
      },
      {
        title: "Kia Connect Skill with Amazon Alexa",
        description:
          "Helps you control your Kia from the comfort of your home.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fcar4.jpg?alt=media&token=3e049150-7e5d-4e81-8d33-7099e3e7bf1f",
      },
    ],
  },
];

const TrimData = [
  {
    title: "HTE",
    cost: "7,99,900",
    feature1: "6 Airbags",
    feature2: "ESC,HAC,BA, VSM",
    feature3: "High line tyre pressure monitor",
    feature4: "Advance 10.65cm (4.2'')Color instrument cluster",
    feature5: "Halogen Headlamps",
    feature6: "Semi Leatherette Seats with All Black Interiors",
    feature7: "Rear AC Vent",
  },
  {
    title: "HTE (O)",
    cost: "8,39,900",
    feature1: "Electric Sunroof",
    feature2: "6 Airbags",
    feature3: "ESC,HAC,BA, VSM",
    feature4: "High line tyre pressure monitor",
    feature5: "Advance 10.67cm (4.2'')Color instrument cluster",
    feature6: "Halogen Headlamps",
    feature7: "Rear AC Vent",
  },
  {
    title: "HTK",
    cost: "9,19,900",
    feature1: "6 Airbags",
    feature2: "ESC,HAC,BA, VSM",
    feature3: "R16 40.56cm(16'')Dual Tone Styled Wheels",
    feature4: "Front parking sensors",
    feature5:
      "20.32cm (8.0'')Touchscreen With Wireless Android Auto and Apple CarPlay",
    feature6: "Advance 10.67cm (4.2'')Color instrument cluster",
    feature7: "Shark fin antenna",
  },
  {
    title: "HTK(O)",
    cost: "9,54,900",
    feature1: "Electric Sunroof",
    feature2: "Fully Automatic Temperature Control ",
    feature3: "Star Map Connected LED Tail",
    feature4: "6Airbags",
    feature5: "ESC, HAC, BA, VSM",
    feature6: "R16 40.56cm (16'')Dual tone Styled Wheels",
    feature7: "Front parking Sensors",
  },
  {
    title: "HTK+(O)",
    cost: "10,49,900",
    feature1: "6Airbags",
    feature2: "Electric Sunroof -G1.0T Only",
    feature3: "R16 40.56cm (16'')Crystal Cut Alloy Wheels",
    feature4: "20.32cm (8.0'')Touchscreen with Wireless Android Auto CarPlay",
    feature5: "Indigo Pear Leatherette Seats",
    feature6: "Star Map LED Connected Tail Lamps",
    feature7: "Smart key with push button start",
  },

  {
    title: "HTX",
    cost: "11,82,900",
    feature1: "6 Airbags",
    feature2: "Electric Sunroof",
    feature3: "Crown Jewel LED Headlamps",
    feature4:
      "20.32cm (8.0'')Touchscreen with Wireless Android Auto and Apple CarPlay",
    feature5: "Rear Disc Brakes",
    feature6:
      "Front Ventilated Seats-G1.0T-GDi Smartstream 7DCT and D1.5 CRDI VGT iMT/6AT",
    feature7: "Black and Beige Semi Leatherette Seats-G1.0T iMT and D1.5 MT",
  },
  {
    title: "GTX+",
    cost: "14,79,900",
    feature1: "ADAS Level1 with 10 Autonomous Features",
    feature2: "360 Degree Camera with Blind View Monitor in Cluster",
    feature3: "R16 40.65cm (16'') Sporty Crystal Cut Alloy Wheels",
    feature4: "Full Digtal Cluster with 26.04cm(10.25'')Color LCD MID",
    feature5:
      "26.03cm (10.25'')HD Touchscreen Navigation with Wired Android Auto and Apple CarPlay",
    feature6: "4-Way Power Drivers Seat",
    feature7: "Front Ventilated Seats",
  },
  {
    title: "X-Line",
    cost: "14,99,900",
    feature1: "ADAS Level1 with 10 Autonomous Features",
    feature2: "360 Degree Camera wit Blind View Monitor in Cluster",
    feature3: "R16 40.56cm (16'')Sporty Crystal Cut Alloy Wheels",
    feature4: "Full Digital Cluster with 26.04cm (10.25'')Color LCD MID",
    feature5:
      "26.03 cm (10.25”) HD Touchscreen Navigation with Wired Android Auto and Apple CarPlay",
    feature6: "4-Way Power Drivers Seat",
    feature7: "Front Ventilated Seats",
  },
];

const CircleData = [
  {
    title: "Intense Red + Aurora Black Pearl",
    color1: "#b2001e",
    color2: "#151617",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fson1.png?alt=media&token=d7a970de-0c56-4349-9523-b05c2cc3a96b",
  },
  {
    title: "Glacier White Pearl + Aurora Black Pearl",
    color1: "#f4f9fb",
    color2: "#151617",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fson2.png?alt=media&token=3f30511b-e24a-4385-b778-54ad31b46c17",
  },
  {
    title: "Gravity Grey",
    color: "#444444",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fson3.png?alt=media&token=476b61bf-dee0-4ff1-a96e-5d804a4e66b3",
  },
  {
    title: "Aurora Black Pearl",
    color: "#151617",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fson4.png?alt=media&token=4b9b6ef5-ba83-499e-9d76-42ade7eefe50",
  },
  {
    title: "Sparkling Silver",
    color: "#e0e0df",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fson5.png?alt=media&token=8ad0d29e-9039-435b-ba3b-20053aba62bf",
  },
  {
    title: "Intense Red",
    color: "#b2001e",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fson6.png?alt=media&token=ce43ff4c-bc88-4dae-9aee-a0923948cc9b",
  },
  {
    title: "Glacier White Pearl",
    color: "#f4f9fb",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fson7.png?alt=media&token=9b680f2b-2f8c-4bc2-8082-067b04fd5790",
  },
  {
    title: "Imperial Blue",
    color: "#2b2c71",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fson8.png?alt=media&token=13439306-2fe9-4015-9eec-768f48ec105a",
  },
  {
    title: "Pewter Olive",
    color: "#3e493b",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fson9.png?alt=media&token=5d470a0a-fa64-4308-a3f7-1ae3432b3ded",
  },
];

const DetailInfo = [
  {
    bgColor: "#FFF",
    color: "#000",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2Fbook.png?alt=media&token=60d7f5b9-c30b-4c19-a810-1ecf835516c8",
    title: "Book New Sonet ",
    desc: "Learn all about its wild possibilities.",
    link: "",
  },
  {
    bgColor: "#05141f",
    color: "#FFF",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2Fdownload.png?alt=media&token=53d6a48a-54b2-4093-b4f8-ebcaa1873a4c",
    title: "Download Brochure",
    desc: "Learn all about its wild possibilities.",
    link: "https://www.kia.com/content/dam/kia2/in/en/our-vehicles/showroom/sonet/Sonet_Wild_Reborn_Brochure_2025_Desktop.pdf",
  },
  {
    bgColor: "#FFF",
    color: "#000",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2Fcall.png?alt=media&token=dbd67b81-8ded-4bf9-bc21-f649fe1dec89",
    title: "Call us for more information",
    desc: "We'll be happy to answer all your queries. 09237386213",
    link: "",
  },
];

const FaqData = [
  {
    question: "What is the price of the new Sonet?",
    answer: "Kia Sonet starts at ₹7 99 900*(Ex-showroom price all India).",
  },
  {
    question: "How many features does the new Sonet offer in its ADAS system?",
    answer:
      "The ADAS Level 1 in the new Sonet comes with 10 autonomous features to make your journey a wild one!",
  },
  {
    question: "What kind of cluster is available in the new Sonet?",
    answer:
      "The new Sonet comes with Full Digital Cluster with 26.04 cm (10.25’’) Color LCD MID offering detailed car information.",
  },
  {
    question: "How many airbags are there in the new Sonet?",
    answer:
      "The new Sonet offers 6 Airbags (Front, Side & Curtain Airbags) standard to ensure the safety of you and your loved ones",
  },
  {
    question: "What type of sunroof comes with the new Sonet?",
    answer:
      "The new Sonet compact SUV comes with Electric Sunroof that gives you a chance to connect with the outside world",
  },

  {
    question: "What are the transmission options available in the new Sonet?",
    answer:
      "The new Sonet SUV car is available in both manual and automatic transmissions, namely 5MT, 6MT, 6iMT, 7DCT, 6AT",
  },
];

const StepData = [
  {
    title: "Signature Tiger Nose Grille",
    desc: "Elevate your style with the Signature Tiger Nose Grille, taking the untamed quotient to breathtaking new heights.",
  },
  {
    title: "R16 - 40.56 cm (16'') Sporty Crystal Cut Alloy Wheels",
    desc: "Turn heads as you prowl the streets with Crystal Cut Alloy Wheels and All Wheel Disc Brakes, giving your wheels a mesmerizing spin.",
  },
  {
    title: "Star Map LED Connected Tail Lamps",
    desc: "Drive away in bold style with Connected LED Tail Lamps, making the rear of your vehicle as distinctive as your journey.",
  },
  {
    title: "Electric Sunroof ",
    desc: "Experience a connection with the outside world through the Electric Sunroof,specially designed for compact SUV enthusiasts. ",
  },
  {
    title:
      "Full Digital Cluster with 26.04 cm (10.25'')Color LCD MID+26.03 cm (10.25'')HD Touchscreen Navigation ",
    desc: "Take command effortlessly with the 26.03cm(10.25'') Digital Cluster + 26.03 cm HD Touchscreen Audio (10.25''),allowing you to stay in command and find your way with ease.",
  },
  {
    title: "Front Ventilated Seats with 4-Way Power Driver's Seat",
    desc: "Conquer hot and humid conditions with Front Ventilated Seats and a 4-Way Power Driver's Seat,ensuring every drive is a breeze with the perfect driving stance and maximum comfort.",
  },
];

export {
  CarouselData,
  CarouselData1,
  TrimData,
  CircleData,
  DetailInfo,
  FaqData,
  StepData,
  BannerData,
  IntroData,
  InterestDealerData,
  CarBanData,
  banner2Data,
};
