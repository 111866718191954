import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import React from 'react';
import { Box, IconButton } from '@mui/material';

const Wsp = () => {
  const handleWhatsAppClick = () => {
    window.open('https://wa.me/9237301338', '_blank'); // Opens WhatsApp link
  };

  return (
    <Box sx={{ position: 'fixed', bottom: 20, right: 20, zIndex: 1200 }}>
      {/* Floating WhatsApp Button */}
      <IconButton
        onClick={handleWhatsAppClick}
        aria-label="Chat on WhatsApp"
        sx={{
          backgroundColor: '#25D366',
          color: '#fff',
          width: 56,
          height: 56,
          boxShadow: 3,
          transition: '0.3s ease-in-out',
          '&:hover': {
            backgroundColor: '#1DA955',
            transform: 'scale(1.1)',
          },
        }}
      >
        <WhatsAppIcon sx={{ fontSize: 30 }} />
      </IconButton>
    </Box>
  );
};

export default Wsp;
