import {
  Box,
  Grid,
  Skeleton,
  Stack,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 3,
    left: "calc(-50% + 0px)",
    right: "calc(50% + 0px)",
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    borderRadius: 1,
    backgroundColor: "rgba(0, 0, 0, 0.2)", // Connector color
    transition: "background-color 0.3s ease",
  },
  [`&.${stepConnectorClasses.active} .${stepConnectorClasses.line}`]: {
    backgroundColor: "#BB162B", // Active step color
  },
  [`&.${stepConnectorClasses.completed} .${stepConnectorClasses.line}`]: {
    backgroundColor: "#BB162B", // Completed steps color
  },
}));

const ColorlibStepIconRoot = styled("div")(({ ownerState }) => ({
  backgroundColor: "#ccc", // Default gray color
  zIndex: 1,
  width: 8,
  height: 8,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  transition: "background-color 0.3s ease, transform 0.3s ease",
  ...(ownerState.active && {
    backgroundColor: "#FFF", // Active step is white
    transform: "scale(3.5)",
  }),
  ...(ownerState.completed && {
    backgroundColor: "#BB162B",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, onClick } = props;
  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      onClick={onClick}
    />
  );
}

export default function CarStepper({ StepData, carLink, stepTimings }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [targetStep, setTargetStep] = useState(null); // Track which step is clicked
  const videoRef = useRef(null); // Reference for the video element
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Function to handle step click
  const handleStepClick = (clickedStep) => {
    if (videoRef.current) {
      setActiveStep(clickedStep); // Instantly activate step in UI
      setTargetStep(clickedStep); // Track the target step

      const previousStepTime = stepTimings[activeStep]; // Start time
      videoRef.current.currentTime = previousStepTime; // Seek to previous step time
      videoRef.current.play(); // Play video
    }
  };

  // Automatically update step when video reaches specific time
  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleTimeUpdate = () => {
      let newStep = 0;
      for (let i = 0; i < stepTimings.length; i++) {
        if (video.currentTime >= stepTimings[i]) {
          newStep = i;
        } else {
          break;
        }
      }
      setActiveStep(newStep);

      // Pause the video when it reaches the target step timing
      if (targetStep !== null && video.currentTime >= stepTimings[targetStep]) {
        video.pause(); // Pause at the correct step
        setTargetStep(null); // Reset the target step
      }
    };

    video.addEventListener("timeupdate", handleTimeUpdate);
    return () => video.removeEventListener("timeupdate", handleTimeUpdate);
  }, [targetStep]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box
          position="relative"
          width="100%"
          height={isSmallScreen ? "50vh" : "100vh"}
        >
          {/* Background Video */}
          {!isLoaded && (
              <Skeleton
                animation="wave"
                variant="rectangular"
                width="100%"
                height="100%"
              />
            )}
          <Box
            component="video"
            playsInline
            autoPlay
            loop
            muted
            preload="auto"
            onCanPlayThrough={() => setIsLoaded(true)}
            ref={videoRef} // Attach ref to video
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          >
            <source src={carLink} type="video/mp4" />
          </Box>

          <Grid
            container
            p={"7%"}
            sx={{
              position: "absolute",
            }}
          >
            <Grid
              item
              xs={7}
              sm={6}
              md={4}
              backgroundColor="#FFF"
              sx={{
                p: { xs: 1, sm: 2, md: 3 },
              }}
            >
              <Typography
                variant="subtitle1"
                color="#888"
                letterSpacing={2}
                fontFamily="KIAbodyLatinbasic_Regular"
                sx={{
                  fontSize: "clamp(8px, 3vw, 16px)",
                }}
              >
                EXPLORE
              </Typography>
              <Typography
                fontFamily="KIAbodyLatinbasic_Bold"
                lineHeight={1.5}
                sx={{
                  fontSize: "clamp(10px, 2vw, 20px)",
                }}
              >
                {StepData[activeStep].title}
              </Typography>
              <Typography
                fontFamily="KIAbodyLatinbasic_Regular"
                textAlign={"justify"}
                sx={{
                  fontSize: "clamp(8px, 1.8vw, 16px)",
                }}
              >
                {StepData[activeStep].desc}
              </Typography>
            </Grid>
          </Grid>

          {/* Stepper Overlay */}
          <Grid
            container
            position="absolute"
            bottom="5%"
            sx={{
              color: "white",
              px: { xs: 2, sm: 4, md: 8 },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item xs={12}>
              <Stack>
                <Stepper
                  alternativeLabel
                  activeStep={activeStep}
                  connector={<ColorlibConnector />}
                >
                  {StepData.map((label, index) => (
                    <Step key={index}>
                      <StepLabel
                        sx={{
                          color: "#FFF !important", // Always white labels
                          "& .MuiStepLabel-label": { color: "#FFF !important" },
                          fontFamily: "KIAbodyLatinbasic_Regular",
                        }}
                        StepIconComponent={(props) => (
                          <ColorlibStepIcon
                            {...props}
                            onClick={() => handleStepClick(index)}
                          />
                        )}
                      >
                        {!isSmallScreen && label.title}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
