import {
  ALLADDES,
  ADDESBYSTATUS,
  ALLCONTACTUS,
  ALLTESTDRIVE,
  SETCARID,
  ALLOLDCAR,
  VIEWFETURESBYID,
  VIEWOLDCARBYID,
  VIEWOVERVIEWBYID,
  VIEWSPECIFICATIONBYID,
  VIEW_CONTACT_US_LENGTH,
  VIEW_TESTDRIVE_LENGTH,
  OLDCARLENGTH,
  ALLSERVICE,
  ALLVEHICLEENQ,
  ALLENQUIRY,
  ALLREVIEWS,
  ALLQUATATION,
} from "./constant";
import UNIVERSAL from "../../src/Config/Config";
import { setLoader, unsetLoader } from "../Common/loader/action";
import { set_snack_bar } from "../Common/snackbar/action";
import "firebase/storage";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

export function uploadImaes(token, user_id, image, mediaType, page, pageSize) {
  return (dispatch) => {
    dispatch(setLoader(true)); // Show loader

    if (image) {
      // Create a unique filename using the original name and a random number
      const imageName = `${image.name}-${Math.random()
        .toString(36)
        .substr(2, 9)}.${mediaType === "image" ? "png" : "mp4"}`;
      const storageRef = ref(getStorage(), `/addes/${imageName}`); // Reference to Firebase Storage

      // Start the upload
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Optional: Handle progress here
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => {
          console.error("Upload failed:", error);
          dispatch(setLoader(false)); // Hide loader on error
          dispatch(set_snack_bar(true, "Image Could Not Be Uploaded")); // Show error message
        },
        () => {
          // Handle successful uploads
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            if (downloadURL) {
              dispatch(
                createAddes(
                  token,
                  user_id,
                  downloadURL,
                  mediaType,
                  page,
                  pageSize
                )
              ); // Dispatch action with download URL
              dispatch(set_snack_bar(true, "Image Uploaded Successfully")); // Show success message
            } else {
              dispatch(set_snack_bar(true, "Image not Uploaded")); // Show error message
            }
            dispatch(setLoader(false)); // Hide loader
          });
        }
      );
    } else {
      // If no image is provided, directly dispatch createAddes
      dispatch(createAddes(token, user_id, image, mediaType, page, pageSize));
      dispatch(setLoader(false)); // Hide loader
    }
  };
}

export function createAddes(token, user_id, image, mediaType, page, pageSize) {
  

  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "addes/register", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        image,
        mediaType,
        addedBy: user_id,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(
            viewAlladdes(token, user_id, "", page, pageSize, "", "", "")
          );
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(
            viewAlladdes(token, user_id, "", page, pageSize, "", "", "")
          );
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

export function viewAlladdes(
  token,
  user_id,
  searchInput,
  page,
  pageSize,
  startDate,
  endDate,
  selectedStatus
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "addes/view", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        searchTerm: searchInput,
        page: parseInt(page) + 1,
        pageSize,
        startDate,
        endDate,
        selectedStatus,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({
            type: ALLADDES,
            payload: responseJson.data.data,
            count: responseJson.data.totalAddess,
          });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: ALLADDES, payload: [], count: 0 });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

export function updateStatus(token, user_id, id, status, page, pageSize) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "addes/updateStatus", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        id,
        status,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(
            viewAlladdes(token, user_id, "", page, pageSize, "", "", "")
          );
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(
            viewAlladdes(token, user_id, "", page, pageSize, "", "", "")
          );
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

export function uploadupdatesImages(
  token,
  user_id,
  id,
  image,
  mediaType,
  page,
  pageSize
) {
  return (dispatch) => {
    dispatch(setLoader(true));
    if (image !== "") {
      const storageRef = ref(
        getStorage(),
        "/addes/" + image + Math.random() + ".png"
      );
      const uploadimage = uploadBytesResumable(storageRef, image);
      uploadimage.on(
        "state_changed",
        function (snapshot) {},
        function (error) {
          console.log(error);
          //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
          dispatch(setLoader(false));
        },

        function () {
          getDownloadURL(uploadimage.snapshot.ref).then(function (image) {
            if (getDownloadURL(uploadimage.snapshot.ref) !== null) {
              dispatch(
                update(token, user_id, id, image, mediaType, page, pageSize)
              );
              dispatch(set_snack_bar(true, "Image Uploaded"));
            } else {
              dispatch(set_snack_bar(true, "Image not Uploaded"));
            }
          });
        }
      );
    } else {
      dispatch(update(token, user_id, id, image, mediaType, page, pageSize));
    }
  };
}

export function update(token, user_id, id, image, mediaType, page, pageSize) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "addes/update", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        id,
        image,
        mediaType,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(
            viewAlladdes(token, user_id, "", page, pageSize, "", "", "")
          );
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(
            viewAlladdes(token, user_id, "", page, pageSize, "", "", "")
          );
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

export function deleteAddes(token, user_id, id, page, pageSize) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "addes/delete", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        id,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(
            viewAlladdes(token, user_id, "", page, pageSize, "", "", "")
          );
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(
            viewAlladdes(token, user_id, "", page, pageSize, "", "", "")
          );
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

export function viewAddesByStatus() {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "addes/viewAddesByStatus", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({ type: ADDESBYSTATUS, payload: responseJson.data.result });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: ADDESBYSTATUS, payload: [] });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
//------------------------------------------Test Drive--------------------------------------------------------------------//
export function BookTestDrive(
  name,
  email,
  mobile,
  modelType,
  dateTime,
  address
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "testDrive/bookTestDrive", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        email,
        mobile,
        modelType,
        dateTime,
        address,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch();
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch();
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function viewAllTestDrive(
  token,
  user_id,
  searchInput,
  page,
  pageSize,
  startDate,
  endDate,
  selectedStatus
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "testDrive/view", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        searchTerm: searchInput,
        page: parseInt(page) + 1,
        pageSize,
        startDate,
        endDate,
        selectedStatus,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({
            type: ALLTESTDRIVE,
            payload: responseJson.data.data,
            count: responseJson.data.testDriveDetail,
          });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: ALLTESTDRIVE, payload: [], count: 0 });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function deleteTestDrive(token, user_id, id, page, pageSize) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "testDrive/delete", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        id,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(
            viewAllTestDrive(token, user_id, "", page, pageSize, "", "", "")
          );
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(
            viewAllTestDrive(token, user_id, "", page, pageSize, "", "", "")
          );
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function updateTestDriveStatus(
  token,
  user_id,
  id,
  status,
  page,
  pageSize
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "testDrive/updateStatus", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        id,
        status,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(
            viewAllTestDrive(token, user_id, "", page, pageSize, "", "", "")
          );
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(
            viewAllTestDrive(token, user_id, "", page, pageSize, "", "", "")
          );
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
//----------------------------------------------ContactUs---------------------------------------------------------//

export function AddContactForm(
  firstName,
  lastName,
  mobile,
  email,
  queryType,
  querySubject,
  query,
  agreedToPolicy
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "contactus/register", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName,
        lastName,
        mobile,
        email,
        queryType,
        querySubject,
        query,
        agreedToPolicy,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch();
          dispatch(unsetLoader());
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch();
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function viewAllContactUs(
  token,
  user_id,
  searchInput,
  page,
  pageSize,
  startDate,
  endDate,
  selectedStatus
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "contactus/view", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        searchTerm: searchInput,
        page: parseInt(page) + 1,
        pageSize,
        startDate,
        endDate,
        selectedStatus,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({
            type: ALLCONTACTUS,
            payload: responseJson.data.data,
            count: responseJson.data.contactUsDetail,
          });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: ALLCONTACTUS, payload: [], count: 0 });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function deleteContact(token, user_id, id, page, pageSize) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "contactus/delete", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        id,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(
            viewAllContactUs(token, user_id, "", page, pageSize, "", "", "")
          );
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(
            viewAllContactUs(token, user_id, "", page, pageSize, "", "", "")
          );
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function updateContactStatus(
  token,
  user_id,
  id,
  status,
  page,
  pageSize
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "contactus/updateStatus", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        id,
        status,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(
            viewAllContactUs(token, user_id, "", page, pageSize, "", "", "")
          );
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(
            viewAllContactUs(token, user_id, "", page, pageSize, "", "", "")
          );
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
//----------------------------Add Old Cars-------------------------------------------------------------//
export function addOldCar(
  token,
  user_id,
  brand,
  carName,
  fuelType,
  carType,
  price,
  discountedPrice,
  kilometer,
  images // Array of objects like [{image1: File}, {image2: File}]
) {


  return (dispatch) => {
    dispatch(setLoader(true));

    const imageUrls = []; // Array to store uploaded image URLs

    // Extract File objects from the images array (which is an array of objects like {image1: File})
    const extractedImages = images.map(
      (imageObj) => Object.values(imageObj)[0]
    );

    // Create a promise for each image upload
    const uploadPromises = extractedImages.map((image) => {
      return new Promise((resolve, reject) => {
        if (image instanceof File) {
          // Ensure each image is a valid File object
          const uniqueImageName = `/oldCar/${image.name}_${Date.now()}.png`; // Use unique name with timestamp
          const storageRef = ref(getStorage(), uniqueImageName);
          const uploadTask = uploadBytesResumable(storageRef, image);

          // Handle image upload
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              // Optional: Track progress if needed
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log(`Upload is ${progress}% done for ${image.name}`);
            },
            (error) => {
              console.error(`Error uploading ${image.name}:`, error);
              reject(error); // Reject the promise on error
            },
            () => {
              // On successful upload, get the download URL
              getDownloadURL(uploadTask.snapshot.ref)
                .then((url) => {
                  imageUrls.push(url); // Store the download URL
                  resolve(); // Resolve promise once the image is uploaded
                })
                .catch((error) => {
                  console.error(
                    `Error getting download URL for ${image.name}:`,
                    error
                  );
                  reject(error); // Reject if URL fetching fails
                });
            }
          );
        } else {
          resolve(); // Resolve immediately if no valid image is provided
        }
      });
    });

    // Wait for all images to finish uploading
    Promise.all(uploadPromises)
      .then(() => {
        // After all uploads, dispatch the action with image URLs
        dispatch(
          addOldCars(
            token,
            user_id,
            brand,
            carName,
            fuelType,
            carType,
            price,
            discountedPrice,
            kilometer,
            imageUrls // Array of uploaded image URLs
          )
        );
        dispatch(set_snack_bar(true, "Images Uploaded Successfully"));
      })
      .catch((error) => {
        console.error("Error uploading images:", error);
        dispatch(set_snack_bar(true, "Failed to upload one or more images"));
      })
      .finally(() => {
        dispatch(setLoader(false)); // Stop the loader in any case
      });
  };
}

export function addOldCars(
  token,
  user_id,
  brand,
  carName,
  fuelType,
  carType,
  price,
  discountedPrice,
  kilometer,
  image
) {

  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "oldcar/register", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        addedBy: user_id,
        brand,
        carName,
        fuelType,
        carType,
        price,
        discountedPrice,
        kilometer,
        image,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(setcarId(responseJson.data.oldCar._id));
          dispatch(unsetLoader());
          dispatch(
            set_snack_bar(responseJson.statusCode, responseJson.message)
          );
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

export function setcarId(payload) {
  return {
    type: SETCARID,
    payload: payload,
  };
}
//---------------------------Overview----------------------------------------
export function addOverview(
  token,
  user_id,
  oldCarId,
  registerationYear,
  manufacturingYear,
  owners,
  transmission,
  color,
  // location,
  insurance,
  registerationType,
  // lastUpdated,
  description
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "overview/register", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        addedBy: user_id,
        oldCar_id: oldCarId,
        registerationYear,
        manufacturingYear,
        owners,
        transmission,
        color,
        // location,
        insurance,
        registerationType,
        // lastUpdated,
        description,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(setcarId(oldCarId));
          dispatch(unsetLoader());
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(setcarId(oldCarId));
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

//--------------------------Specification-------------------------------------
export function addSpecification(
  token,
  user_id,
  oldCar_id,
  engine,
  //engineType,
  maxPower,
  maxTorque,
  mileage,
  driveTrain,
  // turbocharger,
  length,
  width,
  height,
  wheelbase,
  groundClearance,
  kerbWeight,
  doors,
  seatingCapacity,
  rows,
  bootspace,
  fuelTankCapacity,
  frontSuspension,
  rearSuspension,
  frontbrakeType,
  rearBrakeType,
  minTurningRadius,
  steeringType,
  wheels,
  spareWheel,
  frontTyres,
  rearTyres
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "specification/register", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        addedBy: user_id,
        oldCar_id,
        engine,
        //engineType,
        maxPower,
        maxTorque,
        mileage,
        driveTrain,
        //turbocharger,
        length,
        width,
        height,
        wheelbase,
        groundClearance,
        kerbWeight,
        doors,
        seatingCapacity,
        rows,
        bootspace,
        fuelTankCapacity,
        frontSuspension,
        rearSuspension,
        frontbrakeType,
        rearBrakeType,
        minTurningRadius,
        steeringType,
        wheels,
        spareWheel,
        frontTyres,
        rearTyres,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(setcarId(oldCar_id));
          dispatch(unsetLoader());
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(setcarId(oldCar_id));
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

//------------------------Feature--------------------------------------------
export function addFeature(
  token,
  user_id,
  oldCar_id,
  airbag,
  tyrePresssure,
  childSeatAnchorPoints,
  seatBeltWarning,
  // des1,
  abs,
  ebd,
  ba,
  esp,
  fourWheel,
  hillHoldControl,
  tcs,
  // rideHeightAdjustment,
  hillDescentControl,
  // des2,
  engineImmobiliser,
  centeralLocking,
  speedSensingDoorLock,
  childSafetyLock,
  ac,
  frontac,
  rearac,
  heater,
  vanityMirror,
  antiglareMirror,
  parkingAssist,
  parkingSensor,
  cruisecontrol,
  headlightIgnition,
  keylessstart,
  steeringAdjustment,
  power,
  // des3,
  applecarplay,
  androidauto,
  // des4,
  seatUpholestry,
  drivesideAdjust,
  leatherWrapped,
  driverArmrest,
  // rearPassengerSeatType,
  // interiors,
  // interiorsColor,
  foldingRearSeat,
  splitRearSeat,
  splitThirdRowSeat,
  frontSeatbackPockets,
  headrests,
  // des5,
  cupholders,
  driverArmrestStorage,
  cooledGloveBox,
  sunGlassHolder,
  thirdRowCupHolder,
  // des6,
  orvm,
  powerWindow,
  oneTouchDown,
  oneTouchUp,
  adjustableOrvm,
  exteriorDoorHandle,
  chromeexteriorDoorHandle,
  doorPockets,
  bootlidOpener,
  // des7,
  sunroof,
  roofMountedAntenna,
  bodyColouredBumpers,
  chromeFinish,
  // des8,
  projectorheadlight,
  autoheadlamps,
  homeheadlamps,
  drl,
  tailights,
  cabinLamps,
  lightonVanityMirror,
  rearreadinglamp,
  gloveBoxlamp,
  headlightHeightAdjuster,
  // des9,
  // instantaneousConsumption,
  // instrumentCluster,
  tripMeter,
  averageFuelConsumption,
  // averageSpeed,
  distancetoEmpty,
  clock,
  lowFuelWarning,
  doorAjarWarning,
  adjustableCluster,
  gearIndicator,
  shiftIndicator,
  tachometer,
  // des10,
  // display,
  musicSystem,
  speaker,
  steeringmountedctrls,
  voiceCommand,
  gps,
  bluetooth,
  aux,
  radio,
  usb,
  headUnitSize,
  // iPod,
  // des11,
  // warrrantyyear,
  // warrrantykm,
  des12
) {

  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "fetures/register", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        addedBy: user_id,
        oldCar_id,
        airbag,
        tyrePresssure,
        childSeatAnchorPoints,
        seatBeltWarning,
        // des1,
        abs,
        ebd,
        ba,
        esp,
        fourWheel,
        hillHoldControl,
        tcs,
        // rideHeightAdjustment,
        hillDescentControl,
        // des2,
        engineImmobiliser,
        centeralLocking,
        speedSensingDoorLock,
        childSafetyLock,
        ac,
        frontac,
        rearac,
        heater,
        vanityMirror,
        antiglareMirror,
        parkingAssist,
        parkingSensor,
        cruisecontrol,
        headlightIgnition,
        keylessstart,
        steeringAdjustment,
        power,
        // des3,
        applecarplay,
        androidauto,
        // des4,
        seatUpholestry,
        drivesideAdjust,
        leatherWrapped,
        driverArmrest,
        // rearPassengerSeatType,
        // interiors,
        // interiorsColor,
        foldingRearSeat,
        splitRearSeat,
        splitThirdRowSeat,
        frontSeatbackPockets,
        headrests,
        // des5,
        cupholders,
        driverArmrestStorage,
        cooledGloveBox,
        sunGlassHolder,
        thirdRowCupHolder,
        // des6,
        orvm,
        powerWindow,
        oneTouchDown,
        oneTouchUp,
        adjustableOrvm,
        exteriorDoorHandle,
        chromeexteriorDoorHandle,
        doorPockets,
        bootlidOpener,
        // des7,
        sunroof,
        roofMountedAntenna,
        bodyColouredBumpers,
        chromeFinish,
        // des8,
        projectorheadlight,
        autoheadlamps,
        homeheadlamps,
        drl,
        tailights,
        cabinLamps,
        lightonVanityMirror,
        rearreadinglamp,
        gloveBoxlamp,
        headlightHeightAdjuster,
        // des9,
        // instantaneousConsumption,
        // instrumentCluster,
        tripMeter,
        averageFuelConsumption,
        // averageSpeed,
        distancetoEmpty,
        clock,
        lowFuelWarning,
        doorAjarWarning,
        adjustableCluster,
        gearIndicator,
        shiftIndicator,
        tachometer,
        // des10,
        // display,
        musicSystem,
        speaker,
        steeringmountedctrls,
        voiceCommand,
        gps,
        bluetooth,
        aux,
        radio,
        usb,
        headUnitSize,
        // iPod,
        // des11,
        // warrrantyyear,
        // warrrantykm,
        des12,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(setcarId(oldCar_id));
          dispatch(unsetLoader());
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(setcarId(oldCar_id));
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
//----------------------------Edit cars---------------------------------------
export function AddeditCar(
  token,
  user_id,
  oldCar_id,
  brand,
  carName,
  fuelType,
  carType,
  price,
  discountedPrice,
  kilometer,
  imagesData,
  registerationYear,
  manufacturingYear,
  owners,
  transmission,
  color,
  insurance,
  registerationType,
  description,
  engine,
  // engineType,
  maxPower,
  maxTorque,
  mileage,
  driveTrain,
  // turbocharger,
  length,
  width,
  height,
  wheelbase,
  groundClearance,
  kerbWeight,
  doors,
  seatingCapacity,
  rows,
  bootspace,
  fuelTankCapacity,
  frontSuspension,
  rearSuspension,
  frontbrakeType,
  rearBrakeType,
  minTurningRadius,
  steeringType,
  wheels,
  spareWheel,
  frontTyres,
  rearTyres,
  airbag,
  tyrePresssure,
  childSeatAnchorPoints,
  seatBeltWarning,
  // des1,
  abs,
  ebd,
  ba,
  esp,
  fourWheel,
  hillHoldControl,
  tcs,
  // rideHeightAdjustment,
  hillDescentControl,
  // des2,
  engineImmobiliser,
  centeralLocking,
  speedSensingDoorLock,
  childSafetyLock,
  ac,
  frontac,
  rearac,
  heater,
  vanityMirror,
  antiglareMirror,
  parkingAssist,
  parkingSensor,
  cruisecontrol,
  headlightIgnition,
  keylessstart,
  steeringAdjustment,
  power,
  // des3,
  applecarplay,
  androidauto,
  // des4,
  seatUpholestry,
  drivesideAdjust,
  leatherWrapped,
  driverArmrest,
  // rearPassengerSeatType,
  // interiors,
  // interiorsColor,
  foldingRearSeat,
  splitRearSeat,
  splitThirdRowSeat,
  frontSeatbackPockets,
  headrests,
  // des5,
  cupholders,
  driverArmrestStorage,
  cooledGloveBox,
  sunGlassHolder,
  thirdRowCupHolder,
  // des6,
  orvm,
  powerWindow,
  oneTouchDown,
  oneTouchUp,
  adjustableOrvm,
  exteriorDoorHandle,
  chromeexteriorDoorHandle,
  doorPockets,
  bootlidOpener,
  // des7,
  sunroof,
  roofMountedAntenna,
  bodyColouredBumpers,
  chromeFinish,
  // des8,
  projectorheadlight,
  autoheadlamps,
  homeheadlamps,
  drl,
  tailights,
  cabinLamps,
  lightonVanityMirror,
  rearreadinglamp,
  gloveBoxlamp,
  headlightHeightAdjuster,
  // des9,
  // instantaneousConsumption,
  // instrumentCluster,
  tripMeter,
  averageFuelConsumption,
  // averageSpeed,
  distancetoEmpty,
  clock,
  lowFuelWarning,
  doorAjarWarning,
  adjustableCluster,
  gearIndicator,
  shiftIndicator,
  tachometer,
  // des10,
  // display,
  musicSystem,
  speaker,
  steeringmountedctrls,
  voiceCommand,
  gps,
  bluetooth,
  aux,
  radio,
  usb,
  headUnitSize,
  // iPod,
  // des11,
  // warrrantyyear,
  // warrrantykm,
  des12
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "specification/register", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        addedBy: user_id,
        oldCar_id,
        brand,
        carName,
        fuelType,
        carType,
        price,
        discountedPrice,
        kilometer,
        imagesData,
        registerationYear,
        manufacturingYear,
        owners,
        transmission,
        color,
        insurance,
        registerationType,
        description,
        engine,
        // engineType,
        maxPower,
        maxTorque,
        mileage,
        driveTrain,
        // turbocharger,
        length,
        width,
        height,
        wheelbase,
        groundClearance,
        kerbWeight,
        doors,
        seatingCapacity,
        rows,
        bootspace,
        fuelTankCapacity,
        frontSuspension,
        rearSuspension,
        frontbrakeType,
        rearBrakeType,
        minTurningRadius,
        steeringType,
        wheels,
        spareWheel,
        frontTyres,
        rearTyres,
        airbag,
        tyrePresssure,
        childSeatAnchorPoints,
        seatBeltWarning,
        // des1,
        abs,
        ebd,
        ba,
        esp,
        fourWheel,
        hillHoldControl,
        tcs,
        // rideHeightAdjustment,
        hillDescentControl,
        // des2,
        engineImmobiliser,
        centeralLocking,
        speedSensingDoorLock,
        childSafetyLock,
        ac,
        frontac,
        rearac,
        heater,
        vanityMirror,
        antiglareMirror,
        parkingAssist,
        parkingSensor,
        cruisecontrol,
        headlightIgnition,
        keylessstart,
        steeringAdjustment,
        power,
        // des3,
        applecarplay,
        androidauto,
        // des4,
        seatUpholestry,
        drivesideAdjust,
        leatherWrapped,
        driverArmrest,
        // rearPassengerSeatType,
        // interiors,
        // interiorsColor,
        foldingRearSeat,
        splitRearSeat,
        splitThirdRowSeat,
        frontSeatbackPockets,
        headrests,
        // des5,
        cupholders,
        driverArmrestStorage,
        cooledGloveBox,
        sunGlassHolder,
        thirdRowCupHolder,
        // des6,
        orvm,
        powerWindow,
        oneTouchDown,
        oneTouchUp,
        adjustableOrvm,
        exteriorDoorHandle,
        chromeexteriorDoorHandle,
        doorPockets,
        bootlidOpener,
        // des7,
        sunroof,
        roofMountedAntenna,
        bodyColouredBumpers,
        chromeFinish,
        // des8,
        projectorheadlight,
        autoheadlamps,
        homeheadlamps,
        drl,
        tailights,
        cabinLamps,
        lightonVanityMirror,
        rearreadinglamp,
        gloveBoxlamp,
        headlightHeightAdjuster,
        // des9,
        // instantaneousConsumption,
        // instrumentCluster,
        tripMeter,
        averageFuelConsumption,
        // averageSpeed,
        distancetoEmpty,
        clock,
        lowFuelWarning,
        doorAjarWarning,
        adjustableCluster,
        gearIndicator,
        shiftIndicator,
        tachometer,
        // des10,
        // display,
        musicSystem,
        speaker,
        steeringmountedctrls,
        voiceCommand,
        gps,
        bluetooth,
        aux,
        radio,
        usb,
        headUnitSize,
        // iPod,
        // des11,
        // warrrantyyear,
        // warrrantykm,
        des12,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(setcarId(oldCar_id));
          dispatch(unsetLoader());
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(setcarId(oldCar_id));
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function viewAllOldCar(
  token,
  user_id,
  searchInput,
  page,
  pageSize,
  startDate,
  endDate,
  selectedStatus
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "oldcar/view", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        searchTerm: searchInput,
        page: parseInt(page) + 1,
        pageSize,
        startDate,
        endDate,
        selectedStatus,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({
            type: ALLOLDCAR,
            payload: responseJson.data.data,
            count: responseJson.data.totalOldCars,
          });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: ALLTESTDRIVE, payload: [], count: 0 });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function deleteOldCar(token, user_id, id, page, pageSize) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "oldcar/delete", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        id,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(
            viewAllOldCar(token, user_id, "", page, pageSize, "", "", "")
          );
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(
            viewAllOldCar(token, user_id, "", page, pageSize, "", "", "")
          );
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function updateOldCarStatus(token, user_id, id, status, page, pageSize) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "oldcar/updateStatus", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        id,
        status,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(
            viewAllOldCar(token, user_id, "", page, pageSize, "", "", "")
          );
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(
            viewAllOldCar(token, user_id, "", page, pageSize, "", "", "")
          );
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
//------------------------------Edit Page--------------------------------------------------------------//
export function viewOldCarById(token, user_id, id) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "oldcar/viewOldCarById", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        user_id,
        oldcar_id: id,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({
            type: VIEWOLDCARBYID,
            payload: responseJson.data.result,
          });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: VIEWOLDCARBYID, payload: [] });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function viewOverviewById(token, user_id, id) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "overview/viewOverviewById", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        user_id,
        oldcar_id: id,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({
            type: VIEWOVERVIEWBYID,
            payload: responseJson.data.result,
          });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: VIEWOVERVIEWBYID, payload: [] });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function viewSpecificationById(token, user_id, id) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "specification/viewSpecificationById", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        user_id,
        oldcar_id: id,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({
            type: VIEWSPECIFICATIONBYID,
            payload: responseJson.data.result,
          });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: VIEWSPECIFICATIONBYID, payload: [] });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function viewFeturesById(token, user_id, id) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "fetures/viewFeturesById", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        user_id,
        oldcar_id: id,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({
            type: VIEWFETURESBYID,
            payload: responseJson.data.result,
          });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: VIEWFETURESBYID, payload: [] });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
//-----------------------------------Edit Pages -----------------------------------------------------------//
export function editCarFeature(
  token,
  user_id,
  id,
  airbag,
  tyrePresssure,
  childSeatAnchorPoints,
  seatBeltWarning,
  // des1,
  abs,
  ebd,
  ba,
  esp,
  fourWheel,
  hillHoldControl,
  tcs,
  // rideHeightAdjustment,
  hillDescentControl,
  // des2,
  engineImmobiliser,
  centeralLocking,
  speedSensingDoorLock,
  childSafetyLock,
  ac,
  frontac,
  rearac,
  heater,
  vanityMirror,
  antiglareMirror,
  parkingAssist,
  parkingSensor,
  cruisecontrol,
  headlightIgnition,
  keylessstart,
  steeringAdjustment,
  power,
  // des3,
  applecarplay,
  androidauto,
  // des4,
  seatUpholestry,
  drivesideAdjust,
  leatherWrapped,
  driverArmrest,
  // rearPassengerSeatType,
  // interiors,
  // interiorsColor,
  foldingRearSeat,
  splitRearSeat,
  splitThirdRowSeat,
  frontSeatbackPockets,
  headrests,
  // des5,
  cupholders,
  driverArmrestStorage,
  cooledGloveBox,
  sunGlassHolder,
  thirdRowCupHolder,
  // des6,
  orvm,
  powerWindow,
  oneTouchDown,
  oneTouchUp,
  adjustableOrvm,
  exteriorDoorHandle,
  chromeexteriorDoorHandle,
  doorPockets,
  bootlidOpener,
  // des7,
  sunroof,
  roofMountedAntenna,
  bodyColouredBumpers,
  chromeFinish,
  // des8,
  projectorheadlight,
  autoheadlamps,
  homeheadlamps,
  drl,
  tailights,
  cabinLamps,
  lightonVanityMirror,
  rearreadinglamp,
  gloveBoxlamp,
  headlightHeightAdjuster,
  // des9,
  // instantaneousConsumption,
  // instrumentCluster,
  tripMeter,
  averageFuelConsumption,
  // averageSpeed,
  distancetoEmpty,
  clock,
  lowFuelWarning,
  doorAjarWarning,
  adjustableCluster,
  gearIndicator,
  shiftIndicator,
  tachometer,
  // des10,
  // display,
  musicSystem,
  speaker,
  steeringmountedctrls,
  voiceCommand,
  gps,
  bluetooth,
  aux,
  radio,
  usb,
  headUnitSize,
  // iPod,
  // des11,
  // warrrantyyear,
  // warrrantykm,
  des12
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "fetures/update", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        addedBy: user_id,
        id,
        airbag,
        tyrePresssure,
        childSeatAnchorPoints,
        seatBeltWarning,
        // des1,
        abs,
        ebd,
        ba,
        esp,
        fourWheel,
        hillHoldControl,
        tcs,
        // rideHeightAdjustment,
        hillDescentControl,
        // des2,
        engineImmobiliser,
        centeralLocking,
        speedSensingDoorLock,
        childSafetyLock,
        ac,
        frontac,
        rearac,
        heater,
        vanityMirror,
        antiglareMirror,
        parkingAssist,
        parkingSensor,
        cruisecontrol,
        headlightIgnition,
        keylessstart,
        steeringAdjustment,
        power,
        // des3,
        applecarplay,
        androidauto,
        // des4,
        seatUpholestry,
        drivesideAdjust,
        leatherWrapped,
        driverArmrest,
        // rearPassengerSeatType,
        // interiors,
        // interiorsColor,
        foldingRearSeat,
        splitRearSeat,
        splitThirdRowSeat,
        frontSeatbackPockets,
        headrests,
        // des5,
        cupholders,
        driverArmrestStorage,
        cooledGloveBox,
        sunGlassHolder,
        thirdRowCupHolder,
        // des6,
        orvm,
        powerWindow,
        oneTouchDown,
        oneTouchUp,
        adjustableOrvm,
        exteriorDoorHandle,
        chromeexteriorDoorHandle,
        doorPockets,
        bootlidOpener,
        // des7,
        sunroof,
        roofMountedAntenna,
        bodyColouredBumpers,
        chromeFinish,
        // des8,
        projectorheadlight,
        autoheadlamps,
        homeheadlamps,
        drl,
        tailights,
        cabinLamps,
        lightonVanityMirror,
        rearreadinglamp,
        gloveBoxlamp,
        headlightHeightAdjuster,
        // des9,
        // instantaneousConsumption,
        // instrumentCluster,
        tripMeter,
        averageFuelConsumption,
        // averageSpeed,
        distancetoEmpty,
        clock,
        lowFuelWarning,
        doorAjarWarning,
        adjustableCluster,
        gearIndicator,
        shiftIndicator,
        tachometer,
        // des10,
        // display,
        musicSystem,
        speaker,
        steeringmountedctrls,
        voiceCommand,
        gps,
        bluetooth,
        aux,
        radio,
        usb,
        headUnitSize,
        // iPod,
        // des11,
        // warrrantyyear,
        // warrrantykm,
        des12,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          // dispatch(setcarId(oldCar_id));
          dispatch(unsetLoader());
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          // dispatch(setcarId(oldCar_id));
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function editCarOverview(
  token,
  user_id,
  id,
  registerationYear,
  manufacturingYear,
  owners,
  transmission,
  color,
  insurance,
  registerationType,
  description
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "overview/update", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        addedBy: user_id,

        id,
        registerationYear,
        manufacturingYear,
        owners,
        transmission,
        color,
        insurance,
        registerationType,
        description,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          // dispatch(setcarId(oldCar_id));
          dispatch(unsetLoader());
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          // dispatch(setcarId(oldCar_id));
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function editCarSpecification(
  token,
  user_id,
  id,
  engine,
  // engineType,
  maxPower,
  maxTorque,
  mileage,
  driveTrain,
  // turbocharger,
  length,
  width,
  height,
  wheelbase,
  groundClearance,
  kerbWeight,
  doors,
  seatingCapacity,
  rows,
  bootspace,
  fuelTankCapacity,
  frontSuspension,
  rearSuspension,
  frontbrakeType,
  rearBrakeType,
  minTurningRadius,
  steeringType,
  wheels,
  spareWheel,
  frontTyres,
  rearTyres
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "specification/update", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        addedBy: user_id,
        id,
        engine,
        // engineType,
        maxPower,
        maxTorque,
        mileage,
        driveTrain,
        // turbocharger,
        length,
        width,
        height,
        wheelbase,
        groundClearance,
        kerbWeight,
        doors,
        seatingCapacity,
        rows,
        bootspace,
        fuelTankCapacity,
        frontSuspension,
        rearSuspension,
        frontbrakeType,
        rearBrakeType,
        minTurningRadius,
        steeringType,
        wheels,
        spareWheel,
        frontTyres,
        rearTyres,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          // dispatch(setcarId(oldCar_id));
          dispatch(unsetLoader());
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          // dispatch(setcarId(oldCar_id));
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function editCarinfo(
  token,
  user_id,
  id,
  brand,
  carName,
  fuelType,
  carType,
  price,
  discountedPrice,
  kilometer,
  images,
  oldImages
) {
  return (dispatch) => {
    dispatch(setLoader(true));

    if (images.length === 0) {
      // If images array is empty, call editOldCars directly without uploading
      dispatch(
        editOldCars(
          token,
          user_id,
          id,
          brand,
          carName,
          fuelType,
          carType,
          price,
          discountedPrice,
          kilometer,
          oldImages // Assuming oldImages contains the URLs of previously uploaded images
        )
      );
      dispatch(set_snack_bar(true, "No new images to upload"));
      dispatch(setLoader(false));
      return;
    }

    const imageUrls = []; // Array to store uploaded image URLs

    // Extract File objects from the images array
    const extractedImages = images.map(
      (imageObj) => Object.values(imageObj)[0]
    );

    // Create a promise for each image upload
    const uploadPromises = extractedImages.map((image) => {
      return new Promise((resolve, reject) => {
        if (image instanceof File) {
          const uniqueImageName = `/oldCar/${image.name}_${Date.now()}.png`;
          const storageRef = ref(getStorage(), uniqueImageName);
          const uploadTask = uploadBytesResumable(storageRef, image);

          // Handle image upload
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log(`Upload is ${progress}% done for ${image.name}`);
            },
            (error) => {
              console.error(`Error uploading ${image.name}:`, error);
              reject(error);
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref)
                .then((url) => {
                  imageUrls.push(url);
                  resolve();
                })
                .catch((error) => {
                  console.error(
                    `Error getting download URL for ${image.name}:`,
                    error
                  );
                  reject(error);
                });
            }
          );
        } else {
          resolve();
        }
      });
    });

    // Wait for all images to finish uploading
    Promise.all(uploadPromises)
      .then(() => {
        dispatch(
          editOldCars(
            token,
            user_id,
            id,
            brand,
            carName,
            fuelType,
            carType,
            price,
            discountedPrice,
            kilometer,
            imageUrls
          )
        );
        dispatch(set_snack_bar(true, "Images Uploaded Successfully"));
      })
      .catch((error) => {
        console.error("Error uploading images:", error);
        dispatch(set_snack_bar(true, "Failed to upload one or more images"));
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };
}

export function editOldCars(
  token,
  user_id,
  id,
  brand,
  carName,
  fuelType,
  carType,
  price,
  discountedPrice,
  kilometer,
  images
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "oldcar/update", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        addedBy: user_id,
        id,
        brand,
        carName,
        fuelType,
        carType,
        price,
        discountedPrice,
        kilometer,
        image: images,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          // dispatch(setcarId(oldCar_id));
          dispatch(unsetLoader());
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          // dispatch(setcarId(oldCar_id));
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
//-------------------------------------------Dashboard--------------------------------------------------------//
export function viewContactUsLength(token, user_id) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "contactus/viewContactUsLength", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        user_id,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({
            type: VIEW_CONTACT_US_LENGTH,
            payload: responseJson.data, // Updated to access responseJson.data directly
          });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: VIEW_CONTACT_US_LENGTH, payload: [] });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function viewTestDriveLength(token, user_id) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "testDrive/viewTestDriveLength", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        user_id,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({
            type: VIEW_TESTDRIVE_LENGTH,
            payload: responseJson.data,
          });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: VIEW_TESTDRIVE_LENGTH, payload: [] });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function viewOldCarLength(token, user_id) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "oldcar/viewOldCarLength", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        user_id,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({
            type: OLDCARLENGTH,
            payload: responseJson.data.totalLength,
          });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: OLDCARLENGTH, payload: 0 });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

//--------------------------CAR PAGE Show interest form---------------------------
export function kepInTouch(
  firstName,
  lastName,
  mobile,
  email,
  pincode,
  city,
  dealer,
  consentChecked,
  carType
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "vehicleEnq/register", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName,
        lastName,
        mobile,
        email,
        pincode,
        city,
        dealer,
        consentChecked,
        carType,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch();
          dispatch(unsetLoader());
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch();
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

export function viewAllVehicleEnq(
  token,
  user_id,
  searchInput,
  page,
  pageSize,
  startDate,
  endDate,
  selectedStatus
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "vehicleEnq/view", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        searchTerm: searchInput,
        page: parseInt(page) + 1,
        pageSize,
        startDate,
        endDate,
        selectedStatus,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({
            type: ALLVEHICLEENQ,
            payload: responseJson.data.data,
            count: responseJson.data.vehicleEnqDetail,
          });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: ALLVEHICLEENQ, payload: [], count: 0 });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function deleteVehicleEnq(token, user_id, id, page, pageSize) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "vehicleEnq/delete", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        id,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(
            viewAllVehicleEnq(token, user_id, "", page, pageSize, "", "", "")
          );
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(
            viewAllVehicleEnq(token, user_id, "", page, pageSize, "", "", "")
          );
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function updateVehicleEnqStatus(
  token,
  user_id,
  id,
  status,
  page,
  pageSize
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "vehicleEnq/updateStatus", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        id,
        status,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(
            viewAllVehicleEnq(token, user_id, "", page, pageSize, "", "", "")
          );
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(
            viewAllVehicleEnq(token, user_id, "", page, pageSize, "", "", "")
          );
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

//--------------------------CONTACT FORM---------------------------
export function AddContactForm1(firstName, lastName, email, mobile, address) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "contactus/register", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName,
        lastName,
        email,
        mobile,
        queryType: address,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch();
          dispatch(unsetLoader());
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch();
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

//--------------------------Enquiry Form---------------------------
export function AddEnquiryForm(name, mobile, email, city, model) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "enquiry/register", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        mobile,
        email,
        city,
        model,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch();
          dispatch(unsetLoader());
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch();
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

export function viewAllEnquiry(
  token,
  user_id,
  searchInput,
  page,
  pageSize,
  startDate,
  endDate,
  selectedStatus
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "enquiry/view", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        searchTerm: searchInput,
        page: parseInt(page) + 1,
        pageSize,
        startDate,
        endDate,
        selectedStatus,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({
            type: ALLENQUIRY,
            payload: responseJson.data.data,
            count: responseJson.data.vehicleEnqDetail,
          });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: ALLENQUIRY, payload: [], count: 0 });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function deleteEnquiry(token, user_id, id, page, pageSize) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "enquiry/delete", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        id,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(
            viewAllEnquiry(token, user_id, "", page, pageSize, "", "", "")
          );
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(
            viewAllEnquiry(token, user_id, "", page, pageSize, "", "", "")
          );
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function updateEnquiryStatus(
  token,
  user_id,
  id,
  status,
  page,
  pageSize
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "enquiry/updateStatus", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        id,
        status,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(
            viewAllEnquiry(token, user_id, "", page, pageSize, "", "", "")
          );
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(
            viewAllEnquiry(token, user_id, "", page, pageSize, "", "", "")
          );
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

//------------------------Service-------------------------------------------
export function AddServiceForm(
  name,
  email,
  mobile,
  model,
  carRegisterationNumber,
  prefferedServiceCenterLocation,
  preferredDateforService
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "service/register", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        email,
        mobile,
        model,
        carRegisterationNumber,
        prefferedServiceCenterLocation,
        preferredDateforService,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch();
          dispatch(unsetLoader());
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch();
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

export function viewAllService(
  token,
  user_id,
  searchInput,
  page,
  pageSize,
  startDate,
  endDate,
  selectedStatus
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "service/view", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        searchTerm: searchInput,
        page: parseInt(page) + 1,
        pageSize,
        startDate,
        endDate,
        selectedStatus,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({
            type: ALLSERVICE,
            payload: responseJson.data.data,
            count: responseJson.data.serviceDetail,
          });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: ALLSERVICE, payload: [], count: 0 });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function deleteService(token, user_id, id, page, pageSize) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "service/delete", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        id,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(
            viewAllService(token, user_id, "", page, pageSize, "", "", "")
          );
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(
            viewAllService(token, user_id, "", page, pageSize, "", "", "")
          );
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function updateServiceStatus(
  token,
  user_id,
  id,
  status,
  page,
  pageSize
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "service/updateStatus", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        id,
        status,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(
            viewAllService(token, user_id, "", page, pageSize, "", "", "")
          );
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(
            viewAllService(token, user_id, "", page, pageSize, "", "", "")
          );
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

//---------------------------Reviews------------------------------------------------------------------//
export function fetchReviews() {
  return async (dispatch) => {
    dispatch(setLoader());

    try {
      const response = await fetch(
        UNIVERSAL.BASEURL + "vehicleEnq/viewGoogleReview",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      const responseJson = await response.json();

      if (response.ok) {
        dispatch({
          type: ALLREVIEWS,
          payload: responseJson.data || [],
          count: responseJson.data?.length || 0,
        });
      } else {
        console.log("Data Not Found");
        dispatch({ type: ALLREVIEWS, payload: [], count: 0 });
      }
    } catch (error) {
      console.error("Error fetching reviews:", error);
    } finally {
      dispatch(unsetLoader());
    }
  };
}

// ---------------------------------------------GetQuatations--------------------------------------------------------
export function getQuotationsRequest(
  carName,
  firstName,
  lastName,
  email,
  mobile,
  city,
  dealership,
  pincode
) {
  return (dispatch) => {
   

    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "quatations/register", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        carName,
        firstName,
        lastName,
        email,
        mobile,
        city,
        dealership,
        pincode,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {

          dispatch();
          dispatch(unsetLoader());
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch();
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function viewAllQuatation(
  token,
  user_id,
  searchInput,
  page,
  pageSize,
  startDate,
  endDate,
  selectedStatus
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "quatations/view", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        searchTerm: searchInput,
        page: parseInt(page) + 1,
        pageSize,
        startDate,
        endDate,
        selectedStatus,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({
            type: ALLQUATATION,
            payload: responseJson.data.data,
            count: responseJson.data.quatationsDetails,
          });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: ALLQUATATION, payload: [], count: 0 });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function deleteQuatation(token, user_id, id, page, pageSize) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "quatations/delete", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        id,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(
            viewAllEnquiry(token, user_id, "", page, pageSize, "", "", "")
          );
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(
            viewAllEnquiry(token, user_id, "", page, pageSize, "", "", "")
          );
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function updateQuatationStatus(
  token,
  user_id,
  id,
  status,
  page,
  pageSize
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "quatations/updateStatus", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        id,
        status,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(
            viewAllEnquiry(token, user_id, "", page, pageSize, "", "", "")
          );
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(
            viewAllEnquiry(token, user_id, "", page, pageSize, "", "", "")
          );
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
