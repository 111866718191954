import React from "react";
import {
  Button,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import Seltos from "../../Brochure/Seltos/Seltos.pdf";
import Sonet from "../../Brochure/Sonet/Sonet.pdf";
import Carens from "../../Brochure/Carens/Carens.pdf";
import EV6 from "../../Brochure/EV6/Kia-EV6_Desktop.pdf";
import Carnival from "../../Brochure/Carnival/Kia_Carnival_Brochure_Desktop.pdf";
import EV9 from "../../Brochure/Ev9/Ev9.pdf";
import Syros from "../../Brochure/Syros/Kia_Syros_2025_Desktop.pdf";

const cars = [
  {
    name: "Kia Seltos",
    img: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fseltos1.jpg?alt=media&token=32bf45bf-6c39-4a46-8a36-dbe71e5cf654",
    brochure: Seltos,
    downloadName: "Kia_Seltos_Brochure.pdf",
  },
  {
    name: "Kia Sonet",
    img: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fimage1.jpg?alt=media&token=2010886f-2c60-4f64-b35c-eef15196d6f5",
    brochure: Sonet,
    downloadName: "Kia_Sonet_Brochure.pdf",
  },
  {
    name: "Kia Carens",
    img: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fcarensfullimage.jpg?alt=media&token=ca83e291-7a9e-4b88-9526-d343af42ee9d",
    brochure: Carens,
    downloadName: "Kia_Carens_Brochure.pdf",
  },
  {
    name: "Kia EV6",
    img: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV6%2Fevcard.jpg?alt=media&token=15c32ff8-77d5-42b9-8bba-4c7a97bad368",
    brochure: EV6,
    downloadName: "Kia_EV6_Brochure.pdf",
  },
  {
    name: "Kia Carnival",
    img: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carnival%2Fimage2.jpg?alt=media&token=df2dd516-7b80-4b1d-9522-681c8138669a",
    brochure: Carnival,
    downloadName: "Kia_Carnival_Brochure.pdf",
  },
  {
    name: "Kia EV9",
    img: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV9%2Ffull5.jpg?alt=media&token=9bcf13f9-0f2e-4baf-bebc-9470ca05833b",
    brochure: EV9,
    downloadName: "Kia_EV9_Brochure.pdf",
  },
  {
    name: "Kia SYROS",
    img: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FSafety_kv.png?alt=media&token=93fbec69-2efc-4702-bab7-fcc75edeeddf",
    brochure: Syros,
    downloadName: "Kia_Syros_2025_Desktop.pdf",
  },
];

const BrochurePage = () => {
  return (
    <Box textAlign="center" sx={{ p: 4, marginTop: "90px" }}>
      <Typography
        variant="h4"
        sx={{
          fontFamily: "KIAbodyLatinbasic_Bold",
          color: "#000",
          mb: 4,
        }}>
        KIA BROCHURES
      </Typography>

      <Grid container spacing={3} justifyContent="center">
        {cars.map((car, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ maxWidth: 345, mx: "auto" }}>
              <CardMedia
                component="img" loading="eager" decoding="async"
                height="200"
                image={car.img}
                alt={car.name}
              />
              <CardContent>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "KIAbodyLatinbasic_Bold",
                    color: "#91999D",
                    mb: 2,
                    textTransform: "uppercase",
                    fontWeight: "bolder",
                  }}>
                  {car.name}
                </Typography>
                <Button
                  variant="contained"
                  color="error"
                  href={car.brochure}
                  download={car.downloadName}
                  sx={{
                    fontWeight: "800",
                    fontSize: "12px",
                    FONTfAMILY: "KIAbodyLatinbasic_Bold",
                    backgroundColor: "#545454",
                    "&:hover": {
                      backgroundColor: "#545454",
                    },
                  }}>
                  Download Brochure
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default BrochurePage;
