import React from "react";
import Wsp from "../../../contact/Component/Floatingwsp";
import Banner from "../../../../Common/Components/Banner";
import InterestDealer from "../../../../Common/Components/InterestDealer";
import Intro from "../../../../Common/Components/Intro";
import ShowInterest from "../../../show interest/Container/showinterestCon";
import CarouselPart from "../../../../Common/Components/CarouselPart";
import MeetTheFamily from "../../../../Common/Components/MeetTheFamily";
import DetailedInfo from "../../../../Common/Components/DetailedInfo";
import Faq from "../../../../Common/Components/Faq";
import CarStepper from "../../../../Common/Components/CarStepper";
import CarModel from "../../../../Common/Components/CarModel";
import {
  BannerData,
  CarouselData,
  CircleData,
  DetailInfo,
  FaqData,
  IntroData,
  StepData,
  TrimData,
} from "./Data";
import { Grid, Typography } from "@mui/material";

const Syros = () => {
  return (
    <>
      <Banner bannerData={BannerData} />
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={11} py={1}>
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{
              fontFamily: "KIAbodyLatinbasic_Regular",
              textAlign: "center",
              display: "block",
            }}
          >
            Disclaimer: The product shown in the visual is for representation
            purposes only. Color, feature, and actual product may vary. Please
            check with your local Kia dealer for more details.
          </Typography>
        </Grid>
      </Grid>
      <InterestDealer />
      <Intro data={IntroData} />
      <CarModel
        model="Syros"
        bgCar={
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FInt-3.mp4?alt=media&token=d3520979-35c7-426c-a291-1e985e210d33"
        }
        CircleData={CircleData}
      />
      <CarStepper
        StepData={StepData}
        carLink={
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2Fsyros-explore-video.mp4?alt=media&token=d39720f7-d5a1-444e-ac6c-dbb15d8f2da3"
        }
        stepTimings={[2, 3.5, 6.0, 9.0, 15.0, 19.0]}
      />
      <ShowInterest someValue="Syros" />
      {CarouselData.map((item) => (
        <CarouselPart data={item} />
      ))}
      <MeetTheFamily TrimData={TrimData} data={CircleData} />
      <DetailedInfo DetailInfo={DetailInfo} />
      <Faq FaqData={FaqData} />
      <Wsp />
    </>
  );
};

export default Syros;
