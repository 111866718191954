import React, { useRef, useEffect, useState } from "react";
import { Box, Grid, IconButton, Skeleton, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { ArrowLeft, ArrowRight } from "iconsax-react";
import { useMediaQuery, useTheme } from "@mui/material";
export default function Caro({ trim }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    if (swiperRef.current && swiperRef.current.navigation) {
      setTimeout(() => {
        swiperRef.current.params.navigation.prevEl = prevRef.current;
        swiperRef.current.params.navigation.nextEl = nextRef.current;
        swiperRef.current.navigation.init();
        swiperRef.current.navigation.update();
      });
    }
  }, []);

  return (
    <Grid
      container
      justifyContent="center"
      sx={{ position: "relative", margin: "auto" }}
    >
      {/* Left Arrow */}
      <IconButton
        ref={prevRef}
        sx={{
          position: "absolute",
          left: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 10,
          color: "#FFF",
          padding: { xs: "10px", sm: "20px", md: "30px" },
          backgroundColor:
            activeIndex > 0 ? "rgba(0, 0, 0, 0.2)" : "transparent",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 1)",
          },
          display: activeIndex === 0 ? "none" : "flex",
        }}
      >
        <ArrowLeft size={25} />
      </IconButton>

      {/* Swiper Component */}
      <Swiper
        modules={[Navigation]}
        spaceBetween={20}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
        slidesPerView={1}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        style={{ width: "100%", height: { xs: "70vh", sm: "100vh" } }}
      >
        {trim.map((item, index) => (
          <SwiperSlide key={index}>
            <Box
              position="relative"
              width="100%"
              sx={{
                height: { xs: "70vh", sm: "100vh" },
              }}
            >
              {!isLoaded && (
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width="100%"
                  height="100%"
                  sx={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              )}
              {/* Background Image */}
              {item.cover.includes(".mp4") ? (
                <Box
                  component="video"
                  src={
                    isMobile && item?.mobileCover
                      ? item.mobileCover
                      : item.cover
                  }
                  autoPlay
                  loop
                  preload="auto"
                  muted={true}
                  onCanPlayThrough={() => setIsLoaded(true)}
                  sx={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <Box
                  component="img"
                  loading="eager"
                  decoding="async"
                  src={
                    isMobile && item?.mobileCover
                      ? item.mobileCover
                      : item.cover
                  }
                  onLoad={() => setIsLoaded(true)}
                  alt="Banner Image"
                  sx={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              )}

              {/* Text Overlay */}
              <Grid
                container
                position="absolute"
                top="35%"
                sx={{
                  width: "100%",
                  textAlign: "center",
                  color: "white",
                  zIndex: 2,
                  padding: { xs: "10px", sm: "20px" },
                }}
              >
                <Grid item xs={12} sm={10} md={5}>
                  {trim.small && (
                    <Box
                      component="img"
                      loading="eager"
                      decoding="async"
                      width="100px"
                      height="100px"
                      src={trim.small}
                      sx={{ mb: 2 }}
                    />
                  )}
                  {trim.title && (
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "KIAbodyLatinbasic_Bold",
                        lineHeight: 1.5,
                        color: "#FFF",
                      }}
                    >
                      {trim.title}
                    </Typography>
                  )}
                  {trim.desc1 && (
                    <Typography variant="body1" sx={{ color: "#fff", mt: 1 }}>
                      {trim.desc1}
                    </Typography>
                  )}
                  {trim.desc2 && (
                    <Typography variant="body1" sx={{ color: "#fff", mt: 1 }}>
                      {trim.desc2}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Right Arrow */}
      <IconButton
        ref={nextRef}
        sx={{
          position: "absolute",
          right: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 10,
          color: "#FFF",
          padding: { xs: "10px", sm: "20px", md: "30px" },
          backgroundColor:
            activeIndex < trim.length - 1
              ? "rgba(0, 0, 0, 0.2)"
              : "transparent",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 1)",
          },
          display: activeIndex === trim.length - 1 ? "none" : "flex",
        }}
      >
        <ArrowRight size={25} />
      </IconButton>
    </Grid>
  );
}
