import React from 'react';
import Slider from 'react-slick';
import { Box, Card, CardContent, CardMedia, Typography, IconButton, Grid } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// Custom next arrow component
const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      sx={{
        position: 'absolute',
        right: '-5px', 
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 2,
        color: 'black',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        '&:hover': { backgroundColor: 'rgba(255, 255, 255, 1)' },
      }}
    >
      <ArrowForwardIosIcon />
    </IconButton>
  );
};

// Custom previous arrow component
const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      sx={{
        position: 'absolute',
        left: '-5px', 
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 2,
        color: 'black',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        '&:hover': { backgroundColor: 'rgba(255, 255, 255, 1)' },
      }}
    >
      <ArrowBackIosIcon />
    </IconButton>
  );
};

// CarouselSection component
const CarouselSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const cards = [
    {
      image: 'https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV9%2Fevcard9.jpg?alt=media&token=216116c6-f16e-4bad-91c4-fbdc8968a930',
      title: '2nd Row Captain Seats with 8-Way Power Adjust and Massage Function',
      description:
        'Indulge in first-class comfort with 8-way power-adjustable captain seats, complete with a soothing massage function. Your second-row passengers can enjoy personalized seating adjustments for ultimate relaxation on every journey.',
    },
    {
      image: 'https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV9%2Fevcard10.jpg?alt=media&token=ffc0a34d-d2b4-4e45-9e22-eb6e27bd4894',
      title: 'Independently Controlled 3-zone Fully Automatic Temperature Control',
      description:
        'Achieve perfect comfort for every passenger with 3-zone automatic temperature control. Each zone operates independently, allowing personalized climate settings for the driver, front passenger, and rear occupants for a tailored in-car experience.',
    },
    {
      image: 'https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV9%2Fevcard11.jpg?alt=media&token=76c9eb7d-c565-44a1-897f-2f24ee79ef08',
      title: '2nd Row One Touch Fold Seat',
      description:
        'Maximize space effortlessly with the 2nd row’s one-touch fold feature. Whether you need extra room for cargo or passengers, this convenient function allows quick and easy seat adjustments for flexible interior configurations.',
    },
    {
        image:'https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV9%2Fevcard12.jpg?alt=media&token=a7adb7c4-69c1-4445-9b4a-f9cccf7eaba5',
        title:'Frunk*(521)',
        description:'The Kia EV9 offers a convenient Frunk (Front Trunk) with 52 liters (AWD) or 90 Liters (RWD) of additional storage space',
    },
    {
        image:'https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV9%2Fevcard13.jpg?alt=media&token=61b4061e-165c-4868-9df1-c78e1f527557',
        title:'Spacious Boot* (3331)',
        description:"With a generously spacios boot, the Kia EV9 offers ample room for all your gear, ensuring you're ready for any adventure, big or small.",
    },{
        image:'https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV9%2Fevcard14.jpg?alt=media&token=f6c73150-f778-4ced-bac3-f68f334566f9',
        title:'Extendable centeral Console Tray with Under storage',
        description:'Stay organized with the extendable central console tray, offering added convenience and hidden storage. Perfect for keeping your essentials within reach while maintaining and clean and unclutterd cabin.',
    },
  ];

  return (
    <Box
      sx={{
        width: '90%',
        margin: 'auto',
        marginTop: '40px',
        marginBottom: '40px',
        overflow: 'hidden',
      }}
    >
      <Slider {...settings}>
        {cards.map((card, index) => (
          <Grid key={index} item xs={12} md={4} sx={{ padding: '0 15px' }}>
            <Card
              sx={{
                padding: 2,
                boxShadow: 3,
                borderRadius: '8px',
                backgroundColor: '#fff',
                textAlign: 'center',
                height: '100%',
                '&:hover': {
                  boxShadow: 6,
                },
              }}
            >
              <CardMedia
                component="img" loading="eager" decoding="async"
                height="180"
                image={card.image}
                alt={card.title}
                sx={{
                  objectFit: 'cover',
                  borderRadius: '8px 8px 0 0',
                }}
              />
              <CardContent sx={{ padding: '20px', height: '200px' }}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    fontWeight: 'bold',
                    color: '#333',
                    fontSize: '18px',
                    marginBottom: '8px',
                    fontFamily:"KIAbodyLatinbasic_Bold"
                  }}
                >
                  {card.title}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    color: '#666',
                    fontSize: '14px',
                    fontFamily:"KIAbodyLatinbasic_Regular"
                  }}
                >
                  {card.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Slider>
    </Box>
  );
};

export default CarouselSection;
