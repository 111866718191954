import React, { useState } from "react";
import { Box, Grid, Skeleton, Typography } from "@mui/material";

export default function Banner2({ bannerData }) {
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box
          position="relative"
          width="100%"
          sx={{
            height: { xs: "80vh", sm: "100vh", md: "150vh" },
          }}
        >
          {!isLoaded && (
            <Skeleton
              animation="wave"
              variant="rectangular"
              width="100%"
              height="100%"
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          )}
          {/* Banner Image with Fade-in Animation */}
          <Box
            component="img"
            loading="eager"
            decoding="async"
            onLoad={() => setIsLoaded(true)}
            src={bannerData.imgSrc}
            alt="Kia Sonet Banner"
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              opacity: 0,
              animation: "fadeIn 1.5s ease-in-out forwards",
              "@keyframes fadeIn": {
                "0%": { opacity: 0 },
                "100%": { opacity: 1 },
              },
            }}
          />

          {/* Bottom Text Overlay */}
          <Grid
            container
            position="absolute"
            bottom="5%"
            sx={{
              color: "white",
              px: { xs: 2, sm: 4, md: 8 },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {bannerData.data.map((item, index) => (
              <Grid
                key={index}
                item
                xs={12}
                sm={12}
                md={4}
                textAlign="center"
                borderRight={
                  index !== bannerData.data.length - 1
                    ? "1px solid rgba(255, 255, 255, 0.5)"
                    : "none"
                }
              >
                <Typography variant="h6" fontWeight={600}>
                  {item.title}
                </Typography>
                <Typography>{item.description}</Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
