import { Grid, Typography } from "@mui/material";
import React from "react";
import TrimCarousel from "./TrimCarousel";

export default function MeetTheFamily({TrimData, data}) {
  return (
    <Grid
      container
      py={"2.5%"}
      px={"4.5%"}
      overflow={"hidden"}
      backgroundColor={"#eff1f2"}
    >
      <Grid item xs={12} textAlign="center">
        <Typography
          fontFamily="KIAbodyLatinbasic_Bold"
          lineHeight={1.6}
          sx={{ fontSize: "clamp(24px, 4vw, 36px)" }}
        >
          Meet the Family
        </Typography>
        <Typography
          fontFamily="KIAbodyLatinbasic_Regular"
          lineHeight={1.8}
          sx={{
            fontSize: "clamp(12px, 1.8vw, 16px)",
          }}
        >
          5 Trim Available
        </Typography>
      </Grid>
      <Grid item xs={12} mt={3}>
        <TrimCarousel trim={TrimData} CircleData={data} />
      </Grid>
    </Grid>
  );
}
