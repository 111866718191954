import {
  ALLOLDCARS,
  VIEWOVERVIEWBYOLDCAR,
  VIEWOLDCARBYID,
  VIEWSPECIFICATIONBYOLDCAR,
  VIEWFETURESBYOLDCARID,
  VIEWCARBYBRAND,
} from "./constant";
import UNIVERSAL from "../../Config/Config";
import { setLoader, unsetLoader } from "../../Common/loader/action";
import "firebase/storage";

export function viewAllOldCar(
  budget,
  fuels,
  transmissions,
  owners,
  query,
  brand
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "oldcar/viewAllOldCar", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        budget,
        fuels,
        transmissions,
        owners,
        query,
        brand,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({
            type: ALLOLDCARS,
            payload: responseJson.data,
            count: responseJson.data.length,
          });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: ALLOLDCARS, payload: [], count: 0 });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function viewOverviewByOldCarId(oldcar_id) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "overview/viewOverviewById", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        oldcar_id,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({
            type: VIEWOVERVIEWBYOLDCAR,
            payload: responseJson.data.result,
          });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: VIEWOVERVIEWBYOLDCAR, payload: [] });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function viewOldCarById(oldcar_id) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "oldcar/viewOldCarById", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        oldcar_id,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({
            type: VIEWOLDCARBYID,
            payload: responseJson.data.result,
          });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: VIEWOLDCARBYID, payload: [] });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function viewSpecificationByOldCarId(oldcar_id) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "specification/viewSpecificationById", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        oldcar_id,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({
            type: VIEWSPECIFICATIONBYOLDCAR,
            payload: responseJson.data.result,
          });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: VIEWSPECIFICATIONBYOLDCAR, payload: [] });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function viewFeturesById(oldcar_id) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "fetures/viewFeturesById", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        oldcar_id,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({
            type: VIEWFETURESBYOLDCARID,
            payload: responseJson.data.result,
          });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: VIEWFETURESBYOLDCARID, payload: [] });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function viewCarsByBrand(id, brand) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "oldcar/viewCarsByBrand", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
        brand,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({
            type: VIEWCARBYBRAND,
            payload: responseJson.data.result,
            count: responseJson.data.result.length,
          });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: VIEWCARBYBRAND, payload: [], count: 0 });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
