import { Grid, Box, Skeleton } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const dealer = {
  register:
    "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2Fregister%20intrest.png?alt=media&token=2c5fb2a2-418b-4399-8994-8e590bd17314",
  dealer:
    "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FBrochure%20image.png?alt=media&token=5ce8b8e6-2d25-41f1-b458-69aa2bd51a10",
};

export default function InterestDealer() {
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <Grid container justifyContent="center" borderBottom="1px solid #000">
      {/* Registration & Dealer Section */}
      <Grid item xs={12}>
        <Grid container>
          {/* Register Image */}
          <Grid
            item
            xs={6}
            borderRight="1px solid #000"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              component="img"
              loading="eager"
              decoding="async"
              src={dealer.register}
              alt="Register"
              sx={{
                width: "175px",
                height: "230px",
                objectFit: "cover",
                maxWidth: "100%",
                cursor: "pointer",
              }}
              onClick={() => {
                const element = document.getElementById();
                if (element) {
                  element.scrollIntoView({ behavior: "smooth" });
                }
              }}
            />
          </Grid>

          {/* Dealer Image */}
          <Grid
            item
            xs={6}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Link to={"/"}>
              {!isLoaded && (
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  sx={{
                    width: "175px",
                    height: "230px",
                    objectFit: "cover",
                    maxWidth: "100%",
                  }}
                />
              )}
              <Box
                component="img"
                loading="eager"
                decoding="async"
                onLoad={() => setIsLoaded(true)}
                src={dealer.dealer}
                alt="Dealer"
                sx={{
                  width: "175px",
                  height: "230px",
                  objectFit: "cover",
                  maxWidth: "100%",
                }}
              />
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
