import React, { useState, useEffect, useRef } from "react";
import { menuItems } from "./Menu"; // Assuming the `menuItems` data is still coming from the `Menu.js` file
import logoImage from "../../Image/kialogo.png";
import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Drawer,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useLocation } from "react-router-dom";
import "./Nav.css";

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { pathname } = useLocation();

  const toggleDrawer = (open) => {
    setDrawerOpen(open);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // MenuItems component logic
  const MenuItems = ({ items, depthLevel }) => {
    const [dropdown, setDropdown] = useState(false);
    const ref = useRef();

    useEffect(() => {
      const handler = (event) => {
        if (dropdown && ref.current && !ref.current.contains(event.target)) {
          setDropdown(false);
        }
      };

      document.addEventListener("mousedown", handler);
      document.addEventListener("touchstart", handler);

      return () => {
        document.removeEventListener("mousedown", handler);
        document.removeEventListener("touchstart", handler);
      };
    }, [dropdown]);

    const onMouseEnter = () => {
      if (window.innerWidth > 960) {
        setDropdown(true);
      }
    };

    const onMouseLeave = () => {
      if (window.innerWidth > 960) {
        setDropdown(false);
      }
    };

    return (
      <li
        className={`menu-items ${
          items.title === "Enquiry" ? "enquiry-item" : ""
        }`}
        ref={ref}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={
          items.title === "Enquiry" ? { color: "black" } : { color: "white" }
        }>
        {items.submenu ? (
          <>
            <button
              type="button"
              aria-haspopup="menu"
              aria-expanded={dropdown ? "true" : "false"}
              onClick={() => setDropdown((prev) => !prev)}
              style={
                items.title === "Enquiry"
                  ? { color: "black" }
                  : { color: "white" }
              }>
              {items.title}{" "}
              {depthLevel > 0 ? (
                <span>&raquo;</span>
              ) : (
                <span className="arrow" />
              )}
            </button>
            <ul className={`dropdown ${dropdown ? "show" : ""}`}>
              {items.submenu.map((submenu, index) => (
                <MenuItems
                  items={submenu}
                  key={index}
                  depthLevel={depthLevel + 1}
                  sx={{ fontFamily: "LeagueSpartan-Regular" }}
                />
              ))}
            </ul>
          </>
        ) : (
          <Link
            to={items.path}
            className={`menu-link ${
              items.title === "Enquiry" ? "enquiry-link" : ""
            }`}
            style={
              items.title === "Enquiry"
                ? { color: "black" }
                : { color: "white" }
            }
            onClick={() => setDrawerOpen(false)} // Close drawer when menu item is clicked
          >
            {items.title}
          </Link>
        )}
      </li>
    );
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: "#000000",
        boxShadow: 3,
        marginTop: "34px",
        zIndex: theme.zIndex.drawer + 1,
      }}>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 10px", // Padding for responsiveness
        }}>
        {/* Logo Section */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: isMobile ? "200px" : "270px",
            height: "55px",
          }}>
          <a
            href="/"
            onClick={() => setDrawerOpen(false)}
            style={{ display: "flex" }}>
            <img
              src={logoImage}
              alt="Company Logo"
              width="140"
              height="50"
              style={{ objectFit: "contain" }}
            />
          </a>
        </Box>

        {/* Mobile View: Drawer and Menu Icon */}
        {isMobile ? (
          <>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => setDrawerOpen(!drawerOpen)}
              aria-label="menu">
              {drawerOpen ? (
                <CloseIcon sx={{ color: "#E80165" }} />
              ) : (
                <MenuIcon sx={{ color: "#E80165" }} />
              )}
            </IconButton>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={() => setDrawerOpen(false)}
              PaperProps={{
                sx: {
                  width: "60%",
                  backgroundColor: "black",
                  marginTop: "80px",
                },
              }}>
              <Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <a href="/" onClick={() => setDrawerOpen(false)}>
                    <img
                      src={logoImage}
                      alt="Company Logo"
                      width="150" // Adjust for better SEO
                      height="100"
                      style={{ objectFit: "contain" }}
                    />
                  </a>
                </Box>

                <Divider sx={{ backgroundColor: "grey" }} />
                <Box>
                  <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
                    {menuItems.map((menu, index) => (
                      <React.Fragment key={index}>
                        <MenuItems items={menu} depthLevel={0} />
                        <Divider sx={{ backgroundColor: "grey" }} />
                      </React.Fragment>
                    ))}
                  </ul>
                </Box>
              </Box>
            </Drawer>
          </>
        ) : (
          <>
            {/* Desktop View: Horizontal Menu */}
            <Box sx={{ display: "flex" }}>
              <ul
                style={{
                  display: "flex",
                  listStyle: "none",
                  margin: 0,
                  padding: 0,
                }}>
                {menuItems.map((menu, index) => (
                  <MenuItems items={menu} key={index} depthLevel={0} />
                ))}
              </ul>
            </Box>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
