import {
  Box,
  Button,
  Grid,
  Skeleton,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Check } from "@mui/icons-material";
import { styled } from "@mui/system";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFF",
    color: "rgba(0, 0, 0, 0.5)",
    fontSize: "12px",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    padding: "8px 16px",
    borderRadius: 0,
  },
}));

export default function CarModel({ bgCar, CircleData, model }) {
  const [activeTab, setActiveTab] = useState("Exterior");
  const [selectedColor, setSelectedColor] = useState(CircleData?.[0] || {});
 const [isLoaded, setIsLoaded] = useState(false);
  return (
    <div style={{ position: "relative" }}>
      {/* Header Section */}
      <Grid container pt={"6%"} px={"13%"}>
        <Grid item xs={6}>
          <Typography
            variant="inherit"
            color="gray"
            letterSpacing={3}
            fontFamily="KIAbodyLatinbasic_Regular"
            sx={{
              fontSize: "clamp(14px, 2vw, 16px)",
            }}
          >
            DESIGN
          </Typography>
          <Typography
            variant="h4"
            fontFamily="KIAbodyLatinbasic_Bold"
            lineHeight={2}
            sx={{
              fontSize: "clamp(24px, 5vw, 40px)",
            }}
          >
            {model}
          </Typography>
        </Grid>

        {/* Tab Buttons */}
        <Grid item xs={6}>
          <div style={{ float: "right", display: "flex" }}>
            <Button
              variant="text"
              onClick={() => setActiveTab("Exterior")}
              sx={{
                borderTop:
                  activeTab === "Exterior" ? "3px solid #BB162B" : "none",
                fontWeight: activeTab === "Exterior" ? "bold" : "normal",
                textTransform: "capitalize",
                borderRadius: 0,
                fontSize: "clamp(14px, 2vw, 18px)",
                color: "#000",
              }}
            >
              Exterior
            </Button>
            <Button
              variant="text"
              onClick={() => setActiveTab("Interior")}
              sx={{
                borderTop:
                  activeTab === "Interior" ? "3px solid #BB162B" : "none",
                fontWeight: activeTab === "Interior" ? "bold" : "normal",
                textTransform: "capitalize",
                borderRadius: 0,
                fontSize: "clamp(14px, 2vw, 18px)",
                color: "#000",
              }}
            >
              Interior
            </Button>
          </div>
        </Grid>
      </Grid>

      {/* Content Section */}
      <Grid container display={"flex"} pt={"2%"} pb={"7%"} px={"13%"} gap={1}>
        {/* Exterior Section */}
        {activeTab === "Exterior" && (
          <>
            <Grid item xs={12}>
              {!isLoaded && (
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width="100%"
                  height="100%"
                />
              )}
              <Box
                component="img"
                loading="eager"
                decoding="async"
                src={selectedColor.imgSrc}
                onLoad={() => setIsLoaded(true)}
                alt="Car Image"
                sx={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" fontWeight={400}>
                Color : {selectedColor.title}
              </Typography>
            </Grid>

            {CircleData?.length > 0 &&
              CircleData.map((item) => (
                <Grid
                  item
                  key={item.title}
                  onClick={() => setSelectedColor(item)} // Store full object
                  style={{ cursor: "pointer" }}
                >
                  <CustomTooltip title={item.title}>
                    <Box
                      sx={{
                        width: { xs: 25, sm: 30, md: 35, lg: 40 }, // Responsive width
                        height: { xs: 25, sm: 30, md: 35, lg: 40 }, // Responsive height
                        borderRadius: "50%",
                        border: "1px solid lightgray",
                        background: item.color
                          ? item.color
                          : `linear-gradient(135deg, ${item.color1} 50%, ${item.color2} 50%)`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                      }}
                    >
                      {/* Show tick only if selected */}
                      {selectedColor.title === item.title && (
                        <Check
                          sx={{
                            color: item.color === "#FFF" ? "#000" : "#FFF",
                            fontSize: "17px",
                          }}
                        />
                      )}
                    </Box>
                  </CustomTooltip>
                </Grid>
              ))}
          </>
        )}

        {/* Interior Section (with fixed video positioning) */}
        {activeTab === "Interior" && (
          <Grid
            item
            xs={12}
            sx={{
              position: "relative",
              width: "100%",
              height: "50vh", // Set a fixed height
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <video
              autoPlay
              loop
              muted
              playsInline
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            >
              <source src={bgCar} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
