import React from "react";
import Banner from "../../../../Common/Components/Banner";
import InterestDealer from "../../../../Common/Components/InterestDealer";
import Intro from "../../../../Common/Components/Intro";
import CarouselPart from "../../../../Common/Components/CarouselPart";

import {
  BannerData,
  CarouselData,
  CarouselData1,
  CarouselData2,
  CircleData,
  DetailInfo,
  FaqData,
  IntroData,
  MiddleData,
  StepData,
  TrimData,
} from "./Data";
import MeetTheFamily from "../../../../Common/Components/MeetTheFamily";
import DetailedInfo from "../../../../Common/Components/DetailedInfo";
import Faq from "../../../../Common/Components/Faq";
import CarModel from "../../../../Common/Components/CarModel";
import ShowInterest from "../../../../Common/Components/ShowInterest";
import Wsp from "../../../contact/Component/Floatingwsp";
import VideoFrame from "../../../../Common/Components/VideoFrame";
import { Box, Grid, Typography } from "@mui/material";
import CarStepper from "../../../../Common/Components/CarStepper";

export default function SELTOS() {
  return (
    <>
      <Banner bannerData={BannerData} />
      <InterestDealer />
      <Intro data={IntroData} />
      <CarModel model="Seltos"
        bgCar={
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fint2.mp4?alt=media&token=4111ad1b-e446-42ad-855e-2f81601538a2"
        }
        CircleData={CircleData}
      />
      <CarStepper
        StepData={StepData}
        carLink={
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fexplore_m.mp4?alt=media&token=49a388e4-c037-47e3-9f76-a6fa51ee8fd1"
        }
        stepTimings={[2, 3.5, 6.0, 9.0, 15.0, 19.0]}
      />
      <Grid container>
        <Grid item xs={12}>
          <Box
            component="img" loading="eager" decoding="async"
            src="https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2FKia_HTK.jpg?alt=media&token=20ae5627-5e20-4066-a242-10730d2a5341"
            alt="Image"
            sx={{
              width: "100%",
              height: "auto",
            }}
          />
        </Grid>
      </Grid>
      {CarouselData.map((item) => (
        <CarouselPart data={item} />
      ))}
      <ShowInterest someValue="Seltos" />
      <Grid container justifyContent="center">
        {/* Disclaimer Section */}
        <Grid item xs={12} sm={11} py={1}>
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{
              fontFamily: "KIAbodyLatinbasic_Regular",
              textAlign: "center",
              display: "block",
            }}
          >
            Disclaimer: The product shown in the visual is for representation
            purposes only. Color, feature, and actual and product may vary.
            Please check with your local Kia dealer for more details.
          </Typography>
        </Grid>
      </Grid>
      {CarouselData1.map((item) => (
        <CarouselPart data={item} />
      ))}
      <Intro data={MiddleData} />
      {CarouselData2.map((item) => (
        <CarouselPart data={item} />
      ))}
      <MeetTheFamily TrimData={TrimData} data={CircleData} />
      <VideoFrame videoUrl="https://youtu.be/CktZ-sfJJfE?si=gF-1EQsCyDO58IO1" />
      <DetailedInfo DetailInfo={DetailInfo} />
      <Faq FaqData={FaqData} />
      <Wsp />
    </>
  );
}
