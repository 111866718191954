const BannerData = {
  videoUrl:
    "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2FD-1920x1080_Mobile.mp4?alt=media&token=99cae99b-40a2-4131-925d-3de04319e2a9",
  title: "Kia Seltos",
  subtitle: "Tech is now Badass.",
  price: "₹11 12 900*",
  features: [],
};

const IntroData = {
  heading: "Level up with the Badass.",
  subheading: "UNLOCK THE NEXT-LEVEL",
  description:
    "Dominant, confident, and exhilarating. With a strong, chiseled look and a formidable stance, the new Kia Seltos showcases its Badass traits.The SUV car features highly powerful and fuel-efficient engine options, along with 32 safety features including 19 autonomous ADAS Level 2 features, the reinvented Seltos compact SUV has everything it takes to be a Badass on the road.",
  image:
    "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fseltos-anniversary-dektop-image-min.png?alt=media&token=22c7d969-79a6-46db-a716-72371ee1364d",
};

const CarouselData = [
  {
    sub: "HTK (O)",
    title: "A badass that has it all.",
    data: "Badass in every way, the Kia Seltos HTK (O) is built to turn heads and own the road. With its Dual-Pane Panoramic Sunroof letting in the sky above and bold design making a statement below, every drive feels like a moment of power, style, and dominance.​",
    carData: [
      {
        title: "6 Airbags",
        description:
          "A badass drive needs all-around protection, keeping you safe from every side.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fsafe1.jpg?alt=media&token=8d493e0d-2e9d-4cbb-a389-c147ebfe1f67",
      },
      {
        title:
          "Electronic Stability Control (ESC), Hill Assist Control (HAC) & Vehicle Stability Management (VSM)",
        description:
          "Stay in control on every curve and incline with stability that matches your fearless spirit.​",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fsafe2.jpg?alt=media&token=35e9f9d9-a82b-4d30-9924-d9e6b1b75165",
      },
      {
        title: "Dual Pane Panoramic Sunroof",
        description:
          "Let the sky be part of every journey with a panoramic sunroof that adds freedom to every drive.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fsunroof.jpg?alt=media&token=b058c72f-1178-4813-8794-4b208ed044a0",
      },
      {
        title:
          "20.32 cm (8.0”) Touchscreen with Wireless Android Auto & Apple CarPlay",
        description:
          "Seamless connectivity and effortless control keep you in charge without missing a beat.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2FSE-Intutive.jpg?alt=media&token=0809e0a5-b89a-426a-8885-3be1715b95bd",
      },
      {
        title: "R16 40.56 cm Hyper Metallic Alloy Wheels",
        description:
          "A bold stance with striking alloys makes sure your presence is felt on every road.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fslide-2-pc.jpg?alt=media&token=f1763878-9f4a-4f96-a64f-0433fe9b9239",
      },
    ],
  },
  {
    sub: "X-Line",
    title: "Level up with the badass.",
    data: "The Seltos X-Line exudes confidence and power with its bold and aggressive design. Euipped with advanced features and offering an exhilarating driving experience, the new Seltos X-Line is designed to inspire and empower.​",
    carData: [
      {
        title: "Signature Tiger Nose Grill-Matte Graphite",
        description:
          "With its striking appearance and powerful stance, the Signature Tiger Nose Grille has been reincarnated to command attention.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fslide-1-pc.jpg?alt=media&token=206da3f4-0ed0-4ac7-89a1-3b0fd0383bf6",
      },
      {
        title: "R-18 (46.20 cm) Crystal-Cut Glossy Black Alloy Wheels",
        description:
          "The assertive crystal-cut alloy wheels of the new Seltos elevate its impressive appearance to newer heights.​",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fslide-2-pc.jpg?alt=media&token=f1763878-9f4a-4f96-a64f-0433fe9b9239",
      },
      {
        title: "Dual Sports Exhaust (G1.5 T- Gdi)",
        description:
          "With its newly added dual exhaust system, the Seltos X-Line now boasts a sportier look and sound.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fslide-3-pc.jpg?alt=media&token=b7a0df8d-c733-4540-a172-83064a94446a",
      },
      {
        title: "Dual-Pane Panoramic Sunroof",
        description:
          "The panoramic sunroof of the new Seltos features two glass panels so that your journeys feel more immersive, open, and airy",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fslide-4-pc.jpg?alt=media&token=e6ecb381-05a8-43d9-a410-0ed6d544e7c6",
      },
      {
        title: "All Black Interiors With Exclusive Sage Green Inserts",
        description:
          "The All-Black Interiors combined with Xclusive Sage Green inserts make it an unbeatable force to reckon with.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fslide-5-pc.jpg?alt=media&token=370311a2-d6b2-486b-a94b-f1bf1af78fe7",
      },
      {
        title: "Smart 20.32 cm Heads Up Display",
        description:
          "A transparent and Smart 20.32 cm display projecting an image equivalent to an 8-inch screen onto the windshield for maximum visibility and convenience.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fslide-6-pc.jpg?alt=media&token=12d6013e-2303-49f6-9461-82c61ab8d1a0",
      },
    ],
  },
];

const CarouselData1 = [
  {
    sub: "EXTERIOR",
    title: "Make a powerful statement",
    data: "Experience the bold new design of the Kia Seltos, exuding style and attitude. Its aggressive, muscular build, athletic shoulder lines, and stylized front and rear showcase its robust character. Get in and conquer any road.",
    carData: [
      {
        title: "Distinctive Star Map LED Connected Tail Lamps",
        description:
          "Drawing inspiration from the cosmic tapestry above, the distinctive Star Map LED Tail Lamps of the Seltos set new standards for cutting-edge design.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fext2.jpg?alt=media&token=8d2e3ebb-7a73-4848-bba3-34229cf6d60a",
      },
      {
        title: "Striking R18 (46.20 cm) Crystal Cut Glossy Black Alloy Wheels",
        description:
          "Elevate your style quotient with the Seltos' Striking R18 Crystal Cut Alloy Wheels crafted with precision and featuring a glossy black finish to turn heads and conquer roads with an assertive spirit.​",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FEx-2.jpg?alt=media&token=f3912cd2-a87e-4bd8-960c-27137d9d211a",
      },
      {
        title:
          "Dazzling Crown Jewel LED Headlamps with Star Map LED DRLs & Star Map Sweeping LED Light Guide",
        description:
          "The Seltos' Dazzling Crown Jewel LED Headlamps illuminate your path and create a captivating visual symphony. Sweeping Star Map LED Daytime Running Lights bring celestial companions to life, turning every drive into an artful journey.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fext3.jpg?alt=media&token=8c40de45-33be-4f6b-bdd2-192936a1703f",
      },
      {
        title: "Stylish Dual Sport Exhaust (G1.5T Only)",
        description:
          "The road becomes your playground with the Stylish Dual Sport Exhaust of the Seltos. Not just an addition, it's an attitude that defines the very essence of exhilaration.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fext4.jpg?alt=media&token=5fb17922-206b-40b3-9166-d6f72d3610bd",
      },
    ],
  },
  {
    sub: "INTERIOR",
    title: "Exquisitely designed, inside out.",
    data: "With its awe-inspiring interiors boasting stylish and edgy design, along with generous space for supreme comfort, the redefined new Kia Seltos becomes your ultimate travel companion. Parking Brake, Smart Pure Air Purifier with virus and bacteria protection, and Bose Premium Sound System with 8 Speakers, it redefines your driving experience with unparalleled comfort.",
    carData: [
      {
        title: "Awe-inspiring Dual Pane Panoramic Sunroof",
        description:
          "Experience immersive journeys with Seltos' Dual Pane Panoramic Sunroof. Two expansive glass panels make the sky your companion, elevating every drive with wonder and freedom.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fint1.mp4?alt=media&token=ccc5e322-5b5a-4893-9321-c5b8568eca2e",
      },
      {
        title:
          "Intuitive 26.03 cm (10.25’’) HD Touchscreen Navigation + Full Digital Cluster with 26.04 cm (10.25”) Color LCD MID",
        description:
          "Explore limitless possibilities with the Intuitive 26.03 cm HD Touchscreen and Digital Cluster. Dual panoramic screens immerse you in information and entertainment, captivating and functional at your fingertips.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fint2.mp4?alt=media&token=4111ad1b-e446-42ad-855e-2f81601538a2",
      },
      {
        title:
          "Comfortable Front Ventilated Seats with 8-way Power Driver’s Seat",
        description:
          "Indulge in luxury with Comfortable Front Ventilated Seats. Experience continuous airflow and embrace comfort, transforming each drive into rejuvenation.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fint3.jpg?alt=media&token=7053a7b5-151c-465f-a1ed-aedceefac11a",
      },
      {
        title: "Intelligent Dual Zone Fully Automatic Air Conditioner",
        description:
          "Experience the future of climate control with the Intelligent Dual Zone AC. Tailor your comfort with precision, making every ride an oasis of personalized relaxation.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fint4.mp4?alt=media&token=193c9c3d-abd4-49a7-bab1-221406f3991c",
      },
    ],
  },
  {
    sub: "POWER & PERFORMANCE",
    title: "Unrivaled Power & Performance",
    data: "The new Kia Seltos delivers dynamic performance, elevating your driving experience. With advanced, powerful, and fuel-efficient engine options, it offers ultimate power and performance, empowering you to hit the road on your own terms.",
    carData: [
      {
        title: "Powerful Smartstream G1.5 T-GDi Petrol Engine",
        description:
          "Experience the thrill of commanding power and smooth performance on every drive.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fpower1.jpg?alt=media&token=87acca62-53e8-4f9a-b18c-8d212452a18d",
      },
      {
        title: "Advanced Smartstream G1.5 Petrol Engine",
        description:
          "Crafted to deliver seamless driving dynamics and advanced responsiveness.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fpower2.jpg?alt=media&token=722257b5-e690-4c51-bbbc-aece6d8b6871",
      },
      {
        title: "Refined 1.5l CRDi VGT Diesel Engine",
        description:
          "Engineered to redefine every journey with smooth power and superior refinement.",
        media:
          "http://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fpower3.jpg?alt=media&token=cd616dee-cd19-40ad-929a-644c56a076ca",
      },
    ],
  },
];

const MiddleData = {
  heading: "Badass 2.0 meets future of Manual Transmission",
  subheading: "iMT",
  description:
    "Switch to the tech that inspires with the new Seltos. The Badass comes equipped with the convenient, fun, and exciting iMT technology that offers you hassle-free drive every time you hit the road.",
  image:
    "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fimt.jpg?alt=media&token=4b34e450-0364-4666-a95a-7e9f5078f0ca",
};

const CarouselData2 = [
  {
    sub: "CONNECTED CAR TECHNOLOGY",
    title: "Take Control Like a boss.",
    data: "The new Kia Seltos offers integrated home-to-car connectivity through the Next-gen Kia Connect app.With Alexa and Google Voice Assistant, you can control your car using the app on your smartwatch or smartphone.Enjoy over 60 smart connected features for a fun and convenient drive.",
    carData: [
      {
        title: "OTA Map & System Update",
        description:
          "Through OTA Map & System Updates, the Badass is always in-sync with the future, ensuring you're always connected to the latest advancements.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Ftech1.jpg?alt=media&token=119cda90-5ce8-4a10-b113-838db9706b70",
      },
      {
        title: "Voice Controlled Window Function",
        description:
          "Experience the future of control with Voice Controlled Window Function. A simple command brings the world to your fingertips, making every journey effortless and truly hands-free.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Ftech2.jpg?alt=media&token=5f17a3a0-1502-462b-939c-bc68df42799d",
      },
      {
        title: "Remote AC Control",
        description:
          "With Remote AC Control in the Seltos, your comfort begins the moment you step in, helping you stay ahead of the weather for a more welcoming experience.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Ftech3.jpg?alt=media&token=f439f1c2-1aee-4080-8be0-9f3573edd431",
      },
      {
        title: "Kia Connect Skill with Amazon Alexa",
        description:
          "With the integration of home-to-car connectivity with Alexa and Googe Voice Assistant through the Next-gen Kia Connect, the Seltos delivers an effortlessly badass ownership experience in-sync with your lifestyle.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Ftech4.jpg?alt=media&token=e9df2ad8-a3a8-49f4-a050-b8559bfb6be9",
      },
    ],
  },
  {
    sub: "ADAS LEVEL 2",
    title: "Safety maximized with intelligent ADAS Level 2",
    data: "The new Seltos enhances situational awareness by detecting vehicles, bicycles, and pedestrians using advanced sensing technology. It actively prevents collisions, ensures pedestrian safety, and offers a safer, more intuitive, and rewarding driving experience with its Advanced Driver Assistance System (ADAS) consisting of 19 Autonomous Level 2 features.",
    carData: [
      {
        title: "Smart Cruise Control with Stop and Go",
        description:
          "Smart Cruise Control with Stop and Go empowers you for effortless, stress-free travel on highways and urban streets.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fadas.mp4?alt=media&token=a1c0a86d-cb36-4f17-99a8-be61ea5d981b",
      },
      {
        title: "Blind Spot Collision Warning",
        description:
          "The Blind Spot Collision Warning system keeps you informed and secure on the road. It's not just a feature; it's your invisible co-pilot, watching out for your well-being.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fadas1.mp4?alt=media&token=a720005b-7a6b-4ab2-8b8d-85583b411124",
      },
      {
        title: "Front Collision Warning and Avoidance Assist (FCW and FCA)",
        description:
          "Front Collision Warning and Avoidance Assist in the Seltos are your vigilant guardians, working tirelessly to prevent accidents and ensure your security on every drive.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fadas2.jpg?alt=media&token=b97a7c62-39c1-46d9-a59a-d466d4f1d9fe",
      },
      {
        title: "Lane Keep Assist (LKA)",
        description:
          "Lane Keep Assist is your steadfast companion, ensuring your path remains true. Embrace the confidence of steady, safe journeys in your Seltos.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fadas3.jpg?alt=media&token=184fe2d6-a024-4819-8345-7d8fc3bd17aa",
      },
    ],
  },
  {
    sub: "Safety",
    title: "Unparalleled safety as standard.",
    data: "The new Seltos features a comprehensive range of 15 Robust Hi-Safety advanced features as part of its standard safety kit, allowing you to stay in control and respond to sudden impacts swiftly.",
    carData: [
      {
        title: "360º Camera with Blind View Monitor in Cluster",
        description:
          "With the 360º Camera and Blind View Monitor, master the trickiest of parking spots with precision and confidence.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fsafe1.mp4?alt=media&token=43b8e2a0-fbd4-406a-a529-05d6d3a02807",
      },
      {
        title: "6 Airbags",
        description:
          "Our commitment to your well-being extends in all directions, ensuring peace of mind for you and your loved ones.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fsafe1.jpg?alt=media&token=8d493e0d-2e9d-4cbb-a389-c147ebfe1f67",
      },
      {
        title:
          "Electronic Stability Control (ESC), Hill Assist Control (HAC) & Vehicle Stability Management (VSM)",
        description:
          "Elevate your driving confidence with Electronic Stability Control, Hill Assist Control, and Vehicle Stability Management. These ensure your journeys are secure and your experiences are unforgettable.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fsafe2.jpg?alt=media&token=35e9f9d9-a82b-4d30-9924-d9e6b1b75165",
      },
      {
        title: "All Wheel Disc Brakes",
        description:
          "Discover the commanding performance of All Wheel Disc Brakes. With precision stopping at your command, you're not just driving a vehicle; you're mastering the road.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fsafe4.jpg?alt=media&token=81c70abe-a013-4618-b6a7-fd1a0562e5ea",
      },
    ],
  },
];

const TrimData = [
  {
    title: "HTE (O)",
    cost: "11,12,900",
    feature1: "6 Airbags (Front, Side Airbags)",
    feature2: "ESC, VSM",
    feature3: "All Wheel Disc Brakes",
    feature4: "20.32 cm (8.0”) Touchscreen with Wireless Android Auto  Carplay",
    feature5: "Full Digital Cluster with 10.5 cm (4.2) Color TFT MID",
    feature6: "Rear Door Sunshade Curtain",
    feature7: "Halogen Projector Headlamps",
  },
  {
    title: "HTK",
    cost: "12,57,900",
    feature1: "6 Airbags (Front , Side and Curtain Airbags)",
    feature2: "ESC, VSM and HAC",
    feature3: "All Wheel Disc Brakes",
    feature4: "Highline Tire Pressure Monitoring System",
    feature5:
      "20.32 cm (8.0”) Touchscreen with Wireless Android Auto and Apple Carplay",
    feature6: "Full Digital Cluster with 10.5 cm (4.2) Color TFT MID",
    feature7: "Rear Door Sunshade Curtain",
  },
  {
    title: "HTK (O)",
    cost: "12,99,900",
    feature1: "6 Airbags (Front, Side  Airbags)",
    feature2: "ESC, VSM ",
    feature3: "All Wheel Disc Brakes",
    feature4: "Dual Pane Panoramic Sunroof",
    feature5: "20.32 cm (8.0”) Touchscreen with Wireless Android Auto  Carplay",
    feature6: "Full Digital Cluster with 10.5 cm (4.2) Color TFT MID",
    feature7: "R16 40.56 cm Hyper Metallic Alloy Wheels",
  },
  {
    title: "HTK Plus",
    cost: "15,77,900",
    feature1: "6 Airbags (Front, Side  Airbags)",
    feature2: "ESC, VSM ",
    feature3: "All Wheel Disc Brakes",
    feature4: "Dual Pane Panoramic Sunroof",
    feature5: "20.32 cm (8.0”) Touchscreen with Wireless Android Auto  Carplay",
    feature6: "Full Digital Cluster with 10.5 cm (4.2) Color TFT MID",
    feature7: "R16 40.56 cm Hyper Metallic Alloy Wheels",
  },
  {
    title: "HTK Plus (O)",
    cost: "14,39,900",
    feature1: "6 Airbags (Front, Side  Airbags)",
    feature2: "ESC, VSM ",
    feature3: "Smart Key with Push Button Start/Stop",
    feature4: "Dual Pane Panoramic Sunroof",
    feature5: "Front LED DRLs",
    feature6:
      "Crown Jewel LED Headlamps with Star Map LED Sweeping Light Guide",
    feature7: "Sequential LED Turn Indicators with Ice Cube LED Fog Lamps",
  },

  {
    title: "HTX",
    cost: "15,75,900",
    feature1: "6 Airbags (Front , Side and Curtain Airbags)",
    feature2: "Panoramic Sunroof",
    feature3:
      "Crown Jewel LED Headlamps with Star Map LED Sweeping Light Guide",
    feature4: "Star Map LED Connected Tail Lamps",
    feature5: "R17 43.66 cm Alloy Wheels",
    feature6: "Smart Pure Air Purifier with Virus and Bacteria Protection**",
    feature7:
      "26.03 cm (10.25”) HD Touchscreen Navigation with Kia Connect with OTA Maps and System Update",
  },
  {
    title: "HTX (O)",
    cost: "16,70,900",
    feature1: "6 Airbags (Front, Side  Airbags)",
    feature2: "Dual Pane Panoramic Sunroof",
    feature3:
      "Crown Jewel LED Headlamps with Star Map LED Sweeping Light Guide",
    feature4: "Star Map LED Connected Tail Lamps",
    feature5: "Sequential LED Turn Indicators with Ice Cube LED Fog Lamps",
    feature6: "R17 43.66 cm Crystal Cut Alloy Wheels",
    feature7:
      "26.03 cm (10.25”) HD Touchscreen Navigation with Full Digital Cluster with 26.04 cm (10.25”) Color LCD MID",
  },
  {
    title: "GTX+",
    cost: "19,99,900",
    feature1: "ADAS 2.0 with 19 Features",
    feature2: "Panoramic Sunroof",
    feature3: "360 Degree Camera Blind View Monitor in Cluster",
    feature4: "BOSE Premium sound System with 8 Speakers",
    feature5: "Sequential LED Turn Indicators with Ice Cube LED Fog Lamps",
    feature6: "R18 46.20 cm Crystal Cut Glossy Black Alloy Wheels",
    feature7:
      "26.03 cm (10.25”) HD Touchscreen Navigation with Full Digital Cluster with 26.04 cm (10.25”) Color LCD MID",
  },
  {
    title: "X-Line",
    cost: "20,50,900",
    feature1: "ADAS 2.0 with 19 Features",
    feature2: "Panoramic Sunroof",
    feature3: "360 Degree Camera Blind View Monitor in Cluster",
    feature4: "BOSE Premium sound System with 8 Speakers",
    feature5: "Sequential LED Turn Indicators with Ice Cube LED Fog Lamps",
    feature6: "R18 46.20 cm Crystal Cut Glossy Black Alloy Wheels",
    feature7:
      "26.03 cm (10.25”) HD Touchscreen Navigation with Full Digital Cluster with 26.04 cm (10.25”) Color LCD MID",
  },
];

const CircleData = [
  {
    title: "Imperial Blue",
    color: "#2b2c71",
    imgSrc: 'https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fsel1.png?alt=media&token=e4d236cd-5d35-4bb6-a8f3-65bbfd330cf7'
  },
  {
    title: "Pewter Olive",
    color: "#3e493b",
    imgSrc: 'https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fsel2.png?alt=media&token=58bbb7c8-9f59-41a4-87d3-ab3071965eed'
  },
  {
    title: "Glacier White Pearl",
    color: "#f4f9fb",
    imgSrc: 'https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fsel4.png?alt=media&token=7144ced5-a63e-4d5f-b662-49c0468fd53e'
  },
  {
    title: "Intense Red",
    color: "#b2001e",
    imgSrc: 'https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fsel3.png?alt=media&token=733e6b38-b343-4790-97a7-a2f04a66acbc'
  },
  {
    title: "Gravity Grey",
    color: "#444444",
    imgSrc: 'https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fsel5.png?alt=media&token=9d41713e-be10-47ae-84cc-e21cb8e6cf58'
  },
  {
    title: "Aurora Black Pearl",
    color: "#151617",
    imgSrc: 'https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fsel6.png?alt=media&token=d6c84ec2-2c36-4b61-8b15-9f8630511b8b'
  },
  {
    title: "Sparkling Silver",
    color: "#e0e0df",
    imgSrc: 'https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fsel7.png?alt=media&token=511c9adc-378c-4674-a2f3-740327362776'
  },
  {
    title: "Glacier White Pearl + Aurora Black Pearl",
    color1: "#f4f9fb",
    color2: "#151617",
    imgSrc: 'https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fsel8.png?alt=media&token=20a050a6-7dd4-4712-b9e6-a640640b06ab'
  },
  {
    title: "Intense Red + Aurora Black Pearl",
    color1: "#b2001e",
    color2: "#151617",
    imgSrc: 'https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fsel9.png?alt=media&token=8086a414-c0c9-4938-92ef-9062657ef777'
  },
];

const DetailInfo = [
  {
    bgColor: "#FFF",
    color: "#000",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2Fbook.png?alt=media&token=60d7f5b9-c30b-4c19-a810-1ecf835516c8",
    title: "Book Kia Seltos Today ",
    desc: "It’s time to own the Badass.",
    link: "",
  },
  {
    bgColor: "#05141f",
    color: "#FFF",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2Fdownload.png?alt=media&token=53d6a48a-54b2-4093-b4f8-ebcaa1873a4c",
    title: "Download Brochure",
    desc: "Get all what you want to know about the Badass Reborn.",
    link: "https://www.kia.com/content/dam/kia2/in/en/images/brochure/seltos/kia-seltos-mobile-brochure.pdf",
  },
  {
    bgColor: "#FFF",
    color: "#000",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2Fcall.png?alt=media&token=dbd67b81-8ded-4bf9-bc21-f649fe1dec89",
    title: "Call us for more information",
    desc: "We'll be happy to answer all your queries. 09237386213",
    link: "",
  },
];

const FaqData = [
  {
    question: "What is the price of the Kia Seltos?",
    answer:
      "Kia Seltos starts at price of ₹ 11 12 900*(Ex-showroom price all India)",
  },
  {
    question: "What are the seating options available in the Kia Seltos?",
    answer:
      "The Kia Seltos is a 5 seater SUV car which comes with comfortable leatherette Seats and many other features",
  },
  {
    question: "How many airbags are there in the Kia Seltos?",
    answer:
      "The Kia Seltos SUV offers 6 airbags as standard to ensure the safety of you and your loved ones.",
  },
  {
    question: "What type of sunroof comes with the Kia Seltos?",
    answer:
      "The Kia Seltos SUV comes with a Dual Pane Panoramic Sunroof to enjoy the wide sky.",
  },
  {
    question: "What are the transmission options available in the Kia Seltos?",
    answer:
      "The Kia Seltos is available in both manual and automatic transmissions, namely 6MT / IVT 6iMT / 7DCT 6iMT / 6AT.",
  },

  {
    question: "What is the boot space of Kia Seltos SUV cars?",
    answer:
      "The Kia Seltos Compact SUV cars can be your perfect companion on a long drive with a boot space of 433** litres.",
  },
  {
    question: "Which model of Kia Seltos has a sunroof?",
    answer:
      "The Kia Seltos offers a sunroof on its premium models, including HTK+, HTX, HTX+, GTX+, GTX+(s), X-line(s), and X-line, all of which come equipped with a panoramic sunroof.",
  },
  {
    question: "Which is the top model of Kia Seltos?",
    answer:
      "The X-Line is the top variant of the Kia Seltos compact SUV car in India. The ex-showroom Kia Seltos top model price is Rs. 20 34 900 starting MSRP*.",
  },
];

const StepData = [
  {
    title: "Sequential LED  Turn Indicators",
    desc: "The front LED automotive lighting system blinks in a sequential pattern to increase visibility and safety on the road.",
  },
  {
    title: "R-18 (46.20 cm) Crystal-Cut Glossy Black Alloy Wheels",
    desc: "The assertive crystal-cut alloy wheels of the new Seltos elevate its impressive appearance to newer heights.",
  },
  {
    title: "Star Map LED Connected Tail Lamps",
    desc: "Taking inspiration from the stars above, the connected tail-lamp of the new Seltos is accentuated by a distinctive cutting-edge appearance.",
  },
  {
    title: "Dual-Pane Panoramic Sunroof ",
    desc: "The panoramic sunroof of the new Seltos features two glass panels so that your journeys feel more immersive, open, and airy. ",
  },
  {
    title: "Dual-Zone Fully Automatic Air Conditioner ",
    desc: "Stay comfortably cool in your respective zone. Designed to provide personalized comfort with varied temperature preferences.",
  },
  {
    title:
      "26.03 cm (10.25”) HD Touchscreen AVNT + Full Digital Cluster with26.04 cm (10.25”) Color LCD MID",
    desc: "The panoramic display of the new Seltos hosts two screens that can be controlled independently ensuring a wider and more immersive viewing experience.",
  },
];

export {
  CarouselData,
  CarouselData1,
  CarouselData2,
  TrimData,
  CircleData,
  DetailInfo,
  FaqData,
  StepData,
  IntroData,
  MiddleData,
  BannerData
};
