import React from 'react';
import Slider from 'react-slick';
import { Box, Typography } from '@mui/material';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";


const cardData = [
  {
    image: 'https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV9%2Fcard1.jpg?alt=media&token=bb5c2495-dacb-4d7c-9faa-fd3b8a9557f4',
    title: '* ADAS Level-2 with 27 Autonomous Feature',
    description: 'Every vigilant and always ready to step in, the EV9 ensures the ultimate active and passive safety. With more trhan 27 * ADAS functions and a plethora of other fetaures, everyone in and around the EV9 stays safe and in control'
  },
  {
    image: 'https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV9%2Fcard2.jpg?alt=media&token=0c477af9-7b39-4d1c-9c12-cdd92deeb9f6',
    title: 'Digital Pattern Lighting Grille',
    description: 'The sleek and muscular body of the EV9 features the Digital Pattern Ligting Grille with Intelligent Ice Cube LED Projectiion Headlamps; it certainly leaves a lasting impression on everyone'
  },
  {
    image: 'https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV9%2Fcard3.jpg?alt=media&token=72646da0-34c4-499a-8a78-2b2acd95932b',
    title: 'Trinity Panaromic  Display',
    description: 'Technology is integrated seamlessly, and every element is chosen to provide a modern , and convenient driving experience. Navigate and control every drive with the dynamic 12.3* Touchscreen Navigation'
  },
  {
    image: 'https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV9%2Fcard4.jpg?alt=media&token=30b31633-7265-48f4-8cc5-d88d721fb5da',
    title: 'Over the Air Software Updates',
    description: 'Let your vehicle evolve effortlessly with wireless upgrades. '
  },
  {
    image: 'https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV9%2Fcard5.jpg?alt=media&token=104f009c-62f7-4084-931e-f125ae029447',
    title: '2nd row Captain Seats with Relaxation & Massage Function',
    description: 'The 2nd row captain seats with relaxation and massage functions transform your ride into sanctuary. Experience customizable comfort and luxury, all the way. '
  },
  {
    image: 'https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV9%2Fcard6.jpg?alt=media&token=8ab983af-9608-4bb5-b2fd-567a8275f67d',
    title: 'Dual sunroof',
    description: "Embrace the open sky with the Kia Ev9's dual sunroof and enhance your connection with the world beyond"
  },
  {
    image: 'https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV9%2Fcard7.jpg?alt=media&token=9997068f-da28-45bb-a6c7-12042f5f430f',
    title: 'Digital IRVMs',
    description: "Experience enhanced visibility with the IRVM's integrated digital screen, offering a real-time rear camera view. Say goodbye to blind spots and enjoy safer, more confidient driving, evn in challenging conditions."
  },
];

const CustomCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024, // Tablet view
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600, // Mobile view
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <Box sx={{ margin: '15px' }}>
      <Typography variant="h3" sx={{ textAlign: 'center', fontFamily: 'Kia-sig', fontWeight: 800 }}>
      Meet the 2024 World Car of the Year
      </Typography>
      <Box sx={{ width: '88%', margin: 'auto', padding: '20px 30px' }}>
        <Slider {...settings}>
          {cardData.map((card, index) => (
            <Box key={index} sx={{ padding: '0 10px', }}>
              <img src={card.image} alt={card.title} style={{ width: '90%', height: 'auto', borderRadius: '8px' }} />
              <Typography variant="h6" sx={{ marginTop: 2 ,width:'95%',fontFamily:"Kia-sig"}}>{card.title}</Typography>
              <Typography variant="body2" sx={{ color: 'gray',width:'80%' }}>{card.description}</Typography>
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

// Custom arrow components
const SampleNextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      style={{
        display: 'block',
        background: 'rgba(0, 0, 0, 0.5)',
        borderRadius: '50%',
        padding: '10px',
        right: '-50px',
        zIndex: 1,
      }}
    />
  );
}

const SamplePrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      style={{
        display: 'block',
        background: 'rgba(0, 0, 0, 0.5)',
        borderRadius: '50%',
        padding: '10px',
        left: '-50px',
        zIndex: 1,
      }}
    />
  );
}

export default CustomCarousel;
