import React, { useState } from "react";
import { Typography,Box } from "@mui/material";

const CustomCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0); 
  const [fixedImage, setFixedImage] = useState("https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV6%2Fcolor1.png?alt=media&token=c11c1a91-56a3-4e3d-a71b-338f07eddcf8"); 

  const carImages = [
    { src: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV6%2Fcolor1.png?alt=media&token=c11c1a91-56a3-4e3d-a71b-338f07eddcf8", alt: "redev", color: "#C81926" },
    { src: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV6%2Fcolor2.png?alt=media&token=4344fa1d-7af5-4442-a5fa-b634985a47ad", alt: "blueev", color: "#143061" },
    { src: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV6%2Fcolor3.png?alt=media&token=f648aecf-5b1b-4192-b8e1-bdcd4595baa4", alt: "greyev", color: "#8B8C8C" },
    { src: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV6%2Fcolor4.png?alt=media&token=aaa7ed4c-8619-46f8-b6a6-fcf50d165177", alt: "blackev", color: "#050709" },
    { src: "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/EV6%2Fcolor5.png?alt=media&token=96f0cc5a-7814-4fa5-b223-6959ae4ba7b1", alt: "silverev", color: "#C8C9C9" },

  ];

  const handleSelect = (index) => {
    setActiveIndex(index);
    setFixedImage(carImages[index].src); 
  };

  return (
    <div style={{ position: "relative",margin:0,padding:0 }}>
    <div style={{ textAlign: "center",padding: "10px" }}>
    <Box sx={{paddingLeft:{xs:6,sm:13,md:23}, textAlign:"left"}}>
      <Typography variant="h6" sx={{color:'#9F9C9C',fontSize:{xs:'16px',sm:'22px'},marginTop:'16px',fontFamily:'KIAbodyLatinbasic_Bold',fontWeight:800,paddingBottom:1}}>DESIGN</Typography>
      <Typography variant="h4" sx={{fontSize:{xs:'22px',sm:'48px'},fontFamily:'KIAbodyLatinbasic_Bold',fontWeight:800,}}>EV6</Typography>
    </Box>
      <img
        className="d-block w-100"
        src={carImages[activeIndex].src}
        alt={carImages[activeIndex].alt}
        style={{ maxWidth: "100%", height: "auto",paddingBottom:'5px' }}
      />
    </div>

    {/* Custom Circle Indicators */}
    <div style={{ position: "absolute", bottom: "20px", left: "50%", transform: "translateX(-50%)", display: "flex", gap: "12px" }}>
      {carImages.map((car, index) => (
        <div
          key={index}
          onClick={() => handleSelect(index)}
          style={{
            width: "23px",
            height: "22px",
            borderRadius: "50%",
            backgroundColor: activeIndex === index ? car.color : car.color, 
            cursor: "pointer",
          }}
        />
      ))}
    </div>
  </div>
  );
};

export default CustomCarousel;
