import React from "react";
import { Box, Grid } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { featureData } from "./Data";
import "./CrissCross.css";

export default function CrissCross() {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

  return (
    <Box
      sx={{
        pl: { xs: 0, sm: "5vw" },
        pr: { xs: 0, sm: "5vw" },
        pt: { xs: 0, sm: "30px" },
      }}
    >
      <Grid container justifyContent="center" ref={ref}>
        {featureData.map((feature, index) => (
          <Grid
            item
            xs={12}
            key={index}
            sx={{
              backgroundColor:
                feature.textAlign === "Left" ? "#EFEFEF" : "transparent",
              p: { xs: 0, sm: "2%" },
            }}
          >
            <Grid
              container
              textAlign={"justify"}
              className={
                inView
                  ? index % 2 === 0
                    ? "slide-in-left"
                    : "slide-in-right"
                  : ""
              }
            >
              {/* Left Side Image */}
              {feature.textAlign === "Left" && feature.image && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={5}
                  p={0}
                  sx={{ display: { xs: "none", sm: "block" } }}
                >
                  <img
                    src={feature.image}
                    alt={feature.title}
                    className={`criss-cross-image`}
                  />
                </Grid>
              )}

              {/* Content */}
              <Grid
                item
                xs={12}
                sm={6}
                md={7}
                px={{ xs: "10vw", sm: 0 }}
                pt={{ xs: "30px", sm: 0 }}
                sx={{
                  pr: { md: feature.textAlign === "Right" ? 5 : 0 },
                  pl: { md: feature.textAlign === "Left" ? 5 : 0 },
                }}
              >
                <h1 className="criss-cross-title">{feature.title}</h1>
                <p className="criss-cross-description">{feature.description}</p>
                {feature.list && (
                  <ul className="criss-cross-list">
                    {feature.list.map((item, idx) => (
                      <li key={idx}>{item}</li>
                    ))}
                  </ul>
                )}
                {feature.sublist && (
                  <ol className="criss-cross-sublist">
                    {feature.sublist.map((item, idx) => (
                      <li key={idx}>{item}</li>
                    ))}
                  </ol>
                )}
                {feature.description1 && (
                  <p className="criss-cross-description">
                    {feature.description1}
                  </p>
                )}
                {feature.list1 && (
                  <ul className="criss-cross-list">
                    {feature.list1.map((item, idx) => (
                      <li key={idx}>{item}</li>
                    ))}
                  </ul>
                )}
                {feature.description2 && (
                  <p className="criss-cross-description">
                    {feature.description2}
                  </p>
                )}
                {feature.description3 && (
                  <p className="criss-cross-description">
                    {feature.description3}
                  </p>
                )}
                {feature.description4 && (
                  <p className="criss-cross-description">
                    {feature.description4}
                  </p>
                )}
                {feature.description5 && (
                  <p className="criss-cross-description">
                    {feature.description5}
                  </p>
                )}
              </Grid>

              <Grid
                item
                xs={12}
                p={0}
                sx={{ display: { xs: "block", sm: "none" } }}
              >
                <img
                  src={feature.image}
                  alt={feature.title}
                  className={`criss-cross-image ${
                    index === 1 ? "contain" : ""
                  }`}
                />
              </Grid>

              {/* Right Side Image */}
              {feature.textAlign === "Right" && feature.image && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={5}
                  p={0}
                  sx={{ display: { xs: "none", sm: "block" } }}
                >
                  <img
                    src={feature.image}
                    alt={feature.title}
                    className="criss-cross-image"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
