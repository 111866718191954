const BannerData = {
  imageUrl:
    "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fcarens1.jpg?alt=media&token=fd31f40b-8de6-4f0a-9551-c9538bd6247b",
  title: "Kia Carens",
  subtitle: "From a diffrent world.",
  price: "",
  features: [],
};

const banner2Data = {
  imgSrc:
    "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fimage1.jpg?alt=media&token=43d30c4d-5416-4ef1-a3b5-4048d114005e",
  data: [
    {
      title: "iMT (Intelligent Manual Transmission)",
      description:
        "",
    },
    {
      title: "Standard 6 Airbags",
      description: "",
    },
    {
      title: "Bose Premium Sound System with 8 Speakers",
      description: "",
    },
  ],
};

const IntroData = {
  heading: "The futuristic journey begins",
  subheading: "Welcome to a world of infinite choices",
  description:
    "Future calls out to those who are from a different world. The Kia Carens is hi-tech by design and inspiring by nature. It is futuristic sophistication clothed in bold and edgy armor with many class-leading features. So get ready to step into a different world.",
  image:
    "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2FCarens-BS-1.jpg?alt=media&token=d5d3da3b-57b0-472e-845b-48e0ac5df087",
};

const InterestDealerData = {
  disclaimer: "",
  register:
    "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2Fregister%20intrest.png?alt=media&token=2c5fb2a2-418b-4399-8994-8e590bd17314",
  dealer:
    "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2FBrochure%20image.png?alt=media&token=5ce8b8e6-2d25-41f1-b458-69aa2bd51a10",
};
const CarouselData = [
  {
    sub: "Gravity",
    title: "Step Into a World Beyond.",
    data: "The Carens Gravity Variant invites you to explore a world where luxury and innovation meet. With design, tech, and a driving experience that transcends the every day, it truly is from a different world.​​",
    carData: [
      {
        title: "Dash Cam with Dual Camera",
        description:
          "Capture every moment from all angles, ensuring your journey is watched over with clarity and precision.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fgravity1.jpg?alt=media&token=b0ec98e4-5e26-460c-a994-14bfe886cfe3",
      },
      {
        title: "Black Leatherette Seats",
        description:
          "Sink into sleek, sophisticated comfort that enhances every mile of your adventure.​",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fgravity2.jpg?alt=media&token=339503c3-3a46-4e7d-bd15-c8fcb23522d4",
      },
      {
        title: "Skylight Sunroof",
        description:
          "Open up to endless possibilities as the scenic view elevates your drive.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fgravity3.jpg?alt=media&token=a2d48835-929e-4bbb-8623-e1f02a16c4e6",
      },
      {
        title: "D-Cut Steering Wheel",
        description:
          "Experience refined control with a modern design that adds a touch of precision to every turn.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fgravity4.jpg?alt=media&token=383b527e-1ab1-4516-8bca-1ed6ac55e3c4",
      },
      {
        title: "Gravity Emblem",
        description:
          "Wear the emblem of distinction, marking your journey as one that’s from a different world.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fgravity5.jpg?alt=media&token=936e1a0e-0b2b-478a-8eb7-9f33cf181318",
      },
    ],
  },
  {
    sub: "X-Line",
    title: "X from a different world",
    data: "Take all the great things the Kia Carens has to offer and get ready to be transported to a different world.Right from the xclusive matte graphite to the space-age cockpit with sage green upholstery and rear seat entertainment screen, the Carens X-line is crafted to immerse you into a different world of style and comfort.​​",
    carData: [
      {
        title: "Stylish matte graphite exterior and black glossy grille",
        description: "Make you stand out in the world",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fx1.jpg?alt=media&token=e8a6b188-f905-4e51-9574-10cbe3fab603",
      },
      {
        title: "G1.5 Turbo Petrol Engine with 160 ps power",
        description: "Delivers unmatched power for your family trips​",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fx2.jpg?alt=media&token=a6f0466a-68e7-4096-beb2-ea8360ab7003",
      },
      {
        title: "Rear seat entertainment screen",
        description: "Gives freedom to choose your entertainment",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fx3.jpg?alt=media&token=1940669f-99f2-42e7-a1a9-1326146de9df",
      },
      {
        title:
          "Futuristic interiors with 3-rows, captain seats and walk in lever",
        description:
          "Offers more legroom and comfort, available in Kia 6 and 7 seater car options in India, which is class-leading",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fx4.jpg?alt=media&token=8f597bff-6048-4cad-be4f-07d716ed2135",
      },
      {
        title: "Kia Connect Skills with Amazon Alexa",
        description: "Lets you control your Kia from the comfort of your home",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fx5.jpg?alt=media&token=210b2f17-38c4-4ca3-b978-5c44fcf8b3dc",
      },
    ],
  },
];
const CarouselData1 = [
  {
    sub: "EXTERIOR",
    title: "A world of bold and captivating style",
    data: "The distinctive style of Kia Carens inspires awe and respect at the same time. Just like you, this car commands instant attention.It has been designed with bold attitude and space-age sophistication to offer experiences from a different world.​",
    carData: [
      {
        title: "Star Map LED DRLs with Crown Jewel LED Headlamps",
        description:
          "Inspired by constellations, the Star Map LED DRLs are crafted to enhance forward visibility, while the Crown Jewel LED Headlamps lend a distinctive visual edge through avant-garde design.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fext1.jpg?alt=media&token=427d686a-e662-4264-8347-7eaa2832b2e6",
      },
      {
        title: "Star Map LED Tail Lamps",
        description:
          "Leave a starry trail wherever you go with the Star Map LED Tail Lamps—a perfect fusion of form and finesse, drawing inspiration from a million stars for its breathtaking aesthetic appeal.​",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fext2.jpg?alt=media&token=81a2fd9f-c52d-4ec2-aecb-b8189e8e1dcd",
      },
      {
        title: "R16 – 40.62 cm (16’’) Dual Tone Crystal Cut Alloy Wheels",
        description:
          "Chiselled to enhance its distinctive appeal, the dual-tone finish lends a sporty and premium look to the assertive crystal-cut glossy alloy wheels.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fext3.jpg?alt=media&token=e97e5ad0-f863-4575-a789-1db6cd55dbaa",
      },
      {
        title: "Kia Signature Tiger Face with Digital Radiator Grille",
        description:
          "An edge from a different world. Kia's Signature Tiger Face, accompanied by the Digital Radiator Grille, captivates attention with its refreshing style and contrasting attitude.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fext4.jpg?alt=media&token=b29860de-a9d9-4606-987b-8ab165c03594",
      },
      {
        title: "Chrome Rear Bumper Garnish with Diamond Knurling Pattern",
        description:
          "The chrome cast intricate design adds a touch of sophistication to the rear, creating a stunning visual appeal.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fext5.jpg?alt=media&token=7122b40c-0027-4747-9f0c-ca98bbf75c3d",
      },
      {
        title: "Sky Light Sunroof",
        description: "Think of it as your portal to a different world.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fext6.jpg?alt=media&token=a354b34f-e053-4c60-ab3c-d744aa363c7b",
      },
    ],
  },
  {
    sub: "INTERIOR",
    title: "A world of intuitive and immersive experiences",
    data: "When you look within yourself, you are truly limitless. The Kia Carens is an ode to that spirit. Right from the spacecraft-like plush seats to the wraparound high-tech dashboard, every detail of the Kia Carens is crafted perfectly to immerse you in a different world of advancement.",
    carData: [
      {
        title: "Dashcam with Dual Camera",
        description:
          "Enhanced safety, security and convenience with dual recording typically positioned to record both the road ahead and the interior of the Kia Carens.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fint1.jpg?alt=media&token=4500c6b9-6cbe-424a-81fa-f75aaafd4666",
      },
      {
        title: "26.03 cm (10.25’’) HD Touchscreen Navigation",
        description:
          "The ideal and futuristic way to stay entertained, navigate, and stay connected with the world.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fint2.jpg?alt=media&token=379b8a98-02a7-4100-9286-ce9e7ab4fc7f",
      },
      {
        title: "BOSE Premium Sound System with 8 Speakers",
        description:
          "Set the mood right and make every journey epic with an unparalleled audio experience.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fint3.jpg?alt=media&token=d8acbd4d-8c99-41d8-8287-f2f6d4cfa721",
      },
      {
        title: "Cabin Surround 64-Color Ambient Mood Lighting",
        description: "Every possible shade to match every possible mood.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fint4.jpg?alt=media&token=56528ec5-1bc9-469d-9546-25fb2cb12c26",
      },
      {
        title: "Ventilated Front Seats",
        description:
          "Keep it cool as it gets hot outside with the Ventilated Front Seats that make you feel like you are in steering your way through the",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fint5.jpg?alt=media&token=fcabe164-4197-4b7c-8566-fab4a74bc98e",
      },
      {
        title: "Smart Pure Air Purifier with Virus & Bacteria Protection**",
        description:
          "Clean cabin-air with protection from harmful virus and bacteria.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fint6.jpg?alt=media&token=10e477a5-90e8-4d71-8d52-8e8c2df1053e",
      },
    ],
  },
  {
    sub: "PERFORMANCE",
    title: "A world where adrenaline does the talking",
    data: "Excitement seeks those who seek power. Level up your driving experience with the smartstream powertrain, delivering unmatched power and performance.",
    carData: [
      {
        title: "Powerful Smartstream 1.5l T-GDi Petrol Engine",
        description:
          "Unleash the true potential of your drive with the powerful smartstream technology. Transform your journey with G1.5L T-GDi Petrol Engine, delivering the power and performance your drive deserves.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fper1.jpg?alt=media&token=8707302a-9c58-44ad-80cf-c8349e82c4cf",
      },
      {
        title: "Advanced Smartstream 1.5l Petrol Engine",
        description:
          "Powerful journeys are always inspiring but, this time we are unlocking a new level of power with the advanced smartstream technology. The 1.5l Petrol Engine delivers powerful performance where adrenaline does the talking with boosted power and torque.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fper2.jpg?alt=media&token=3dd48c90-87d0-4f3e-9ab2-3ed133d207bd",
      },
      {
        title: "Refined 1.5l CRDi VGT Diesel Engine",
        description:
          "Your unstoppable journey begins when you find inspiration. With the Refined 1.5L CRDi VGT Diesel Engine, experience a drive that redefines excellence and takes your travel to newer heights.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fper3.jpg?alt=media&token=cb70bba8-ec9f-4b5c-8f6c-534b533064e5",
      },
    ],
  },
  {
    sub: "CONVENIENCE",
    title: "A world where comfort is everything",
    data: "Space is what inspires you to be truly you. That’s why the Carens offers you class-leading cabin space with plush 3-row seating comfort in 6 & 7 seater options to carry your world with you.​",
    carData: [
      {
        title: "2nd Row One Touch Easy Electric Tumble Seat",
        description:
          "Quick & easy way to walk straight to the third row with just a touch of button.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fcon1.mp4?alt=media&token=a616b2a5-4b92-4b48-b7d9-25190c7932de",
      },
      {
        title: "Roof Flushed 2nd and 3rd Row Diffused AC Vents",
        description:
          "Comfort and convenience come effortlessly with the Carens, featuring first-in-class 2nd and 3rd Row Diffused AC Vents.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fcon2.jpg?alt=media&token=53d61176-9e75-4465-8737-a2fabe764f8c",
      },
      {
        title: "Smartphone Wireless Charger with Cooling Function",
        description:
          "Designed to wirelessly charge your smartphone with cooling mechanism to prevent overheating during charging. It ensures safe and efficient charging on the go.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fcon3.jpg?alt=media&token=af555805-d3ed-48f4-8ba5-9981d48a514d",
      },
      {
        title: "Seat Back Table with Cup Holder and Gadget Mount",
        description:
          "Eat, drink, and savor the journey without any interruptions, thanks to the comfortable Seat Back Table.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fcon4.jpg?alt=media&token=45461061-e18a-4a59-bd7f-939d4c2dbad7",
      },
      {
        title: "Cooling Cup & Can Holders",
        description:
          "Keep your refreshments chilled on the go with the Cooling Cup and enjoy designated spots for your beverage containers.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fcon5.jpg?alt=media&token=aa5af352-99b0-4166-9706-d68ec6265773",
      },
    ],
  },
  {
    sub: "SAFETY",
    title:
      "To enhance passenger safety, all variants come standard with 6 airbags.",
    data: "The Kia Carens comes with class-leading Robust 10 Hi-safety features along with many additional features as standard across all variants, making it the safest car in its class. After all, your safety is what drives us to think out of the box.",
    carData: [
      {
        title: "6 Airbags",
        description:
          "To enhance passenger safety, all variants come standard with 6 airbags.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fsafe1.jpg?alt=media&token=8569eae0-eee3-403c-a4dd-ab5da068cc93",
      },
      {
        title: "All Wheel Disc Brakes",
        description:
          "Experience an advanced braking system that offers improved safety, control, efficient cooling, consistent performance, and responsive braking on every journey.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fsafe2.jpg?alt=media&token=04213f72-ab4a-4b8c-ab7d-a0d5ddc4f1ca",
      },
      {
        title: "ESC, VSM, BAS, HAC, DBC & ABS",
        description:
          "Stay in control during slippery or unstable driving conditions with ESC, VSM, BAS, HAC, DBC and ABS features.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fsafe3.mp4?alt=media&token=e3a08ca1-c3f4-4e5a-9287-db61afe14bae",
      },
      {
        title: "Highline Tyre Pressure Monitoring System",
        description:
          "Receive alerts to help the driver monitor tire pressure, enhancing safety, fuel efficiency, and tire longevity.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fsafe4.jpg?alt=media&token=5a64f68a-2dbc-4ed0-b601-632a3c4e91a0",
      },
    ],
  },
  {
    sub: "CONNECTED",
    title: "Smarter than ever",
    data: "Indulge in a world where the Carens is always at your beck-and-call.Presenting, the next-gen Kia Connect that's integrated with Alexa and offers 66 smart features that you can operate from the comfort of your home.",
    carData: [
      {
        title: "OTA Map & System Update",
        description:
          "Equipped with wireless technology, our advanced navigation and infotainment systems keep you updated with minimal disruption to the driver, ensuring convenience.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fcar1.jpg?alt=media&token=64f4f92c-ceae-4f8e-a8dd-b457eb9fd3a2",
      },
      {
        title: "Voice Controlled Sunroof ",
        description:
          "Now, drivers and passengers can open, close, or adjust the sunroof with simple voice commands.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fcar2.jpg?alt=media&token=3ae2eae4-2b8a-4643-b06a-41eb815ca11d",
      },
      {
        title: "Remotely Controlled Ventilated Seats",
        description:
          "Activate the ventilated seats with a remote control to circulate air through the seat surfaces for a more comfortable experience.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fcar3.jpg?alt=media&token=0894efcf-2ed7-412a-bef5-f5234b29aa8c",
      },
      {
        title: "Kia Connect Skill on Amazon Alexa",
        description:
          "Studded with numerous smart features, the Kia Carens keeps you connected even when you're not inside. Switch it on and step into a futuristic journey that begins right there.",
        media:
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fcar4.jpg?alt=media&token=f8c85db0-7d3f-4783-919f-a401470b4f31",
      },
    ],
  },
];

const TrimData = [
  {
    title: "Premium 7",
    cost: "10,59,900",
    feature1: "2nd Seat One Touch Easy Electric Tumble",
    feature2: "Roof Flushed 2nd and 3rd Row Diffused AC Vents",
    feature3: "Semi Leatherette (Black and indigo) Seats",
    feature4: "2nd Row 60:40 Split Seats with Sliding, Reclining and Tumble",
    feature5: "3rd Row 50:50 Split Seats with Reclining and Full Flat Folding",
    feature6: "Keyless Entry with Burglar Alarm",
  },
  {
    title: "Premium (O) 7",
    cost: "11,30,900",
    feature1:
      "20.32 cm(8.0)Touchscreen with Android Auto, Apple Carplay,(6 Speakers)",
    feature2: "Semi Leathrette (Black and Indigo Seats)",
    feature3: "Rear view camera with dynamic guideliness",
    feature4: "Keyless entry with Burglar Alarm",
    feature5: "Rear view camera with Dynamic Guidelines",
    feature6: "Electric Adjust Outside Mirror with LED Turn Signals",
  },
  {
    title: "Gravity",
    cost: "12,29,900",
    feature1: "Dash Cam with Dual Camera",
    feature2: "Black Leatherette Seats",
    feature3: "Skylight Sunroof",
    feature4: "D-Cut Steering Wheel",
    feature5: "Rear View Camera with Dynamic Guidelines",
    feature6: "Keyless Entry with Burglar Alarm",
    feature7:
      "20.32 cm (8.0) Touchscreen with Android Auto, Apple Carplay (6 Speakers)",
  },
  {
    title: "Prestige",
    cost: "14,25,900",
    feature1:
      "20.32 cm (8.0) Touchscreen with Android Auto, Apple Carplay (6 Speakers)",
    feature2: "Premium Fabric and Leatherette Combination (Black ) Seats",
    feature3: "Rear View Camera with Dynamic Guidelines",
    feature4: "Front Parking Sensors",
    feature5: "Keyless Entry with Burglar Alarm",
    feature6: "Electric Adjust Outside Mirror with LED Turn Signals",
  },
  {
    title: "Prestige (O) 6",
    cost: "11,99,900",
    feature1:
      "Premium Fabric and Leatherette Combination (Black and Beige Seats)",
    feature2: "Rear view camera with Dynamic Guideliness",
    feature3: "Front Parking Sensors",
    feature4: "Smart Key with push button start",
    feature5: "Electric Adjust Outside Mirror with LED Turn Signals",
    feature6: "Star map LED DRLs and Tailamps",
  },

  {
    title: "Prestige Plus 7",
    cost: "15,19,900",
    feature1: "Star Map LED DRLs and Tailamps",
    feature2: "R16 - 40.62 cm (16”) Dual Tone Crystal Cut Alloy Wheels",
    feature3: "Fully Automatic Air Conditioner",
    feature4: "Multi Drive Modes (Normal/Eco/Sport) [7DCT]",
    feature5: "Smart Key with Push Button Start",
    feature6: "Auto Cruise Control with Speed Limiting Option",
    feature7: "Remote Engine Start",
  },
  {
    title: "Prestige Plus(O)",
    cost: "16,39,900",
    feature1: "Sky Light sunroof",
    feature2: "Star Map LED DRls and Tailamps",
    feature3: "Fully Automatic Air Conditioner",
    feature4: "Smart Key with Push Button Start",
    feature5: "Rear Door with Sunshade Curtains",
    feature6: "Cooling Cup and Can Holders",
  },
  {
    title: "Luxury Plus",
    cost: "18,99,900",
    feature1: "Sky Light Sunroof",
    feature2: "BOSE Premium Sound System with 8 Speakers",
    feature3: "Ventilated Front Seats",
    feature4:
      "2nd Row Captain Seats with Sliding, Reclining and Tumble [6 Seater]",
    feature5: "Smart Phone Wireless Charger with Cooling Function",
    feature6: "Paddle Shifters [7DCT/6AT]",
    feature7:
      "Ambient Lighting linked with Multi-Drive Modes (Normal/Eco/Sport) [DCT/AT]",
  },
  {
    title: "X-Line 6",
    cost: "19,49,900",
    feature1: "Xclusive X-Line styling",
    feature2: "25.65cm (10.1)Rear Seat Entertainment-Passenger side",
  },
];

const CircleData = [
  {
    title: "Imperial Blue",
    color: "#2b2c71",
    imgSrc: 'https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fcarens1.png?alt=media&token=dfde6251-4b4b-4cf7-8540-6e29f6876305'
  },
  {
    title: "Intense Red",
    color: "#b2001e",
    imgSrc: 'https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fcarns2.png?alt=media&token=416024b3-db63-41a8-8659-13f355c33bee'
  },
  {
    title: "Aurora Black Pearl",
    color: "#151617",
    imgSrc: 'https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fcarens3.png?alt=media&token=1f4eecbc-8085-4e99-9a5e-d71b69c49c03'
  },
  {
    title: "Sparkling Silver",
    color: "#e0e0df",
    imgSrc: 'https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fcarens4.png?alt=media&token=17ed0f96-3f1b-4039-877a-72ab347b956e'
  },
  {
    title: "Gravity Grey",
    color: "#444444",
    imgSrc: 'https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fcarens5.png?alt=media&token=96916bad-f7f4-464f-9b24-32728843c135'
  },
  {
    title: "Glacier White Pearl",
    color: "#f3f9fb",
    imgSrc: 'https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fcarens6.png?alt=media&token=2cc6ff7e-5b11-4db2-9fd4-f9159d12e31f'
  },
  {
    title: "Pewter Olive",
    color: "#3e493b",
    imgSrc: 'https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Carens%2Fcarens7.png?alt=media&token=3561e7cf-2f52-4046-b7c1-6812d2d2411c'
  },
];

const DetailInfo = [
  {
    bgColor: "#FFF",
    color: "#000",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2Fbook.png?alt=media&token=60d7f5b9-c30b-4c19-a810-1ecf835516c8",
    title: "Book Kia Carens Today ",
    desc: "Exprience a diffrent World",
    link: "",
  },
  {
    bgColor: "#05141f",
    color: "#FFF",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2Fdownload.png?alt=media&token=53d6a48a-54b2-4093-b4f8-ebcaa1873a4c",
    title: "Download Brochure",
    desc: "Know it all about our Big Family Car",
    link: "https://www.kia.com/content/dam/kia2/in/en/our-vehicles/showroom/Carens/Carens_Brochure_2025_Desktop.pdf",
  },
  {
    bgColor: "#FFF",
    color: "#000",
    imgSrc:
      "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Syros%2Fcall.png?alt=media&token=dbd67b81-8ded-4bf9-bc21-f649fe1dec89",
    title: "Call us for more information",
    desc: "We'll be happy to answer all your queries. 09237386213",
    link: "",
  },
];

const FaqData = [
  {
    question: "What is the price of Kia Carens?",
    answer: "Kia Carens MSRP* starts at ₹ 10 59 900* (exclusive of all taxes).",
  },
  {
    question: "Does the Kia Carens come with a sunroof?",
    answer:
      "Yes, selected variants of Kia Carens top model comes with a sunroof.",
  },
  {
    question: "What are the seating options available in the Kia Carens?",
    answer:
      "The Carens provides best-in-class cabin space, along with luxurious 3-row seating comfort, in Kia's 6 and 7-seater car options in India, allowing you to take your world with you wherever you go.",
  },
  {
    question: "How many airbags are there in Kia Carens?",
    answer:
      "Kia Carens is a family car that offers 6 airbags to ensure comprehensive safety for you and your loved ones.",
  },
  {
    question: "What are the transmission options available in the Kia Carens?",
    answer:
      "The all-new Kia Carens is available in both manual and automatic transmissions, namely – 7DCT, 6AT and 6 MT, 6iMT.",
  },

  {
    question: "Which Kia carens model has a sunroof?",
    answer:
      "Kia Carens Sunroof Models are Prestige+(O), Luxury and Luxury Plus. These selected top models feature a skylight sunroof for enhanced openness and ambiance.",
  },
];

const StepData = [
  {
    title: "Star Map LED DRLs with Crown Jewel LED Headlamps",
    desc: "For a sleek and futuristic look that lends Carens a distinct identity.Inspired by constellations, they lend a distinctive visual edge through sleek and avant-grade design.",
  },
  {
    title: "R16 - 40.62 CM (16”) Dual Tone Crystal Cut Alloy Wheels ",
    desc: " Designed to stun, even at standstill. Chiselled to stun and enhance the muscular appeal, the two-tone finish provides a sporty and premium look.",
  },
  {
    title: " Chrome Rear Bumper Garnish with Diamond Knurling Pattern",
    desc: " It's a statement cast in chrome. Keep distance.Eye-catching bumper designed with chrome and sophisticated pattern makes the rear look stunning.",
  },
  {
    title: "Sky Light Sunroof",
    desc: "Think of it as your portal to a different world.",
  },
  {
    title: "26.04 cm (10.25”) Full Digital Color Cluster",
    desc: "Enhance your driving experience with a clear and concise display of vehicle parameters.",
  },
  {
    title: "Ventilated Front Seats",
    desc: "This is what the lap of luxury looks like. With days getting hotter, the driving experience gets cooler while lounging in the lap of luxury.",
  },
];

export {
  CarouselData,
  CarouselData1,
  TrimData,
  CircleData,
  DetailInfo,
  FaqData,
  StepData,
  BannerData,
  IntroData,
  InterestDealerData,
  banner2Data
};
