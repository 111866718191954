import React from "react";
import {
  banner2Data,
  BannerData,
  CarBanData,
  CarouselData,
  CarouselData1,
  CircleData,
  DetailInfo,
  FaqData,
  IntroData,
  StepData,
  TrimData,
} from "./Data";
import Wsp from "../../../contact/Component/Floatingwsp";
import Faq from "../../../../Common/Components/Faq";
import DetailedInfo from "../../../../Common/Components/DetailedInfo";
import VideoFrame from "../../../../Common/Components/VideoFrame";
import MeetTheFamily from "../../../../Common/Components/MeetTheFamily";
import ShowInterest from "../../../../Common/Components/ShowInterest";
import CarouselPart from "../../../../Common/Components/CarouselPart";
import { Grid, Typography } from "@mui/material";
import CarBanner from "../../../../Common/Components/CarBanner";
import CarStepper from "../../../../Common/Components/CarStepper";
import Banner2 from "../../../../Common/Components/Banner2";
import CarModel from "../../../../Common/Components/CarModel";
import Intro from "../../../../Common/Components/Intro";
import InterestDealer from "../../../../Common/Components/InterestDealer";
import Banner from "../../../../Common/Components/Banner";

export default function SONET() {
  return (
    <>
      <Banner bannerData={BannerData} />
      <InterestDealer />
      <Intro data={IntroData} />
      <CarModel
        model="Sonet"
        bgCar={
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Sonet%2Fint8.mp4?alt=media&token=deee93d1-c63e-427d-8a87-6426b2ee3df6"
        }
        CircleData={CircleData}
      />
      <Banner2 bannerData={banner2Data} />
      <CarStepper
        StepData={StepData}
        carLink={
          "https://firebasestorage.googleapis.com/v0/b/kiacentral-dcdd7.firebasestorage.app/o/Seltos%2Fdesktop.mp4?alt=media&token=14e43c07-acc9-4830-ae4e-543186a8aea1"
        }
        stepTimings={[0.0, 2.0, 3.0, 5.0, 7.0, 11.0]}
      />
      <CarBanner data={CarBanData} />
      <Grid container justifyContent="center">
        {/* Disclaimer Section */}
        <Grid item xs={12} sm={10} py={1}>
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{
              fontFamily: "KIAbodyLatinbasic_Regular",
              textAlign: "justify",
              display: "block",
              fontSize: "11px",
            }}
          >
            *Disclaimer:{" "}
            <span style={{ color: "#000", fontWeight: 600 }}>Source</span>
            : Comprehensive market research conducted by{" "}
            <span style={{ color: "#000", fontWeight: 600 }}>
              Frost & Sullivan
            </span>
              - a global analytics and advisory firm. The aggregate cost of
            ownership has been ascertained through a thorough analysis from the
            source, which includes the evaluation of five petrol and three
            diesel competitor models in comparison to the Sonet. This evaluation
            encompasses factors such as Initial Acquisition Cost, Residual
            Value, Maintenance Cost, Finance and Insurance Costs, and Fuel
            Expenses.
            <br />{" "}
            <span style={{ color: "#000", fontWeight: 600 }}>*Segment</span>:
            Segment is defined as Vehicle with length between 3990 mm – 3999 mm
            and height - 1570 mm.
          </Typography>
        </Grid>
      </Grid>
      {CarouselData.map((item) => (
        <CarouselPart data={item} />
      ))}
      <ShowInterest someValue="Seltos" />
      {CarouselData1.map((item) => (
        <CarouselPart data={item} />
      ))}
      <MeetTheFamily TrimData={TrimData} data={CircleData} />
      <VideoFrame videoUrl="https://youtu.be/3OxbKD5csXg?si=mjyiU_EKVD4FZwhY" />
      <DetailedInfo DetailInfo={DetailInfo} />
      <Faq FaqData={FaqData} />
      <Wsp />
    </>
  );
}
