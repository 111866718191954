import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Controller from "../Containers/Controller_Cont.js";
import Brochure from "../../Pages/Brochure/Component/Brochure.js";
import Header from "../../Pages/Header/Components/Header.js";
import Navbar from "../../Pages/Nav/Components/Navbar.js";
import SEO from "../../Common/Helmet/SEO.js";

import Home from "../../Pages/Home/Component/HOME2.jsx";
import Syros from "../../Pages/Cars/Syros/Component/SYROS.jsx";
import Seltos from "../../Pages/Cars/Seltos/Component/SELTOS.jsx";
import Sonet from "../../Pages/Cars/Sonet/Component/SONET.jsx";
import Carens from "../../Pages/Cars/Carens/Component/CARENS.jsx";
import Ev6 from "../../Pages/Cars/EV6/Component/Ev6.js";
import Carnival from "../../Pages/Cars/Carnival/Component/CARNIVAL.jsx";
import Ev9 from "../../Pages/Cars/EV9/Component/Ev9.js";

import Contact from "../../Pages/contact/Container/contactCon.js";
import Enquiry from "../../Pages/Enquiry/Container/enquiryCon.js";
import Service from "../../Pages/Services/Container/ServiceCon.js";
import Pricing from "../../Pages/Pricing/Containers/pricingCon.js";
import Preowned from "../../Pages/Preownedcars/Container/preownedCon.js";
import Footer from "../../Pages/Footer/Component/Footer.js";
import Clientnav from "../../Client/Containers/NavbarCont.js";
import Advertisement from "../../Client/Containers/advertisementCon.js";
import Oldcar from "../../Client/Containers/oldCarCont.js";
import Admindashboard from "../../Client/Containers/dashboardCon.js";
import EditPage from "../../Client/Containers/addEditCarCon.js";
import View from "../../Client/Containers/ViewpageCon.js";
import Addinginfo from "../../Client/Containers/addOldCarsCon.js";
import Overview from "../../Client/Containers/addOverviewsCon.js";
import Specification from "../../Client/Containers/addSpecificationsCon.js";
import Features from "../../Client/Containers/addFeaturesCon.js";
import Login from "../../Auth/Containers/Login_Cont.js";
import ContactDetails from "../../Client/Containers/ContactDetailsCon.js";
import TestDrive from "../../Client/Containers/testDriveCon.js";
import Services from "../../Client/Containers/serviceCon.js";
import Profile from "../../Pages/Profile/Containers/ProfileCont.js";
import Car1 from "../../Pages/car-details/Container/overviewCon.js";
import VehicleEnq from "../../Client/Containers/vehicleEnqCon.js";
import Enquirys from "../../Client/Containers/enquiryCon.js";
import Quatation from "../../Client/Containers/quatations.js";
import About from "../../Pages/AboutUs/components/aboutus.js";
import Headersupportpage from "../../Pages/Support/Component/Support.js";

class Routers extends Component {
  render() {
    const { login } = this.props;
    let body;

    if (login.isHome) {
      body = (
        <div>
          <main style={{ flexGrow: 1 }}>
            <Controller />
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <SEO
                      title="Central Kia: Premier Kia Dealership in Bhubaneswar, Odisha"
                      description="Explore Central Kia, your trusted Kia dealership in Bhubaneswar, Odisha. Discover the latest Kia models, exceptional service, and unbeatable offers tailored for you."
                      keywords="Kia dealership Bhubaneswar, Kia showroom Odisha, new Kia cars Bhubaneswar, used Kia cars Odisha, Kia service center Bhubaneswar, Kia Seltos Bhubaneswar, Kia Sonet Odisha, Kia Carens Bhubaneswar, Kia Carnival Odisha, Kia EV6 Bhubaneswar, Kia Syros Bhubaneswar"
                    />

                    <Header />
                    <Navbar />
                    <Home />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/car-details/:id/:brand"
                element={
                  <>
                    <SEO
                      title=" Kia for Sale in Bhubaneswar, Odisha | Central Kia"
                      description="Explore the 2022 Kia  with advanced features and sleek design at Central Kia in Bhubaneswar, Odisha. Contact us to schedule a test drive or for more information."
                      keywords="2022 Kia Seltos Bhubaneswar, pre-owned Kia Seltos Odisha, used Kia cars Bhubaneswar, Kia Seltos features, Kia Seltos price Bhubaneswar"
                    />
                    <Header />
                    <Navbar />
                    <Car1 />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/quotations"
                element={
                  <>
                    <SEO
                      title="Get a Quote for Your New Kia | Central Kia Bhubaneswar"
                      description="Request a personalized quotation for your preferred Kia model at Central Kia in Bhubaneswar, Odisha. Our team is ready to assist you with competitive pricing and financing options."
                      keywords="Kia car quotation Bhubaneswar, Kia pricing Odisha, Kia finance options Bhubaneswar, Kia Seltos quote Odisha, Kia Sonet price Bhubaneswar"
                    />
                    <Header />
                    <Navbar />
                    <Pricing />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/brochure"
                element={
                  <>
                    <SEO
                      title="Download Kia Car Brochures | Central Kia Bhubaneswar"
                      description="Access detailed brochures for all Kia models available at Central Kia in Bhubaneswar, Odisha. Download now to explore features, specifications, and more."
                      keywords="Kia car brochure Bhubaneswar, Kia Seltos brochure Odisha, Kia Sonet brochure Bhubaneswar, Kia Carens brochure Odisha, Kia model brochures Bhubaneswar"
                    />
                    <Header />
                    <Navbar />
                    <Brochure />
                    <Footer />
                  </>
                }
              />

              <Route
                path="/seltos"
                element={
                  <>
                    <SEO
                      title="Kia Seltos: Stylish and Powerful SUV in Bhubaneswar, Odisha"
                      description="Discover the Kia Seltos at Central Kia Bhubaneswar. Experience advanced features, robust performance, and modern design. Book a test drive today!"
                      keywords="Kia Seltos Bhubaneswar, Kia Seltos price Odisha, Kia Seltos features, Kia Seltos specifications, Kia Seltos test drive Bhubaneswar"
                    />

                    <Header />
                    <Navbar />
                    <Seltos />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/sonet"
                element={
                  <>
                    <SEO
                      title="Kia Sonet: Compact SUV with Premium Features in Bhubaneswar, Odisha"
                      description="Explore the Kia Sonet at Central Kia Bhubaneswar. A compact SUV offering cutting-edge technology, comfort, and style. Schedule your test drive now!"
                      keywords="Kia Sonet Bhubaneswar, Kia Sonet price Odisha, Kia Sonet features, Kia Sonet specifications, Kia Sonet test drive Bhubaneswar"
                    />

                    <Header />
                    <Navbar />
                    <Sonet />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/carens"
                element={
                  <>
                    <SEO
                      title="Kia Carens: Spacious and Versatile MPV in Bhubaneswar, Odisha"
                      description="Check out the Kia Carens at Central Kia Bhubaneswar. This MPV combines space, comfort, and advanced safety features, perfect for families. Visit us today!"
                      keywords="Kia Carens Bhubaneswar, Kia Carens price Odisha, Kia Carens features, Kia Carens specifications, Kia Carens test drive Bhubaneswar"
                    />

                    <Header />
                    <Navbar />
                    <Carens />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/ev6"
                element={
                  <>
                    <SEO
                      title="Kia EV6: Advanced Electric Vehicle in Bhubaneswar, Odisha"
                      description="Discover the future with the Kia EV6 at Central Kia Bhubaneswar. A fully electric vehicle offering impressive range, performance, and technology. Schedule a test drive now!"
                      keywords="Kia EV6 Bhubaneswar, Kia EV6 price Odisha, Kia EV6 features, Kia EV6 specifications, Kia EV6 test drive Bhubaneswar"
                    />

                    <Header />
                    <Navbar />
                    <Ev6 />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/ev9"
                element={
                  <>
                    <SEO
                      title="Kia EV9: Premium Electric SUV in Bhubaneswar, Odisha"
                      description="Explore the Kia EV9 at Central Kia Bhubaneswar. A premium electric SUV offering spacious interiors, advanced technology, and sustainable performance. Book your test drive today!"
                      keywords="Kia EV9 Bhubaneswar, Kia EV9 price Odisha, Kia EV9 features, Kia EV9 specifications, Kia EV9 test drive Bhubaneswar"
                    />

                    <Header />
                    <Navbar />
                    <Ev9 />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/carnival"
                element={
                  <>
                    <SEO
                      title="Kia Carnival: Luxury MPV Experience in Bhubaneswar, Odisha"
                      description="Experience the luxury of the Kia Carnival at Central Kia Bhubaneswar. Offering premium comfort, advanced technology, and ample space. Book your test drive today!"
                      keywords="Kia Carnival Bhubaneswar, Kia Carnival price Odisha, Kia Carnival features, Kia Carnival specifications, Kia Carnival test drive Bhubaneswar"
                    />

                    <Header />
                    <Navbar />
                    <Carnival />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/syros"
                element={
                  <>
                    <SEO
                      title="Kia Syros: Next-Generation SUV in Bhubaneswar, Odisha"
                      description="Introducing the all-new Kia Syros at Central Kia Bhubaneswar. Experience cutting-edge design, advanced safety features, and exceptional performance. Visit us for a test drive!"
                      keywords="Kia Syros Bhubaneswar, Kia Syros price Odisha, Kia Syros features, Kia Syros specifications, Kia Syros test drive Bhubaneswar"
                    />

                    <Header />
                    <Navbar />
                    <Syros />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/preowned"
                element={
                  <>
                    <SEO
                      title="Certified Pre-Owned Kia Cars | Central Kia Bhubaneswar"
                      description="Explore certified pre-owned Kia cars at Central Kia in Bhubaneswar, Odisha. Our pre-owned vehicles undergo comprehensive quality checks to ensure reliability and performance."
                      keywords="Pre-owned Kia cars Bhubaneswar, used Kia cars Odisha, certified used Kia Bhubaneswar, Kia Seltos pre-owned Odisha"
                    />

                    <Header />
                    <Navbar />
                    <Preowned />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/contact"
                element={
                  <>
                    <SEO
                      title="Contact Central Kia | Kia Dealership in Bhubaneswar, Odisha"
                      description="Get in touch with Central Kia, your trusted Kia dealer in Bhubaneswar, Odisha. Visit our showroom or contact us for inquiries about Kia cars, services, and offers."
                      keywords="Contact Central Kia, Kia dealership contact Bhubaneswar, Kia showroom Odisha contact, Kia service center Bhubaneswar contact"
                    />

                    <Header />
                    <Navbar />
                    <Contact />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/enquiry"
                element={
                  <>
                    <SEO
                      title="Enquire About Your Next Kia | Central Kia Bhubaneswar"
                      description="Have questions about a Kia model or need more information? Contact Central Kia Bhubaneswar through our enquiry page, and our team will assist you promptly."
                      keywords="Kia enquiry Bhubaneswar, contact Central Kia, Kia dealership Odisha, Kia model information, test drive booking Bhubaneswar"
                    />
                    <Header />
                    <Navbar />
                    <Enquiry />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/service"
                element={
                  <>
                    <SEO
                      title="Kia Car Services | Central Kia Service Center in Bhubaneswar"
                      description="Central Kia offers comprehensive car services in Bhubaneswar, Odisha. Our authorized Kia service center provides maintenance, repairs, and genuine spare parts for all Kia models."
                      keywords="Kia service center Bhubaneswar, Kia car maintenance Odisha, Kia repairs Bhubaneswar, Kia genuine spare parts Odisha"
                    />

                    <Header />
                    <Navbar />
                    <Service />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/support"
                element={
                  <>
                    <SEO
                      title="Customer Support | Central Kia Bhubaneswar"
                      description="Access comprehensive customer support services at Central Kia Bhubaneswar. Our dedicated team is here to assist you with all your Kia vehicle needs."
                      keywords="Kia customer support Bhubaneswar, Central Kia assistance, Kia service center Odisha, vehicle support Bhubaneswar, Kia help desk"
                    />
                    <Header />
                    <Navbar />
                    <Headersupportpage />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/about"
                element={
                  <>
                    <SEO
                      title="About Central Kia | Leading Kia Dealership in Odisha"
                      description="Learn more about Central Kia, the largest Kia dealership network in Odisha. With showrooms in multiple locations, we are committed to providing exceptional service and a wide range of Kia vehicles to our customers."
                      keywords="About Central Kia, Kia dealership Odisha, Kia showroom Bhubaneswar, Kia dealer information Odisha"
                    />

                    <Header />
                    <Navbar />
                    <About />
                    <Footer />
                  </>
                }
              />
              {/* <Route path='/pre-owned' element={<><Navbar/><Preowned/><Footer/></>}/> */}
              <Route exact path="/admin" element={<Login />} />
            </Routes>
          </main>
        </div>
      );
    } else {
      switch (login.type) {
        case "SA":
          body = (
            <div>
              {/* <Drawer type={login.type} /> */}
              <main style={{ flexGrow: 1, paddingTop: 0 }}>
                <Controller />

                <Routes>
                  <Route
                    exact
                    path="/admin"
                    element={
                      <>
                        {/* <Header /> */}
                        <Admindashboard />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/advertisement"
                    element={
                      <>
                        <Clientnav /> <Advertisement />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/profile"
                    element={
                      <>
                        <Clientnav /> <Profile />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/vehicle-enquiry"
                    element={
                      <>
                        <Clientnav /> <VehicleEnq />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/enquiry"
                    element={
                      <>
                        <Clientnav /> <Enquirys />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/quatation"
                    element={
                      <>
                        <Clientnav /> <Quatation />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/contact-details"
                    element={
                      <>
                        <Clientnav /> <ContactDetails />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/service-request"
                    element={
                      <>
                        <Clientnav /> <Services />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/vehicle-enquiry"
                    element={
                      <>
                        <Clientnav /> <VehicleEnq />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/oldcar"
                    element={
                      <>
                        <Clientnav /> <Oldcar />
                      </>
                    }
                  />
                  <Route
                    path="/about"
                    element={
                      <>
                        <Header />
                        <Navbar />
                        <About />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/book-test-drive"
                    element={
                      <>
                        <Clientnav /> <TestDrive />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/addinfo"
                    element={
                      <>
                        <Clientnav />
                        <Addinginfo />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/overview"
                    element={
                      <>
                        <Clientnav />
                        <Overview />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/specification"
                    element={
                      <>
                        <Clientnav />
                        <Specification />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/feature"
                    element={
                      <>
                        <Clientnav />
                        <Features />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/edit/:id"
                    element={
                      <>
                        <Clientnav /> <EditPage />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/view/:id"
                    element={
                      <>
                        <Clientnav /> <View />
                        <Footer />
                      </>
                    }
                  />
                </Routes>
              </main>
            </div>
          );
          break;
        default:
          body = (
            <div>
              <main style={{ flexGrow: 1, paddingTop: 70 }}>
                <Controller />
                <Routes></Routes>
              </main>
            </div>
          );
          break;
      }
    }

    return <Router>{body}</Router>;
  }
}

export default Routers;
